import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
// Import Components
import {createLines, resetLines} from '../../../actions/index'
// Set variables

// Main Class
class TermList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverLineIndex: 'none',
      clickLineIndex : 'none'
    };
    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
    this.onMouseClick = this.onMouseClick.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if(this.props.match !== nextProps.match) {
      this.setState({clickLineIndex: 'none'})
    }
  }
  onMouseEnterHandler(locations, index) {
    let hoverLines = {}
    let hoverEnds = []
    let lineEndClass = ''
    let lineStartClass = `line-start-${index}`
    hoverLines.lineStartClass = lineStartClass;
    let i = 0;
    _.map(locations, (location) => {
      lineEndClass = `line-end-${index}--${i}`
      hoverEnds.push(lineEndClass)
      i++
    })
    hoverLines.lineEndClass = hoverEnds
    this.props.createLines(this.props.lines, hoverLines,null )
  }
  onMouseLeaveHandler() {
    let hoverLines = {}
    this.props.createLines(this.props.lines, hoverLines,null )
  }
  onMouseClick(locations, index) {
    let clickLines = {}
    let clickEnds = []
    let lineEndClass = ''
    let lineStartClass = `line-start-${index}`
    clickLines.lineStartClass = lineStartClass
    let i = 0;
    _.map(locations, (location) => {
      lineEndClass = `line-end-${index}--${i}`
      clickEnds.push(lineEndClass)
      i++
    })
    clickLines.lineEndClass = clickEnds
    this.props.createLines(this.props.lines, null, clickLines)
    this.setState({clickLineIndex: index});
  }
  handleScroll = () => {
    this.props.resetLines()
    this.setState({clickLineIndex: 'none'})
  }
  renderTerms(terms, termClicked) {
    return _.map(terms, (term, index) => {
      let lineStartClass = `line-start-${index}`

      let isClicked = ''
      if(termClicked === index){
        isClicked = 'clicked'
      }
      return (
        <div
          className={`term ${isClicked}`}
          key={index}
          onMouseEnter={() => this.onMouseEnterHandler(term.location, index)}
          onMouseLeave={this.onMouseLeaveHandler}
          onClick={() => {this.onMouseClick(term.location, index)
          }}
          >
            <div className={`term__line ${lineStartClass}`}></div>
          <div className="term__title">{term.textField}</div>

        </div>
      )
    })
  }
  render() {
      return (
        <div className="termList">
          <Scrollbars
            className='scroll-area'
            onScroll={this.handleScroll}
            >
          {this.renderTerms(this.props.data, this.state.clickLineIndex)}
        </Scrollbars>
        </div>
      )
  }
}

function mapStateToProps(state) {
  return {lines: state.lines}
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({
    createLines : createLines,
    resetLines  : resetLines
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TermList))
