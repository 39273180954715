import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// Set Redux Actions
import {} from '../../../actions/index'

// Import Components
import TermListMobile from '../common/term-list-mobile'
import MdiChevronLeft from 'mdi-react/ChevronLeftIcon';

// Set variables

// Main Class
class MobileTermSidebar extends Component {
  constructor() {
    super();
    this.state = {

    };
    this.handleBackBtnClick = this.handleBackBtnClick.bind(this)
  }
  componentWillReceiveProps(nextProps) {

  }
  handleBackBtnClick() {
    this.props.toggleSidebar()
  }
  render() {
    let isOpenCSS = 'closed';
    if(this.props.isOpen) {
      isOpenCSS = 'open'
    } 
    return(
      <div className={`term-sidebar ${isOpenCSS}`}>
        <div className="title__viewer">MRI - Neck &amp; Spine</div>
        <div className="btn-container" onClick={this.handleBackBtnClick}>
          <div className="back-btn"><MdiChevronLeft /></div>
          <div className="viewer-info">
            <span className="viewer-info__text">{this.props.currentAnatomy}</span>
            <span className="viewer-info__text">{this.props.currentPlane}</span>
            <span className="viewer-info__text">{this.props.currentSlice} of {this.props.maxSlice}</span>
          </div>
        </div>
        <TermListMobile data={this.props.data}/>
      </div>
    )
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {
    viewer: state.viewer
  }
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({

  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileTermSidebar))
