import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';
// Import Components

// Set variables

// Main Class
class MainError extends Component {

  render() {
    let redirectionURL = '/'
    let redirectionText = 'Enter another viewerID'
    if(this.props.redirectionURL) {
      redirectionURL = this.props.redirectionURL
    }
    if(this.props.redirectionText) {
      redirectionText = this.props.redirectionText
    }
    return (
      <div className="select-viewer">
        <div className="card">
          <div className="card-block">
            <h5 className="card-title text-danger">Something happened</h5>
            <p className="card-text">{this.props.errorText}</p>
            <Link to={redirectionURL}>{redirectionText}</Link>
          </div>
        </div>
      </div>
    )
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {
  }
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({

  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainError))
