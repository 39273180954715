import { combineReducers } from 'redux';
import {reducer as formReducer } from 'redux-form';

import {SET_LOGOUT} from '../actions/';

//Utility Reducers
import {
  lineReducer,
  globalAlertReducer,
  globalErrorReducer,
  scoutReducer
} from './utility/reducer_utilities';

import {
  Di1_ViewerReducer,
  Di1_EditorReducer,
  Di1_SearchTermsReducer,
  Di1_QuizReducer
} from './di1/di1-reducers'

import {
  timerReducer
} from './timer/reducer_timer'

const appReducer = combineReducers({
  form            : formReducer, // Redux-form
  viewer          : Di1_ViewerReducer,
  editor          : Di1_EditorReducer,
  lines           : lineReducer,
  scout           : scoutReducer,
  searchTerms     : Di1_SearchTermsReducer,
  quiz            : Di1_QuizReducer,
  timer           : timerReducer,
  globalError     : globalErrorReducer,
  globalAlert     : globalAlertReducer
});

// Resets reducers on log out
const rootReducer = (state, action) => {
  switch(action.type) {
    case SET_LOGOUT:
      //state = undefined
    break;
    default:
    break;
  }
  return appReducer(state, action)
}
export default rootReducer;
