import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import _ from 'lodash';
import LineTo from 'react-lineto'
// Import Components
import {createLines} from '../../../actions/index'

// Set variables

// Main Class
class DrawLine extends Component {
  componentWillReceiveProps(nextProps) {

  }

  renderDrawLine(lineFrom, lineTo, type, index) {
    let css_editorClass = ''
    if(this.props.isEditor) {
      css_editorClass = 'editor-line'
    }
    return (
      <LineTo
        key={Math.random()}
        from={lineFrom}
        to={lineTo}
        className={`lineTo--${type} line-${index} ${css_editorClass}`}
        border="1px solid hot-pink"
      />
    )
  }
  renderDrawLines(lines) {
    return(
      _.map(lines, (line, index) => {
      let lineFrom = line.lineStartClass
      return(
        _.map(line.lineEndClass, (lineTo, index2) => {
        return this.renderDrawLine(lineFrom, lineTo, index, index2)
      })
    )
    })
  )
  }
  render() {
    return (
      <div className="draw-lines">
        {this.renderDrawLines(this.props.lines)}
      </div>
    )
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
return {
  lines: state.lines
  }
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

return bindActionCreators({
  createLines: createLines
}, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DrawLine))
