import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// Set Redux Actions
import {
  setScoutDisabled,
  setScoutVisibility,
  initQuizTimer,
  startQuizTimer,
  stopQuizTimer
} from '../../../actions/index'

// Import Components
import QuizLogin from './quiz-login'
import QuizSubmit from './quiz-submit'
import Sidebar from '../quiz/di-quiz-sidebar'
import ViewerImageArea from '../viewer/di-viewer-image-area'
import DrawLines from '../common/draw-line'
import ViewerOverlays from '../common/viewer-overlays'

// Set variables

// Main Class
class Quiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceUpdateVar: 'moo',
      badURL: false,
    };
    this.checkURL = this.checkURL.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('di-quiz componentDidUpdate');
    if(this.props.match.url !== prevProps.match.url) {
      this.checkURL(this.props)
    }
    if (!this.state.badURL) {
      if(this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse[this.props.match.params.plane]._attributes.scout !==
      prevProps.viewer.anatomy[prevProps.match.params.anatomy].plane_pulse[prevProps.match.params.plane]._attributes.scout) {
        this.checkScout();
      }
    }
  }
  componentWillMount() {
    this.checkURL(this.props)
  }
  componentDidMount() {
    // Start Quiz Timer
    let timerLength = parseInt(this.props.viewer._attributes.duration, 10)*60
    //timerLength = 5;
    this.props.initQuizTimer('quizTimer', timerLength)

    this.checkScout();

    //this.props.startQuizTimer('quizTimer', parseInt(this.props.viewer._attributes.duration, 10)*60)
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  componentWillUnmount() {
    // Start Quiz Timer
    this.props.stopQuizTimer('quizTimer')
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }
  updateDimensions() {
    let d = Date.now()
    this.setState({forceUpdateVar: d})
  }
  checkScout() {
    let scoutImage = this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse[this.props.match.params.plane]._attributes.scout
    // console.log('checkScout', scoutImage)
    if (scoutImage === '' || scoutImage === undefined) {
      this.props.setScoutDisabled(true)
      this.props.setScoutVisibility(false)
    } else {
      this.props.setScoutDisabled(false)
    }
  }
  checkURL(checkProps) {
    // console.log()
    if (!checkProps.match.params.anatomy || checkProps.match.params.anatomy === undefined) {
      // console.log('no URL params, redirecting to something useable');
      const redirectionURL = `/${checkProps.match.params.GUID}/0/0/1`
      this.props.history.replace(redirectionURL);
      this.setState({badURL: true});
    } else if (!checkProps.match.params.plane || checkProps.match.params.plane === undefined) {
      // console.log('no plane URL params, redirecting to something useable');
      const redirectionURL = `/${checkProps.match.params.GUID}/${checkProps.match.params.anatomy}/0/1`
      this.props.history.replace(redirectionURL);
      this.setState({badURL: true});
    } else if (!checkProps.match.params.slice || checkProps.match.params.slice === undefined) {
      // console.log('no image URL params, redirecting to something useable');
      const redirectionURL = `/${checkProps.match.params.GUID}/${checkProps.match.params.anatomy}/${checkProps.match.params.plane}/1`
      this.props.history.replace(redirectionURL);
      this.setState({badURL: true});
    } else {
      this.setState({badURL: false});
    }
  }

  render() {
    if (this.state.badURL) {
      return (
        <div>Redirecting..</div>
      );
    } else {
      return (
        <div className="viewer">
          <QuizLogin />
          <QuizSubmit />
          <Sidebar diType="Quiz" slice={this.props.match.params.slice}/>
          <div className="viewer__container">
            <ViewerOverlays slice={this.props.match.params.slice}/>
            <ViewerImageArea slice={this.props.match.params.slice}/>
          </div>
          <DrawLines forceUpdateVar={this.state.forceUpdateVar}/>
        </div>
      )
    }
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {
    viewer: state.viewer,
    searchTerms: state.searchTerms
  }
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({
    setScoutDisabled,
    setScoutVisibility,
    initQuizTimer,
    startQuizTimer,
    stopQuizTimer
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Quiz))
