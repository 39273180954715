import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import _ from 'lodash'
import Draggable from 'react-draggable';
import MdiPlusCircle from 'mdi-react/PlusCircleIcon';
import MdiMinusCircle from 'mdi-react/MinusCircleIcon';
import MdiRotateRight from 'mdi-react/RotateRightIcon';

// Import Components
import {
  addScoutLine,
  removeScoutLine,
  setScoutLinePosition,
  setScoutLineOrientation
} from '../../../actions/editor-actions'
// Set variables

// Main Class
class ScoutEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLine: false,
      orientation: '0',
      imageDimensions: {},
      startPos: 0,
      startPct: 0,
      flashMaxScoutPos: 128, // This is the max pos value the flash editor could set
      scoutSize: 250,
      editOffset: 3
    };
    this.onImgLoad = this.onImgLoad.bind(this);
  }
  componentDidMount() {
    let startPos = null;
    let startPct = null;
    let isLine = false;
    let orientation = '0';
    if(this.props.viewer.anatomy[parseInt(this.props.match.params.anatomy, 10)].plane_pulse[this.props.match.params.plane].image[this.props.currentSlice]._attributes.pos){
      startPos = this.props.viewer.anatomy[parseInt(this.props.match.params.anatomy, 10)].plane_pulse[this.props.match.params.plane].image[this.props.currentSlice]._attributes.pos
      startPct = this.linePosToPct(startPos, this.state.flashMaxScoutPos)
      isLine = true;
    }
    if(this.props.viewer.anatomy[parseInt(this.props.match.params.anatomy, 10)].plane_pulse[parseInt(this.props.match.params.plane, 10)]._attributes.orient) {
      orientation = this.props.viewer.anatomy[parseInt(this.props.match.params.anatomy, 10)].plane_pulse[parseInt(this.props.match.params.plane, 10)]._attributes.orient;
    }

    this.setState({
      ...this.state,
      startPos,
      startPct,
      isLine,
      orientation
    })
  }
  componentDidUpdate(prevProps, prevState){
    let startPos = null;
    let startPct = null;
    let isLine = false
    let updateState = false;
    if(this.props.currentSlice !== prevProps.currentSlice) {
      // console.log('currentSlice change')
      if(this.props.viewer.anatomy[parseInt(this.props.match.params.anatomy, 10)].plane_pulse[this.props.match.params.plane].image[this.props.currentSlice]._attributes.pos){
        startPos = this.props.viewer.anatomy[parseInt(this.props.match.params.anatomy, 10)].plane_pulse[this.props.match.params.plane].image[this.props.currentSlice]._attributes.pos
        startPct = this.linePosToPct(startPos, this.state.flashMaxScoutPos)
        isLine = true
        updateState = true;
      }
      // console.log('startPos', startPos)
    }

    if((this.state.isLine !== prevState.isLine) || isLine) {
      if(this.state.isLine) {
        startPos = this.props.viewer.anatomy[parseInt(this.props.match.params.anatomy, 10)].plane_pulse[this.props.match.params.plane].image[this.props.currentSlice]._attributes.pos
        startPct = this.linePosToPct(startPos, this.state.flashMaxScoutPos)
        updateState = true;
      }
    }
    if(updateState) {
      this.setState({
        startPos,
        startPct
      })
    }
  }

  renderScoutImage(image) {
    let imageURL = `${this.props.viewer._attributes.imagesbaseurl}${image}`
    return (<img onLoad={this.onImgLoad} src={imageURL} alt="Scout"/>)
  }

  onImgLoad(img) {
    this.setState({
      ...this.state,
      imageDimensions: {
        height: img.target.naturalHeight,
        width: img.target.naturalWidth
      }
    });
  }

  linePosToPct(position, parentSize) {
    const percent = (position / parentSize) * 100
    return (percent)
  }

  linePctToPx(percent){
    return(percent * this.state.scoutSize)
  }

  renderLines(linePlane) {
    // orient: 0 = vertical, 1 = horizontal
    let orient = linePlane._attributes.orient;
    let orientationClass = 'line--horizontal'
    let orientation = 'top';
    let currentLineStyle, minLineStyle, maxLineStyle = {top: '0px'};
    let defaultPosition = {x: 10, y: 0}

    let lines = []
    let minLinePos = 999;
    let maxLinePos = 1;

    console.log('orient: ', orient)
    // Set orientation
    if(orient === '1') {
      orientationClass = 'line--vertical'
      orientation = "left"
      defaultPosition = {x: 0, y: 10}
    }
    // Set line positions, convert to percentage
    _.map(linePlane.image, image => {
      if(image._attributes.pos){
        lines.push(parseInt(image._attributes.pos, 10))
      }
    })
    minLinePos = _.min(lines)
    maxLinePos = _.max(lines)
    minLineStyle = {[orientation] : this.linePosToPct(minLinePos, this.state.flashMaxScoutPos) + '%'} //minLinePos+ 'px'}
    maxLineStyle = {[orientation] : this.linePosToPct(maxLinePos, this.state.flashMaxScoutPos) + '%'} //maxLinePos+ 'px'}

    if(linePlane.image[this.props.currentSlice]._attributes.pos){
      let currentPos = linePlane.image[this.props.currentSlice]._attributes.pos;
      currentLineStyle = {[orientation] : this.linePosToPct(currentPos - this.state.editOffset, this.state.flashMaxScoutPos) + '%'} //currentPoslinePlane+ 'px'}
    } else {
      currentLineStyle = {display: 'none'}
    }

    return(
      <div className="scout__lines" style={{maxWidth: this.state.scoutSize+'px', maxHeight:this.state.scoutSize+'px'}}>
      <div className={`scout__line line--min ${orientationClass}`} style={minLineStyle}></div>
      <Draggable
        axis="both"
        defaultPosition ={ defaultPosition }
        bounds=".scout__lines"
        onStart={this.handleStartDrag}
        onStop={this.handleStopDrag}
        >
        <div className={`scout__line ${orientationClass} editor-line-drag-container`} style={currentLineStyle}>
          <div className={`scout__line line--pos ${orientationClass}`}></div>
        </div>
      </Draggable>
      <div className={`scout__line line--max ${orientationClass}`} style={maxLineStyle}></div>
    </div>
    )
  }
  handleStartDrag = (e, data) => {
  }

  handleStopDrag = (e, data) => {

    let initialHTMLPos = this.state.scoutSize * (this.state.startPct/100)
    let newHTMLPos = initialHTMLPos + data.y
    if(this.state.orientation === '1') {
      newHTMLPos = initialHTMLPos + data.x
    }
    let newHTMLPct = newHTMLPos / this.state.scoutSize ;
    let newFlashPos = Math.floor(newHTMLPct * 128);

    const propData = {
      anatomyIndex: this.props.match.params.anatomy,
      planeIndex: this.props.match.params.plane,
      sliceIndex: this.props.match.params.slice,
      pos: newFlashPos // this.state.flashMaxScoutPos/2
    }
    console.log("newPos: ", data.x, newFlashPos)
    this.props.setScoutLinePosition(propData)
  }
  handleAddRemoveLineClick = () => {
    const data = {
      anatomyIndex: this.props.match.params.anatomy,
      planeIndex: this.props.match.params.plane,
      sliceIndex: this.props.match.params.slice,
      pos: 64 // this.state.flashMaxScoutPos/2
    }
    if(this.state.isLine) {
      this.props.removeScoutLine(data)
    } else {
      this.props.addScoutLine(data)
    }
    this.setState({
      isLine: !this.state.isLine
    })
  }
  handleRotateClick = () => {
    console.log("handleRotateClick")
    const newOrientation = this.state.orientation === '0' ? '1' : '0';
    const data = {
      anatomyIndex: this.props.match.params.anatomy,
      planeIndex: this.props.match.params.plane,
      sliceIndex: this.props.match.params.slice,
      orientation: newOrientation
    }
    this.props.setScoutLineOrientation(data)
    this.setState({orientation: newOrientation})
  }

  render() {
    const linePlane = this.props.viewer.anatomy[parseInt(this.props.match.params.anatomy, 10)].plane_pulse[parseInt(this.props.match.params.plane, 10)];
    const image = this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse[this.props.match.params.plane]._attributes.scout
    const imageURL = `${this.props.viewer._attributes.imagesbaseurl}${image}`
    let lineBtn = <MdiPlusCircle />;
    let css_lineBtn = 'btn-add';
    let lineText = 'Add a Scout Line';
    if(this.state.isLine){
      lineBtn = <MdiMinusCircle />;
      css_lineBtn = 'btn-remove';
      lineText = 'Remove the Scout Line';
    }
    return (
      <div className="scout edit-mode">
        <div className="scout__rotation" onClick={this.handleRotateClick}>
          <MdiRotateRight />
        </div>
        <div className="scout__image" style={{maxWidth: this.state.scoutSize+'px'}}>
          {this.renderLines(linePlane)}
          <div className={`line-edit ${css_lineBtn}`} onClick={this.handleAddRemoveLineClick}>{lineText}{lineBtn}</div>
          <img onLoad={this.onImgLoad} src={imageURL} alt="Scout"/>
        </div>
      </div>
    )
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {
    viewer: state.viewer,
    scout: state.scout,
    editor: state.editor
  }
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({
    addScoutLine,
    removeScoutLine,
    setScoutLinePosition,
    setScoutLineOrientation,
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScoutEditor))
