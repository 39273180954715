import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Draggable from "react-draggable";
import MdiCursorPointer from "mdi-react/CursorPointerIcon";
// Import Components
import NavBackFwdBtn from "./btn-nav-back-fwd";
import Scout from "./scout";
import SlicePicker from "../editor/di-editor-slice-picker";
import SliceSwitcher from "../editor/di-editor-slice-switcher";
import ScoutPicker from "../editor/di-editor-scout-picker";
import ScoutEditor from "../editor/di-editor-scout";
import QuizEditor from "../editor/di-editor-quiz-editor";
// Import actions

// Set variables

// Main Class
class ViewerOverlays extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scoutVisible: false,
      scoutDisabled: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      scoutVisible: nextProps.scout.visible,
      scoutDisabled: nextProps.scout.disabled,
    });
  }
  renderScoutComponent = () => {
    if (this.props.editor.scoutEditMode) {
      return (
        <Draggable disabled axis="both" bounds=".nav__back-fwd">
          <div>
            <ScoutEditor
              key={Date.now()}
              currentSlice={parseInt(this.props.match.params.slice, 10) - 1}
            />
          </div>
        </Draggable>
      );
    } else {
      return (
        <Draggable axis="both" bounds=".nav__back-fwd">
          <div>
            <Scout
              currentSlice={parseInt(this.props.match.params.slice, 10) - 1}
            />
          </div>
        </Draggable>
      );
    }
  };
  renderViewerURL() {
    const viewerURL = `${window.location.host}/#/${this.props.match.params.GUID}`;
    if (this.props.isEditor) {
      return (
        <div className="viewer-url-bar">
          <span className="viewer-url-bar__title">URL:</span>
          <span className="viewer-url-bar__url">{viewerURL}</span>
        </div>
      );
    } else {
      return null;
    }
  }
  renderEditorInstructions() {
    if (this.props.isEditor) {
      return (
        <div className="edit-mode__border">
          <div className="edit-mode__instructions">
            <div className="instructions__icon">
              <MdiCursorPointer />
            </div>
            <div className="instructions__title">
              CLICK on the slice the create a line to that point
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
  render() {
    let css_editMode_Style = "";
    if (this.props.editor.newLineMode) {
      css_editMode_Style = "edit-mode";
    }

    if (this.state.scoutVisible && !this.state.scoutDisabled) {
      return (
        <div className={`viewer__overlays ${css_editMode_Style}`}>
          <div className="edit-mode__border"></div>
          {this.renderViewerURL()}
          <SlicePicker />
          <SliceSwitcher />
          <ScoutPicker />
          <QuizEditor />
          <NavBackFwdBtn
            currentSliceNum={this.props.slice}
            maxSliceNum={this.props.maxSliceNum}
            isEditor={this.props.isEditor}
          />
          {this.renderScoutComponent()}
        </div>
      );
    } else {
      return (
        <div className={`viewer__overlays ${css_editMode_Style}`}>
          {this.renderViewerURL()}
          {this.renderEditorInstructions()}
          <SlicePicker />
          <ScoutPicker />
          <SliceSwitcher />
          <QuizEditor />
          <NavBackFwdBtn
            currentSliceNum={this.props.slice}
            maxSliceNum={this.props.maxSliceNum}
            isEditor={this.props.isEditor}
          />
        </div>
      );
    }
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {
    viewer: state.viewer,
    editor: state.editor,
    scout: state.scout,
  };
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewerOverlays)
);
