import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import Toggle from "react-toggle";
import { RIETextArea } from "@attently/riek";
import { authData } from "../editor-wrapper";
// Set Redux Actions
import {
  resetLines,
  setScoutVisibility,
  setScoutDisabled,
  setQuiz
} from "../../../actions/index";

import {
  di1Editor_SetSlice_Remove,
  di1Editor_removeScout,
  di1Editor_SetTitle,
  di1Editor_setSlicePickerVisibility,
  di1Editor_SetSlice_At,
  di1Editor_setSliceSwitcherVisibility,
  di1Editor_setScoutPickerVisibility,
  setQuizEditorVisibility,
  newLine_disable,
  setScoutEditMode
} from "../../../actions/editor-actions";

import {
  di1EditorGetQuizList,
  di1EditorSetQuizList,
  di1EditorSetViewerList
} from "../../../actions/editor-api-actions";

// Import Components
import TermList from "./di-editor-term-list";
import SubSideBar from "./di-editor-sub-sidebar";
import NavItem from "../nav/nav-item";
import NavImageNum from "./di-editor-nav-image-number";
import Sprite from "../../svg_sprite";
import MdiPencil from "mdi-react/PencilIcon";
import MdiPlusCircle from "mdi-react/PlusCircleIcon";
import MdiMinusCircle from "mdi-react/MinusCircleIcon";

// Set variables

// Main Class
class EditorSidebar extends Component {
  constructor() {
    super();
    this.state = {
      subSidebar: {
        isOpen: false,
        type: "",
        title: "select an anatomy",
        data: [{ anatomyIndex: 0, planeIndex: 0, textField: "" }]
      },
      mobileTermBar: {
        isOpen: false
      },
      viewerURL: {
        anatomy: 0,
        plane: 0,
        slice: 0
      },
      imageSetTitle: {
        editable: false,
        activeClass: "",
        title: ""
      },
      navItemOpen: "",
      searchIsOpen: false
    };
    this.toggleSearch = this.toggleSearch.bind(this);
    this.toggleScout = this.toggleScout.bind(this);
    this.toggleMobileSidebar = this.toggleMobileSidebar.bind(this);
  }

  componentDidMount() {
    // console.log('componentDidMount: ', this.props)
    if (this.props) {
      this.setState({
        viewerURL: {
          anatomy: parseInt(this.props.match.params.anatomy, 10),
          plane: parseInt(this.props.match.params.plane, 10),
          slice: parseInt(this.props.match.params.slice, 10) - 1
        },
        imageSetTitle: {
          ...this.state.imageSetTitle,
          title: this.props.viewer._attributes.name
        }
      });
      this.props.di1EditorGetQuizList(authData.quizzesDocStoreGUID, () => {
        // console.log("componentDidMount di1GetQuizList")
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("di-editor-sidebar componentDidUpdate")
    let newState = { ...this.state };
    let updateState = false;

    if (prevProps.viewer !== this.props.viewer) {
      // console.log("di-editor-sidebar componentDidUpdate Viewer Data Changed")

      updateState = true;
      let subSideBarData = [];
      let subNavData = {};
      switch (this.state.subSidebar.type) {
        case "anatomy":
          subNavData = this.props.viewer.anatomy;
          break;
        case "plane":
          subNavData = this.props.viewer.anatomy[this.state.viewerURL.anatomy]
            .plane_pulse;
          break;
        default:
          break;
      }
      // console.log("di-editor-sidebar componentDidUpdate subNavData", subNavData)
      _.map(subNavData, (data, index) => {
        var listData = {
          value: index,
          textField: data._attributes.menuname
        };
        if (this.state.subSidebar.type === "plane") {
          listData.anatomyIndex = this.state.viewerURL.anatomy;
        }
        // console.log("di-editor-sidebar componentDidUpdate listData", listData)
        subSideBarData.push(listData);
      });
      newState.subSidebar = {
        ...newState.subSidebar,
        data: subSideBarData
      };
    }

    if (
      this.props.match.params.anatomy !== prevProps.match.params.anatomy ||
      this.props.match.params.plane !== prevProps.match.params.plane ||
      this.props.match.params.slice !== prevProps.match.params.slice
    ) {
      updateState = true;
      newState.viewerURL = {
        ...newState.viewerURL,
        anatomy: parseInt(this.props.match.params.anatomy, 10),
        plane: parseInt(this.props.match.params.plane, 10),
        slice: parseInt(this.props.match.params.slice, 10) - 1
      };
    }

    if (updateState) {
      this.setState({ ...newState });
    }
  }

  //// Term Data
  getTermData() {
    let termData = [];
    if (
      this.props.viewer.anatomy[this.state.viewerURL.anatomy].plane_pulse &&
      this.props.viewer.anatomy[this.state.viewerURL.anatomy].plane_pulse[
        this.state.viewerURL.plane
      ].image
    ) {
      let prevIndex = 0;
      try {
        const {anatomy, plane, slice} = this.state.viewerURL;
        prevIndex = this.props.viewer.anatomy.slice(0, anatomy).selectMany(a => (a.plane_pulse || []).selectMany(p => (p.image || []).selectMany(i => i.term || []))).length;
        if (plane > 0) prevIndex += this.props.viewer.anatomy[anatomy].plane_pulse.slice(0, plane).selectMany(p => (p.image || []).selectMany(i => i.term || [])).length;
        if (slice > 0) prevIndex += this.props.viewer.anatomy[anatomy].plane_pulse[plane].image.slice(0, slice).selectMany(i => i.term || []).length;
      } catch (error) {}
      _.map(
        this.props.viewer.anatomy[this.state.viewerURL.anatomy].plane_pulse[
          this.state.viewerURL.plane
        ].image[this.state.viewerURL.slice].term,
        (value, index) => {
          var myValue = {
            number: this.props.viewer._attributes.quiz === "1" ? prevIndex + index + 1 : 0,
            value: index,
            textField: value._attributes.text,
            location: value.line
          };
          termData.push(myValue);
        }
      );
    }
    // console.log('termData: ',termData)
    return termData;
  }

  hasTerms() {
    return this.props.viewer.anatomy[this.state.viewerURL.anatomy].plane_pulse[
      this.state.viewerURL.plane
    ].image[this.state.viewerURL.slice].term
      ? true
      : false;
  }

  toggleSearch() {
    this.setState({
      searchIsOpen: !this.state.searchIsOpen
    });
  }

  toggleSubSidebar() {
    if (this.state.subSidebar.isOpen) {
      this.setState({
        subSidebar: {
          isOpen: !this.state.subSidebar.isOpen
        },
        navItemOpen: ""
      });
    }
  }

  toggleScout() {
    this.props.setScoutVisibility(!this.props.scout.visible);
    if (this.props.editor.scoutEditMode && this.props.scout.visible) {
      this.props.setScoutEditMode(false);
    }
  }

  toggleMobileSidebar() {
    this.props.resetLines();
    this.setState({
      mobileTermBar: {
        isOpen: !this.state.mobileTermBar.isOpen
      }
    });
  }

  handleAnatomyClick() {
    let anatomyData = [];
    let navItemOpen = "anatomy";
    let isOpen = true;
    if (this.props.viewer) {
      _.map(this.props.viewer.anatomy, (value, index) => {
        var myValue = {
          value: index,
          textField: value._attributes.menuname
        };
        anatomyData.push(myValue);
      });
    }
    if (
      this.state.subSidebar.type === "anatomy" &&
      this.state.subSidebar.isOpen
    ) {
      isOpen = false;
      navItemOpen = "";
    }
    this.setState({
      navItemOpen,
      subSidebar: {
        isOpen: isOpen,
        type: "anatomy",
        title: "Select an Anatomy",
        activeItem: this.state.viewerURL.anatomy,
        data: anatomyData
      }
    });
    this.props.newLine_disable();
  }

  handlePlaneClick() {
    let planeData = [];
    let navItemOpen = "plane";
    let isOpen = true;

    let currentAnatomy = this.state.viewerURL.anatomy;
    _.map(
      this.props.viewer.anatomy[currentAnatomy].plane_pulse,
      (value, index) => {
        var myValue = {
          anatomyIndex: currentAnatomy,
          value: index,
          textField: value._attributes.menuname
        };
        // console.log("handlePlaneClick Value: ", myValue)
        planeData.push(myValue);
      }
    );
    if (
      this.state.subSidebar.type === "plane" &&
      this.state.subSidebar.isOpen
    ) {
      isOpen = false;
      navItemOpen = "";
    }
    this.setState({
      navItemOpen,
      subSidebar: {
        isOpen,
        type: "plane",
        title: "Select a Plane/Pulse",
        activeItem: this.state.viewerURL.plane,
        data: planeData
      }
    });
    this.props.newLine_disable();
  }

  //////////////////////////////////////////////////////////////////////////////
  // Image Edit

  onAddSliceBefore = () => {
    // console.log('onAddSlice')
    this.props.di1Editor_SetSlice_At(-1);
    this.props.di1Editor_setSlicePickerVisibility(!this.props.imagePickerVisibility);
    this.props.resetLines();
  };
  onAddSliceAfter = () => {
    // console.log('onAddSlice')
    this.props.di1Editor_SetSlice_At(0);
    this.props.di1Editor_setSlicePickerVisibility(!this.props.imagePickerVisibility);
    this.props.resetLines();
  };

  onSwitchSlice = () => {
    console.log("onSwitchSlice");
    this.props.di1Editor_setSliceSwitcherVisibility(
      !this.props.imageSwitcherVisibility
    );
    this.props.resetLines();
  };

  onRemoveSlice = () => {
    // console.log('onRemoveSlice')
    var data = {
      anatomyIndex: this.props.match.params.anatomy,
      planeIndex: this.props.match.params.plane,
      removeSlice: this.props.match.params.slice
    };
    this.props.di1Editor_SetSlice_Remove(data);
    this.props.resetLines();
  };

  // Image Edit End
  //////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////
  // Title Edit

  handleEditClick = () => {
    // console.log("handleEditClick")
    // console.log("handleEditClick")
    if (!this.state.imageSetTitle.editable) {
      this.setState({
        imageSetTitle: {
          ...this.state.imageSetTitle,
          editable: true,
          activeClass: "active"
        }
      });
    } else {
      this.setState({
        imageSetTitle: {
          ...this.state.imageSetTitle,
          editable: false,
          activeClass: ""
        }
      });
    }
    this.props.newLine_disable();
  };

  onTitleEditFinish(e) {
    // console.log('onTitleEditFinish')
    this.setState({
      imageSetTitle: {
        ...this.state.imageSetTitle,
        editable: false,
        activeClass: ""
      }
    });
  }

  onTitleChange(e) {
    let newList = "";
    if (
      this.props.viewer._attributes.quiz &&
      this.props.viewer._attributes.quiz === "1"
    ) {
      _.map(this.props.editor.existingQuizzes, (quiz, index) => {
        if (quiz.guid === this.props.match.params.GUID) {
          quiz.name = e.text;
        }
        const quizName = _.escape(quiz.name);
        const quizEntry = `<viewer name="${quizName}" guid="${quiz.guid}" />`;
        newList += quizEntry;
      });
      let newListXML = `<viewers>${newList}</viewers>`;

      let quizListData = {
        sSetPassword: authData.viewersDocStoreSetPassword,
        sDocIdentity: authData.quizzesDocStoreGUID,
        sBinString: newListXML
      };
      di1EditorSetQuizList(quizListData, res => {
        this.props.di1Editor_SetTitle(e.text);
      });
    } else {
      _.map(this.props.editor.existingViewers, (viewer, index) => {
        if (viewer.guid === this.props.match.params.GUID) {
          viewer.name = e.text;
        }
        const viewerName = _.escape(viewer.name);
        const viewerEntry = `<viewer name="${viewerName}" guid="${viewer.guid}" />`;
        newList += viewerEntry;
      });
      let newListXML = `<viewers>${newList}</viewers>`;

      let viewerListData = {
        sSetPassword: authData.viewersDocStoreSetPassword,
        sDocIdentity: authData.viewersDocStoreGUID,
        sBinString: newListXML
      };
      di1EditorSetViewerList(viewerListData, res => {
        this.props.di1Editor_SetTitle(e.text);
      });
    }
  }

  validateString(string) {
    return string.length >= 1; // At least one letter
  }

  renderTitleArea = () => {
    if (this.state.imageSetTitle.editable) {
      return (
        <RIETextArea
          value={this.props.viewer._attributes.name}
          change={this.onTitleChange.bind(this)}
          propName="text"
          editing={true}
          selectAll={true}
          afterFinish={this.onTitleEditFinish.bind(this)}
        />
      );
    } else {
      return (
        <span className="title__viewer">
          {this.props.viewer._attributes.name}
        </span>
      );
    }
  };
  // Title Edit End
  //////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////
  // Quiz Edit Start
  toggleQuiz = () => {
    let newQuizState = true;
    if (this.props.viewer._attributes.quiz === "1") {
      newQuizState = false;
    }
    this.props.setQuiz(newQuizState);
  };
  toggleQuizEditor = () => {
    this.props.resetLines();
    this.props.setQuizEditorVisibility(!this.props.editor.quizEditorVisiblity);
  };

  renderQuizSidebarArea = () => {
    if (this.props.viewer._attributes.quiz === "1") {
      const css_quizEditMode = this.props.editor.quizEditorVisiblity
        ? "edit-mode"
        : "";
      return (
        <div className={`sidebar__footer--item editor-footer-item`}>
          <div className="sidebar__footer--item--title">Quiz</div>
          <div className="nav__slider">
            {/* <Toggle
            onChange={this.toggleQuiz}
            className='quiz__toggle'
            checked={true}
          /> */}
          </div>
          <div className="icon-area">
            <div
              className={`icon edit ${css_quizEditMode}`}
              onClick={this.toggleQuizEditor}
            >
              <MdiPencil />
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  //////////////////////////////////////////////////////////////////////////////
  // Scout Edit Start

  onEditScout = () => {
    this.props.setScoutEditMode(!this.props.editor.scoutEditMode);
  };
  onAddRemoveScout = () => {
    console.log("onAddRemoveScout", this.props.scout.disabled);
    const data = {
      anatomyIndex: this.props.match.params.anatomy,
      planeIndex: this.props.match.params.plane
    };
    if (this.props.scout.disabled) {
      this.props.di1Editor_setScoutPickerVisibility(true);
    } else {
      this.props.setScoutDisabled(true);
      this.props.setScoutVisibility(false);
      this.props.di1Editor_removeScout(data);
    }
  };
  // Scout Edit End
  //////////////////////////////////////////////////////////////////////////////
  render() {
    // console.log("di-editor-sidebar render")
    let anatomyOpen = false;
    let planeOpen = false;
    let hasSlice = false;
    let editorTitle = this.props.diType;

    const css_scoutEditMode = this.props.editor.scoutEditMode
      ? "edit-mode"
      : "";
    const css_scoutDisabled = this.props.scout.disabled ? "disabled" : "";

    if (this.state.navItemOpen === "anatomy") {
      anatomyOpen = true;
    } else if (this.state.navItemOpen === "plane") {
      planeOpen = true;
    }

    let currentPlaneView = "NO PLANE CREATED";
    if (this.props.viewer.anatomy[this.state.viewerURL.anatomy].plane_pulse) {
      currentPlaneView = this.props.viewer.anatomy[this.state.viewerURL.anatomy]
        .plane_pulse[this.state.viewerURL.plane]._attributes.menuname;
    }
    if (
      this.props.viewer.anatomy[this.state.viewerURL.anatomy].plane_pulse[
        this.state.viewerURL.plane
      ].image
    ) {
      hasSlice = true;
    }

    let css_scout_removeMode = "add-scout";
    let scoutIcon = <MdiPlusCircle />;
    if (!this.props.scout.disabled) {
      css_scout_removeMode = "remove-scout";
      scoutIcon = <MdiMinusCircle />;
    }

    if (this.props.viewer._attributes.quiz === "1") {
      editorTitle = "Quiz " + this.props.diType;
    }

    return (
      <div className="sidebar">
        <div className="mainSidebar">
          <div className={`sidebar__header ${this.props.diType}`}>
            <div className="title">
              <h1 className="title__text">{editorTitle}</h1>
              <div className="title__sprite">
                <Sprite sprite="logo-nait-shield" />
              </div>
            </div>
            <div
              className={`viewer__title ${this.state.imageSetTitle.activeClass}`}
            >
              <span className="headline">Enter a new title</span>
              {this.renderTitleArea()}
              <span
                className="viewer__title__icon edit"
                onClick={this.handleEditClick}
              >
                <MdiPencil />
              </span>
            </div>
          </div>
          <div className="sidebar__nav">
            <NavItem
              title="Anatomy"
              currentView={
                this.props.viewer.anatomy[this.state.viewerURL.anatomy]
                  ._attributes.menuname
              }
              onClicky={this.handleAnatomyClick.bind(this)}
              isActive={anatomyOpen}
            />
            <NavItem
              title="Plane/Pulse"
              currentView={currentPlaneView}
              onClicky={this.handlePlaneClick.bind(this)}
              isActive={planeOpen}
            />
            <NavImageNum
              currentNum={this.props.currentSliceNum}
              maxNum={this.props.maxSliceNum}
              onAddSliceBefore={this.onAddSliceBefore}
              onAddSliceAfter={this.onAddSliceAfter}
              onSwitchSlice={this.onSwitchSlice}
              onRemoveSlice={this.onRemoveSlice}
            />
          </div>
          <div className="termList-title">Terms</div>
          <TermList data={this.getTermData()} hasSlice={hasSlice} />
          <div className="sidebar__footer">
            {this.renderQuizSidebarArea()}

            <div
              className={`sidebar__footer--item editor-footer-item ${css_scoutEditMode}`}
            >
              <div className="sidebar__footer--item--title">Scout</div>
              <div className="nav__slider">
                <Toggle
                  onChange={this.toggleScout}
                  className="scout__toggle"
                  checked={this.props.scout.visible}
                  disabled={this.props.scout.disabled}
                />
              </div>
              <div className="icon-area">
                <div
                  className={`icon edit ${css_scoutDisabled}`}
                  onClick={this.onEditScout}
                >
                  <MdiPencil />
                </div>
                <div
                  className={`icon remove ${css_scout_removeMode}`}
                  onClick={this.onAddRemoveScout}
                >
                  {scoutIcon}
                </div>
              </div>
            </div>
          </div>
        </div>
        <SubSideBar
          isOpen={this.state.subSidebar.isOpen}
          toggleSubSidebar={this.toggleSubSidebar.bind(this)}
          type={this.state.subSidebar.type}
          title={this.state.subSidebar.title}
          activeItem={this.state.subSidebar.activeItem}
          data={this.state.subSidebar.data}
        />
      </div>
    );
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {
    //viewer: state.viewerURL,
    scout: state.scout,
    imagePickerVisibility: state.editor.imagePicker.sliceVisible,
    imageSwitcherVisibility: state.editor.imagePicker.switchVisible,
    editor: state.editor
  };
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetLines,
      setScoutVisibility,
      setScoutDisabled,
      di1Editor_SetTitle,
      di1Editor_setSlicePickerVisibility,
      di1Editor_SetSlice_At,
      di1Editor_setSliceSwitcherVisibility,
      di1Editor_setScoutPickerVisibility,
      di1Editor_SetSlice_Remove,
      setQuizEditorVisibility,
      setQuiz,
      newLine_disable,
      di1Editor_removeScout,
      setScoutEditMode,
      di1EditorGetQuizList
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditorSidebar));
