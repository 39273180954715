import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import _ from "lodash";
// Import actions
import {
  di1GETImageList,
  di1Editor_SetSlice_Add,
  di1Editor_setSlicePickerHidden
} from "../../../actions/editor-actions";
// Import Components
import SliceGallery from "./di-editor-image-gallery";
// Set variables

//eslint-disable-next-line
let planeSlices = [];
// Main Class
class SlicePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageList: [],
      selectedImages: [],
      searchData: []
    };
    this.filterList = this.filterList.bind(this);
  }

  componentDidMount() {
    this.props.di1GETImageList(this.props.imagesBaseURL);
  }
  componentWillReceiveProps(newProps) {
    this.setState({
      searchData: newProps.editor.imageList
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.viewer.anatomy[this.props.match.params.anatomy] &&
      this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse &&
      this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse[
        this.props.match.params.plane
      ].image
    ) {
      this.planeSlices = this.props.viewer.anatomy[
        this.props.match.params.anatomy
      ].plane_pulse[this.props.match.params.plane].image;
    }
    if (
      _.isEmpty(
        this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse[
          this.props.match.params.plane
        ].image
      )
    ) {
      this.planeSlices = [];
    }
  }
  componentWillUnmount() {
    this.props.di1Editor_setSlicePickerHidden();
  }

  filterList(event) {
    // console.log(event.target.value)
    let updatedImageList = [];
    _.map(this.props.editor.imageList, term => {
      updatedImageList.push(term);
    });
    updatedImageList = _.orderBy(
      updatedImageList,
      [item => item.path.toLowerCase()],
      ["asc"]
    );

    let filteredImagePath = updatedImageList.filter(function(item) {
      if (event.target.value.includes("\\")) return false;
      return (
        item.path.toLowerCase().search(event.target.value.toLowerCase()) !== -1
      );
    });
    // console.log(filteredImagePath);
    this.setState({ searchData: filteredImagePath });
  }

  onSelectImage = image => {
    const slicePos = parseInt(this.props.match.params.slice, 10) + parseInt(this.props.editor.imagePicker.insertSliceAt, 10)
    const newSlice = { _attributes: { path: image.path } };
    let data = {
      anatomyIndex: this.props.match.params.anatomy,
      planeIndex: this.props.match.params.plane,
      sliceIndex: slicePos,
      newSlice,
      slicePos
    };
    // console.log('data: ', data)
    this.props.di1Editor_SetSlice_Add(data);
    this.props.di1Editor_setSlicePickerHidden();
    const redirectionURL = `/editor/${this.props.match.params.GUID}/${data.anatomyIndex}/${data.planeIndex}/${slicePos + 1}`;
    this.props.history.push(redirectionURL);
  };

  render() {
    const slicePos = parseInt(this.props.match.params.slice, 10) + 1 + this.props.editor.imagePicker.insertSliceAt;
    if (this.props.editor.imagePicker.sliceVisible) {
      return (
        <div className="slice-picker visible">
          <div className="slice-picker__card card">
            <div className="slice-picker__title card-title">Pick a new slice to insert at postion {slicePos}</div>
            <div className="slice-picker__search">
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Search"
                onChange={this.filterList}
              />
            </div>
            <Scrollbars
              className="scroll-area"
              renderThumbVertical={props => (
                <div {...props} className="slice-picker__scrollbar" />
              )}
            >
              <SliceGallery
                images={this.state.searchData} //this.props.editor.imageList}
                onSelectImage={this.onSelectImage}
                planeSlices={this.planeSlices}
              />
            </Scrollbars>
            <div className="slice-picker__footer">
              {/* <button type="button" className="btn btn-primary">Pick Slices</button> */}
              <button
                type="button"
                className="btn btn-danger"
                onClick={this.props.di1Editor_setSlicePickerHidden}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return <div className="slice-picker hidden"></div>;
    }
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {
    viewer: state.viewer,
    editor: state.editor,
    imagesBaseURL: state.viewer._attributes.imagesbaseurl
  };
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      di1GETImageList,
      di1Editor_setSlicePickerHidden,
      di1Editor_SetSlice_Add,
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SlicePicker)
);
