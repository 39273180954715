import React, { Component } from "react";
import { connect } from "react-redux";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";
import { isIE } from "react-device-detect";
import TagManager from "react-gtm-module";

import "./scss/main.css";

import OrientationOverlay from "./components/views/orientation";
import Alert from "./components/alert";
import Main from "./components/views/main";
import SelectViewer from "./components/views/select-viewer";

// Editor
import EditorAuthWrapper from "./components/views/editor-auth-wrapper";
// import AuthLogin from './components/views/auth-login';
// import SelectEditor from './components/views/select-editor';

// Google Tag Manager
const tagManagerArgs = {
  gtmId: "GTM-KFJB7DP",
};

TagManager.initialize(tagManagerArgs);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount() {}
  componentDidCatch(error, info) {
    // Display fallback UI
    //this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  renderApp() {
    if (window.location.pathname !== "/assets/NAIT/diviewer/html5/")
      window.location.replace("/assets/NAIT/diviewer/html5/");

    let ROOT_URL;
    if (module.hot) {
      ROOT_URL = "/";
    } else {
      ROOT_URL = "/";
    }
    return (
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>NAIT Diagnostic Image Viewer</title>
          <link rel="shortcut icon" href="/favicons.png" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, user-scalable=no"
          />
          <meta name="HandheldFriendly" content="True" />
        </Helmet>
        <Alert />
        <OrientationOverlay />
        <HashRouter basename={ROOT_URL}>
          <Switch>
            <Route
              path="/editor/:GUID/:anatomy/:plane/:slice"
              component={EditorAuthWrapper}
            />
            <Route
              path="/editor/:GUID/:anatomy/:plane"
              component={EditorAuthWrapper}
            />
            <Route
              path="/editor/:GUID/:anatomy/"
              component={EditorAuthWrapper}
            />
            <Route path="/editor/:GUID/" component={EditorAuthWrapper} />
            <Route path="/editor/" component={EditorAuthWrapper} />

            <Route path="/:GUID/:anatomy/:plane/:slice" component={Main} />
            <Route path="/:GUID/:anatomy/:plane/" component={Main} />
            <Route path="/:GUID/:anatomy" component={Main} />
            <Route
              path="/:GUID"
              render={(props) => (
                <Redirect to={`/${props.match.params.GUID}/0/0/1`} />
              )}
            />
            <Route path="/" component={SelectViewer} />
          </Switch>
        </HashRouter>
      </div>
    );
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    if (isIE) {
      // console.log("is IE: ")
      return (
        <div className="is_ie">
          <div className="is_ie__center">
            <p>
              <strong>
                Sorry, the DI Viewer does not support Internet Explorer.
              </strong>
            </p>
            <p>
              Please use <a href="https://google.com/chrome">Chrome</a>,{" "}
              <a href="https://getfirefox.com">Firefox</a>,{" "}
              <a href="https://www.microsoft.com/en-ca/windows/microsoft-edge">
                Edge
              </a>{" "}
              or <a href="https://support.apple.com/en-ca/safari">Safari</a>
            </p>
          </div>
        </div>
      );
    } else {
      // console.log("not IE: ")
      return this.renderApp();
    }
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
