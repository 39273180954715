import axios from "axios";
import _ from "lodash";
//import xml2js from 'xml2js-es6-promise';
import convert from "xml-js";
import qs from "qs";

// Quiz Timer Actions
import { START_TIMER, STOP_TIMER } from "redux-timer-middleware";
export const QUIZ_TIMER_INIT = "quiz_timer_init";
export const QUIZ_TIMER_START = "quiz_timer_start";
export const QUIZ_TIMER_STOP = "quiz_timer_stop";
export const QUIZ_TIMER_FINISHED = "quiz_timer_finished";
export const QUIZ_TIMER_TICK = "quiz_timer_tick";
export const QUIZ_TIMER_TICK_END = "quiz_timer_tick_END";

// Authentication
export const SET_LOGIN = "set_login";
export const SET_LOGOUT = "set_logout";
export const FETCH_AUTH_STATUS = "feth_auth_status";

export const GET_GLOBAL_ERROR = "get_global_error";
export const GLOBAL_MODAL = "get_global_modal";
export const GLOBAL_ERROR = "global_error";
export const API_ERROR = "api_error";
export const SET_LINES = "set_lines";
export const SET_SCOUT_VISIBILITY = "set_scout_visibility";
export const SET_SCOUT_DISABLED = "set_scout_disabled";

////// 1.0 viewer consts
export const DI1_GET_VIEWER = "di1_get_viewer";
export const DI1_GET_EDITOR = "di1_get_editor";
export const DI1_SET_SEARCH = "di1_set_search";

export const DI1_GET_ANATOMIES = "di1_get_anatomies";
export const DI1_GET_PLANE = "di1_get_plane";
export const DI1_GET_SLICE = "di1_get_slice";
export const DI1_GET_TERMS = "di1_get_terms";

////// 1.0 QUIZ
export const DI1_SET_STUDENT_INFO = "di1_set_student_info";
export const DI1_SET_QUIZ_VIEW = "di1_set_quiz_view";
export const DI1_SET_QUIZ_QUESTIONS_REMAINING =
  "di1_set_quiz_questions_remaining";
export const DI1_SET_QUIZ_QUESTIONS = "di1_set_quiz_questions";
export const DI1_SET_QUIZ_STUDENT_ANSWER = "di1_set_quiz_student_answer";
export const DI1_FINISH_QUIZ = "di1_finish_quiz";

export const DI1_SET_QUIZ = "di1_set_quiz";
export const DI1_SET_QUIZ_MODE = "di1_set_quiz_mode";

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.withCredentials = true;

//this is localhost
const DI1_URL = `${process.env.REACT_APP_DOC_STORAGE_URL}/api/DocStorage/GetStoreXML`;
const DI1_VIEWER_PASS = "diViewerGet";

// Action Creators

// Global Alert
export function globalAlert(s_message) {
  const isVisible = s_message ? true : false;
  const alert = {
    visible: isVisible,
    message: s_message,
  };
  return { type: GET_GLOBAL_ERROR, payload: alert };
}

export function postApiError(message) {
  return { type: API_ERROR, payload: message };
}

export function setGlobalError(message) {
  return { type: GLOBAL_ERROR, payload: message };
}

// Authentication
export function setLogin() {
  return (dispatch) => {
    dispatch({
      type: SET_LOGIN,
      payload: {
        isAuth: true,
      },
    });
  };
}
export function setLogout(state) {
  return (dispatch) => {
    dispatch({
      type: SET_LOGOUT,
      payload: {
        isAuth: false,
      },
    });
  };
}

////////////////////////////////////////////////////////////////////////////////
//// Timer Actions

export function initQuizTimer(timerName, timerPeriod) {
  return (dispatch) => {
    dispatch({ type: QUIZ_TIMER_INIT, payload: { timerPeriod } });
  };
}
export function startQuizTimer(timerName, timerPeriod) {
  return (dispatch) => {
    dispatch({
      type: START_TIMER,
      payload: {
        actionName: QUIZ_TIMER_TICK,
        timerName: timerName,
        timerPeriod: timerPeriod,
      },
    });
    dispatch({ type: QUIZ_TIMER_START, payload: { timerPeriod } });
  };
}

export function stopQuizTimer(timerName) {
  return (dispatch) => {
    dispatch({ type: STOP_TIMER, payload: { timerName: timerName } });
    dispatch({ type: QUIZ_TIMER_STOP, payload: { timerName: timerName } });
  };
}

////////////////////////////////////////////////////////////////////////////////
//// DI 1.0 XML Actions
export function di1GetViewer(viewerGUID, callback) {
  const viewerURL = `${DI1_URL}?sDocIdentity=${viewerGUID}&sGetPassword=${DI1_VIEWER_PASS}`;
  const request = axios({
    method: "get",
    withCredentials: false,
    url: viewerURL,
    alwaysArray: true,
  });
  return (dispatch) => {
    request
      .then((response) => {
        const options = {
          compact: true,
          ignoreDeclaration: true,
          alwaysArray: true,
        };
        let xml2jsonParser = new Promise((resolve, reject) => {
          resolve(convert.xml2js(response.data, options));
        });
        xml2jsonParser.then((parserResponse) => {
          const diData = parserResponse.div_data[0];
          if (diData._attributes.quiz === "1") {
            let quizProps = {
              itemsComplete: 0,
              itemsRemaining: 0,
              totalItems: 0,
              currentView: "login",
            };
            let quizTerms = di1SetupQuiz(diData);
            let quizState = { quizTerms, quizProps };
            dispatch({ type: DI1_SET_QUIZ_QUESTIONS, payload: quizTerms });
            dispatch({
              type: DI1_GET_VIEWER,
              payload: di1SetupQuizIDs(diData),
            });
            dispatch({
              type: DI1_SET_QUIZ_QUESTIONS_REMAINING,
              payload: quizTermsRemaining(quizState),
            });
            // for some reason if GET_VIEWER is dispatched first, it passes
            // di1SetupQuizIDs() return data into di1SetupQuiz()
          } else {
            dispatch({ type: DI1_GET_VIEWER, payload: diData });
          }
          dispatch({
            type: DI1_SET_SEARCH,
            payload: di1SetupSearchTerms(diData),
          });
        });

        callback(response);
      })
      .catch(function (error) {
        parseError(error, dispatch);
      });
  };
}

export function setupStudentInfo(state = {}, studentData) {
  let quizProps = { ...state };
  _.set(quizProps, "studentFirstName", studentData.studentFirstName);
  _.set(quizProps, "studentLastName", studentData.studentLastName);
  _.set(quizProps, "studentID", studentData.studentID);
  _.set(quizProps, "quizGuid", studentData.quizGuid);
  return { type: DI1_SET_STUDENT_INFO, payload: quizProps };
}
export function setQuizStudentAnswer(state = {}, quizID, newStudentAnswer) {
  let quizTerms = { ...state };
  _.set(quizTerms, [quizID, "studentAnswer"], newStudentAnswer);
  return { type: DI1_SET_QUIZ_STUDENT_ANSWER, payload: quizTerms };
}
///////////////////// Adds IDs to the viewer data
export function setQuizTermsRemaining(state = {}) {
  return {
    type: DI1_SET_QUIZ_QUESTIONS_REMAINING,
    payload: quizTermsRemaining(state),
  };
}
///////////////////// Set the current view of the quiz
export function setQuizView(view) {
  return (dispatch) => {
    dispatch({ type: DI1_SET_QUIZ_VIEW, payload: view });
  };
}
///////////////////// Figures out quiz items remaining
function quizTermsRemaining(state = {}) {
  let quizProps = { ...state.quizProps };
  let quizTerms = { ...state.quizTerms };
  let totalItems = Object.keys(quizTerms).length;
  let itemsRemaining = Object.keys(quizTerms).length;
  let itemsComplete = 0;
  _.map(quizTerms, (term, index) => {
    if (term.studentAnswer !== "") {
      itemsComplete++;
      itemsRemaining--;
    }
  });

  _.set(quizProps, "itemsComplete", itemsComplete);
  _.set(quizProps, "itemsRemaining", itemsRemaining);
  _.set(quizProps, "totalItems", totalItems);
  return quizProps;
}

///////////////////// Adds IDs to the viewer data
export function di1SetupQuizIDs(dataID) {
  var quizIDData = dataID;
  let quizID = 1;
  _.map(quizIDData.anatomy, (anatomy, anatomyIndex) => {
    _.map(anatomy.plane_pulse, (plane, planeIndex) => {
      _.map(plane.image, (image, imageIndex) => {
        _.map(image.term, (term, termIndex) => {
          let quizIDAttributes = {
            term: term._attributes.text,
            quizID,
          };
          quizID++;
          _.set(
            quizIDData,
            [
              "anatomy",
              anatomyIndex,
              "plane_pulse",
              planeIndex,
              "image",
              imageIndex,
              "term",
              termIndex,
              "_attributes",
            ],
            quizIDAttributes
          );
        });
      });
    });
  });
  return quizIDData;
}

function di1SetupQuiz(data) {
  let quizData = {};
  let quizID = 0;

  _.map(data.anatomy, (anatomyID, anatomyIndex) => {
    _.map(anatomyID.plane_pulse, (planeID, planeIndex) => {
      _.map(planeID.image, (imageID, imageIndex) => {
        _.map(imageID.term, (termID, termIndex) => {
          let quizURL = `${anatomyIndex}/${planeIndex}/${imageIndex + 1}`;
          let quizAttributes = {
            correctAnswer: termID._attributes.text,
            studentAnswer: "",
            quizID,
            quizURL,
          };
          quizID++;
          _.set(quizData, quizID, quizAttributes);
        });
      });
    });
  });
  return quizData;
}
////////////////////////////////////////////////////////////////////////////////
//// Quiz Functions for the Editor
export function setQuiz(b_isQuiz) {
  const payload = b_isQuiz;
  return { type: DI1_SET_QUIZ_MODE, payload };
}
export function setQuizInfo(data) {
  const payload = { ...data };
  return { type: DI1_SET_QUIZ, payload };
}

////////////////////////////////////////////////////////////////////////////////
//// Search Term Setup
export function di1SetupSearchTerms(data) {
  let searchData = [];
  let termURL = "";
  let termAnatomy = "";
  let termPlane = "";
  let termText = "";
  _.map(data.anatomy, (anatomy, anatomyIndex) => {
    termAnatomy = anatomy._attributes.menuname;
    _.map(anatomy.plane_pulse, (plane, planeIndex) => {
      termPlane = plane._attributes.menuname;
      _.map(plane.image, (image, imageIndex) => {
        _.map(image.term, (term, termIndex) => {
          termURL = `/${anatomyIndex}/${planeIndex}/${imageIndex + 1}`;
          termText = term._attributes.text;
          let termData = {
            termText,
            termURL,
            termAnatomy,
            termPlane,
          };
          searchData.push(termData);
        });
      });
    });
  });
  return searchData;
}
export function submitQuiz(viewer, quiz, timer) {
  let quizProps = { ...quiz.quizProps };

  let options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  let today = new Date();
  const formattedDate = today.toLocaleDateString("en-US", options);

  const readableTimeLeft = formatTime(timer.timeLeft);
  const sStudentName =
    escapeHtml(quizProps.studentLastName) +
    ", " +
    escapeHtml(quizProps.studentFirstName);
  const sStudentID = escapeHtml(quiz.quizProps.studentID);
  let htmlToSubmit = "";

  let htmlHeader = "";
  let htmlBody = "";
  let htmlFooter = "";

  // Old DIV1 way of submitting quiz
  /*
  htmlHeader = `<html><head><title></title></head>
  <body style='font-family:Verdana;font-size:11px;' >
    quiz: ${viewer._attributes.name}<br><br>
    name: ${sStudentName}<br>
    id: ${sStudentID}<br>
    date: ${formattedDate}<br>
    duration: ${timer.timerPeriod/60} minutes. ${readableTimeLeft} remaining when quiz was submitted
    <br><br><b><font color='#990000'>student answer</font></b>: correct answer<br><br>`;
  htmlFooter = "</body></html>"
  htmlBody = ''

  _.map(quiz.quizTerms, (term, termIndex) => {
    let newAnswer = `${term.quizID+1}) <b><font color='#990000'>${term.studentAnswer}</font></b>:${term.correctAnswer}<br>`
    htmlBody = htmlBody + newAnswer
  })
  */
  // Start New way of submitting quiz
  htmlHeader = `
  <html>
    <head>
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
      <title>DIViewer Quiz Result</title>
      <link rel="stylesheet" href="css/bootstrap.min.css">
      <link rel="stylesheet" href="css/diviewer-quiz-result.css">
    </head>
    <body>
      <div class="quiz-contianer">
        <div class="row quiz-head">
          <div class="col-sm-12">
            <div class="row quiz-name">
              <div class="col-sm-6 no-gutters">
                <div class="row no-gutters">
                  <div class="col-sm-4 head-title">
                    Quiz Name:
                  </div>
                  <div class="col-sm-8 head-value">
                    ${viewer._attributes.name}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="row student-info">
              <div class="col-sm-6">
                <div class="row no-gutters">
                  <div class="col-sm-4 head-title">
                    Student Name:
                  </div>
                  <div class="col-sm-8 head-value">
                    ${sStudentName}
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="row no-gutters">
                  <div class="col-sm-3 head-title">
                    Student ID:
                  </div>
                  <div class="col-sm-9 head-value">
                    ${sStudentID}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12">
            <div class="row student-info">
              <div class="col-sm-6">
                <div class="row no-gutters">
                  <div class="col-sm-4 head-title">
                    Quiz Date:
                  </div>
                  <div class="col-sm-8 head-value">
                    ${formattedDate}
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="row no-gutters">
                  <div class="col-sm-3 head-title">
                    Duration:
                  </div>
                  <div class="col-sm-9 head-value">
                    ${
                      timer.timerPeriod / 60
                    } minutes. ${readableTimeLeft} remaining when submitted
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="quiz-results">
          <div class="row quiz-legend">
            <div class="col-sm-1 question-number">#</div>
            <div class="col-sm-5 student-answer">Student Answer</div>
            <div class="col-sm-6 correct-answer">Correct Answer</div>
          </div>
      `;

  htmlFooter = "</div></div></body></html>";
  htmlBody = "";
  _.map(quiz.quizTerms, (term, termIndex) => {
    let sanitized_studentAnswer = escapeHtml(term.studentAnswer);
    let newAnswer = `
      <div class="row quiz-result">
        <div class="col-sm-1 question-number">${term.quizID + 1}</div>
        <div class="col-sm-5 student-answer">${sanitized_studentAnswer}</div>
        <div class="col-sm-6 correct-answer">${term.correctAnswer}</div>
      </div>
      `;
    htmlBody += newAnswer;
  });

  // End new way of submitting quiz

  htmlToSubmit = htmlHeader + htmlBody + htmlFooter;

  if (module.hot) {
    console.log("htmlToSubmit", htmlToSubmit);
    //quizSubmissionURL = "1http://null.com/";
  }

  _.set(quizProps, "quizComplete", true);

  // var params = qs.stringify({
  //   sStudentName,
  //   sQuizData: htmlToSubmit,
  //   sQuizName: viewer._attributes.name,
  //   sStudentID,
  //   sLOGID: 0
  // });
  // const request = axios({
  //   method: "post",
  //   withCredentials: false,
  //   url: quizSubmissionURL,
  //   data: params
  // });

  // Use new method to submit quiz.
  const request = axios({
    method: "post",
    withCredentials: false,
    url: `${process.env.REACT_APP_DOC_STORAGE_URL}/api/DocStorage/SubmitQuiz`,
    data: qs.stringify({
      sStudentName,
      sQuizData: window.btoa(htmlToSubmit),
      sQuizName: viewer._attributes.name,
      sStudentID,
      sLOGID: 1,
      quiz: {
        ...quiz,
        imagesBaseUrl: viewer._attributes.imagesbaseurl,
        guid: viewer._attributes.guid,
      },
    }),
  });
  return (dispatch) => {
    request
      .then((request) => {
        return { type: DI1_FINISH_QUIZ, payload: quizProps };
        //callback()
      })
      .catch(function (error) {
        dispatch(setQuizView("submitError"));
        axios({
          method: "post",
          withCredentials: false,
          url: `${process.env.REACT_APP_DOC_STORAGE_URL}/api/DocStorage/SubmitQuiz`,
          data: qs.stringify({
            sStudentName,
            sQuizData: window.btoa(htmlToSubmit),
            sQuizName: viewer._attributes.name,
            sStudentID,
            sLOGID: 2,
          }),
        });
        parseError(error);
      });
  };
}

function escapeHtml(str) {
  var div = document.createElement("div");
  div.appendChild(document.createTextNode(str));
  return div.innerHTML;
}

////////////////////////////////////////////////////////////////////////////////
// End DI 1.0 XML Functions
////////////////////////////////////////////////////////////////////////////////

// Common functions
export function ajaxify(url, method, formDataParams, callback) {
  let submissionURL = "";
  let params = qs.stringify(formDataParams);

  const request = axios({
    method: method,
    withCredentials: false,
    url: submissionURL,
    data: params,
  });
  return (dispatch) => {
    request
      .then((res) => {
        if (callback) {
          callback(res);
        }
      })
      .catch(function (error) {
        parseError(error);
      });
  };
}

// Scout Actions
export function setScoutVisibility(message) {
  return { type: SET_SCOUT_VISIBILITY, payload: message };
}
export function setScoutDisabled(message) {
  return { type: SET_SCOUT_DISABLED, payload: message };
}

// Line Actions
export function createLines(state = {}, hoverLines, clickLines) {
  let lines = { ...state };
  if (hoverLines === null) {
    _.set(lines, "click", clickLines);
  } else if (clickLines === null) {
    _.set(lines, "hover", hoverLines);
  } else {
    _.set(lines, "click", clickLines);
    _.set(lines, "hover", hoverLines);
  }
  return { type: SET_LINES, payload: lines };
}

export function resetLines() {
  const lines = {};
  return { type: SET_LINES, payload: lines };
}

// Parse any errors that might arrise from the axios request
export function parseError(error, dispatch) {
  console.warn("Error status: ", error);
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(
      "Actions - ParseError: Server responded with error code: ",
      error.response.status
    );
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(
      "Actions - ParseError: The request was made but no response was received: ",
      error.request
    );
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log(
      "Actions - ParseError: An error other than a request or response happened: ",
      error.message
    );
  }
}
function formatTime(time) {
  // Hours, minutes and seconds
  let hrs = Math.floor(time / 3600);
  let mins = Math.floor((time % 3600) / 60);
  let secs = time % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let formattedTime = "";

  if (hrs > 0) {
    formattedTime += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }
  formattedTime += "" + mins + ":" + (secs < 10 ? "0" : "");
  formattedTime += "" + secs;
  return formattedTime;
}

export function addQuizLog(quizGuid, actionType, json) {
  const request = axios({
    method: "post",
    withCredentials: false,
    url: `${process.env.REACT_APP_DOC_STORAGE_URL}/api/DocStorage/AddQuizLog`,
    data: { quizGuid, actionType, json },
  });
  return (dispatch) => {
    request.then().catch(function (error) { parseError(error, dispatch) });
  };
}