import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';
import _ from 'lodash'
import Modal from 'react-modal';
import { Scrollbars } from 'react-custom-scrollbars';

// Set variables

import {resetLines} from '../../../actions/index'
// Main Class
class Search extends Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
      searchData: {},
      resultCount: 0,
    };
    this.keyCount = 0;

    this.getKey = this.getKey.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.filterList = this.filterList.bind(this);
    this.onSearchEntryClick = this.onSearchEntryClick.bind(this);
  }
  getKey(){
    return this.keyCount++;
  }
  componentWillReceiveProps(newProps) {
    this.setState({
      searchData: newProps.searchTerms,
      resultCount: newProps.searchTerms.length,
      modalIsOpen: newProps.modalIsOpen
    })
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    // console.log('close search')
    //this.props.toggleModal
    //this.setState({modalIsOpen: false});
  }
  onSearchEntryClick() {
    this.props.toggleSearch();
    this.props.resetLines();
  }
  filterList(event) {
    // console.log(event.target.value)
    let updatedList = []

    if(event.target.value.length >> 1) {
      _.map(this.props.searchTerms, term => {
        updatedList.push(term)
      });

      updatedList = _.orderBy(updatedList, [item => item.termText.toLowerCase()], ['asc']);
      // console.log('updatedList: ', updatedList)
      let termTextArray = updatedList.filter(function(item) {
        return item.termText.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
      });
      let termAnatomyArray = updatedList.filter(function(item) {
        return item.termAnatomy.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
      });
      let termPlaneArray = updatedList.filter(function(item) {
        return item.termPlane.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
      });
      updatedList = [...termTextArray, ...termAnatomyArray, ...termPlaneArray]

      this.setState({
        searchData: updatedList,
        resultCount: (updatedList.length)
      });
    }

  }
  renderSearchEntry(result) {
    return(
      <div className="result" key={this.getKey()}>
        <Link
          to={`/${this.props.match.params.GUID}${result.termURL}`}
        onClick={this.onSearchEntryClick}
        >
        <div className="result__item">{result.termText}</div>
        <div className="result__item">{result.termAnatomy}</div>
        <div className="result__item">{result.termPlane}</div>
      </Link>
      </div>
    )
  }
  renderSearchGrid() {
    let alphabeticalList = _.orderBy(this.state.searchData, [item => item.termText.toLowerCase()], ['asc']);
    return _.map(alphabeticalList, result => this.renderSearchEntry(result));
  }
  render() {
    Modal.setAppElement(".stage")
    const modalVisibility = this.state.modalIsOpen
    return (
      <div className="search">
        <Modal
          isOpen={modalVisibility}
          onRequestClose={this.closeModal}
          className={{
            base: 'search',
            afterOpen: 'search__after-open',
            beforeClose: 'search__before-close'
            }}
          overlayClassName={{
            base: 'searchOverlay',
            afterOpen: 'searchOverlay__after-open',
            beforeClose: 'searchOverlay__before-close'
          }}
          >
            <div className="search__block">
              <div className="search__header">
              <div className="search__subHeader">
                <h6>Search for terms below</h6>
                <h6>{this.state.resultCount} Results</h6>
              </div>
              <fieldset className="form-group">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Search"
                  onChange={this.filterList}
                />
              </fieldset>
            </div>
              <div className="results">
                <div className="result__header">
                  <div className="result__header--title">Term Name</div>
                  <div className="result__header--title">Anatomy</div>
                  <div className="result__header--title">Plane/Pulse</div>
                </div>
                <Scrollbars
                  className='scroll-area'
                  onScroll={this.handleScroll}
                  >
                {this.renderSearchGrid()}
              </Scrollbars>
              </div>
            </div>
        </Modal>
      </div>
    )
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {searchTerms: state.searchTerms, searchModal: state.searchModal}
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({
    resetLines  : resetLines
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search))
