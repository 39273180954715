import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {di1GetViewer, setGlobalError} from '../../actions/index'

import Viewer from './viewer/di-viewer'
import QuizHider from './quiz-hider'
import Quiz from './quiz/di-quiz'
import Error from './main-error'
import Loader from './loader'

// Import Components
// import MainNav from '../main-nav';
// import MainSubNav from '../main-sub-nav'
// import StageContainer from './stage/stage-container'

/// Variables

class Main extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewMode: '',
      errorText: '',
      hasError: false
    }
  }
  componentDidCatch(error, info) {
    // Display fallback UI
    console.log("Error: ", error, info)
    //this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }
  componentDidMount() {
    // console.log('main.js componentDidMount');
    const viewerGUID = this.props.match.params.GUID;
    if (/^[a-zA-Z\d]{8}-[a-zA-Z\d]{4}-[a-zA-Z\d]{4}-[a-zA-Z\d]{4}-[a-zA-Z\d]{12}$/g.test(viewerGUID)) {
      this.props.di1GetViewer(viewerGUID, (response) => {
      });
    } else {
      console.log('GUID Error');
      this.setState({
        viewMode: 'error',
        errorText: 'There was a problem with the viewer ID'
      })
    }
  }
componentDidUpdate(prevProps, prevState) {
  // console.log('main.js componentDidUpdate');
  let viewMode = '';
  let errorText = "";
  // console.log('componentDidUpdate', this.props.viewer._attributes, prevProps.viewer._attributes);
if(this.props !== prevProps) {
  // console.log("props!", this.props, prevProps)
}
if(this.state !== prevState) {
  // console.log("state!")
}
  if (this.props.globalError !== prevProps.globalError) {
    // console.log('componentDidUpdate globalError');
    viewMode = 'error'
    switch (this.props.globalError.message) {
      case 500:
        errorText = "Server responded with error code 500. There is probably something wrong with the viewer ID. Please check to see if the ID is correct."
        break
      default:
        errorText = 'Something happened'
        break
    }
    this.setState({viewMode, errorText})
  } else if (this.props.viewer._attributes !== prevProps.viewer._attributes) {
    // console.log('componentDidUpdate viewer', this.props.viewer._attributes, prevProps.viewer._attributes);
    switch (this.props.viewer._attributes.quiz) {
      case '0':
        viewMode = 'viewer'
        break;
      case '1':
        viewMode = 'quiz'
        break;
      default:
        viewMode = 'error'
        break;
    }
    this.setState({viewMode})
  }
}

  renderStage(view) {
    let displayView = ''
    switch (view) {
      case 'viewer':
        displayView = <Viewer
                        anatomy={this.props.match.params.anatomy}
                        plane={this.props.match.params.plane}
                        slice={this.props.match.params.slice}
                        viewer={this.props.viewer}
                      />
        break;
      case 'quiz':
        displayView = <div>
                      <QuizHider />
                      <Quiz
                        anatomy={this.props.match.params.anatomy}
                        plane={this.props.match.params.plane}
                        slice={this.props.match.params.slice}
                      />
                    </div>
        break
      case 'error':
        displayView = <Error
                        errorText={this.state.errorText}
                      />
        break
      default:
        displayView = <Loader
                        errorText="Loading..."
                      />
    }
    return (
      <div className="stage">
        {displayView}
      </div>
    )
  }

  render() {
    if (this.state.hasError) {
     // You can render any custom fallback UI
     return (<h1>Something went wrong.</h1>);
   }
    if (this.props.timer.timerStatus === 'finished') {
    return(
      <div className="main">
      <div>QUIZ DONE WHETHER YOU LIKE IT OR NOT</div>
      </div>
    )
  }
    return (
      <div className="main">
        {this.renderStage(this.state.viewMode)}
      </div>
    )
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {
    viewer: state.viewer,
    globalError: state.globalError,
    timer: state.timer
  }
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {
  // whenever selectView is called the result should be passed to
  // all of our reducers
  return bindActionCreators({
    di1GetViewer, setGlobalError
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
