import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import _ from 'lodash'

// Import Components
import EditorSidebar from './di-editor-sidebar'
import ViewerImageArea from './di-editor-image-area'
import DrawLines from '../common/draw-line'
import ViewerOverlays from '../common/viewer-overlays'

import {setScoutDisabled, setScoutVisibility} from '../../../actions/index'

// Set variables

// Main Class
class Editor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceUpdateVar: 'moo',
      image: {
        _attributes: {
          path:'default_hd_image.jpg'
        }
      }
    }
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentDidUpdate(prevProps){
    // console.log("di-editor componentDidUpdate")
    if(
      this.props.match.params.anatomy !== prevProps.match.params.anatomy ||
      this.props.match.params.plane !== prevProps.match.params.plane ||
      this.props.match.params.slice !== prevProps.match.params.slice
    ) {
      let d = Date.now()
      this.setState({forceUpdateVar: d})
    }

    if(
      this.props.match.params.anatomy !== prevProps.match.params.anatomy ||
      this.props.match.params.plane !== prevProps.match.params.plane
    ) {
      if(this.props.viewer.anatomy[this.props.match.params.anatomy] &&
        this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse){
        let scoutImage = this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse[this.props.match.params.plane]._attributes.scout
        if (scoutImage === '' || scoutImage === undefined) {
          // console.log("No Scout")
          this.props.setScoutDisabled(true)
          this.props.setScoutVisibility(false)
        } else {
          // console.log("There is a Scout")
          this.props.setScoutDisabled(false)
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    let d = Date.now()
    this.setState({forceUpdateVar: d})
  }

  //// Slice Count
  getSliceCount() {
    let sliceCount = 0;
    if((this.props.viewer.anatomy[this.props.match.params.anatomy]) &&
      (this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse) &&
      (this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse[this.props.match.params.plane].image)) {
      sliceCount = this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse[this.props.match.params.plane].image.length;
    }
    return sliceCount
  }

  checkURL() {
    let doesUrlPass = true;

    const currentAnatomy = this.props.match.params.anatomy;
    const currentPlane = this.props.match.params.plane;
    const anatomyURL = parseInt(this.props.match.params.anatomy, 10);
    const anatomyNum = this.props.viewer.anatomy.length - 1;
    const planeURL =  parseInt(currentPlane, 10);
    const sliceURL =  parseInt(this.props.match.params.slice, 10);

    let planeNum = 0;
    let sliceNum = 1;

    if(
      this.props.viewer.anatomy[currentAnatomy] &&
      this.props.viewer.anatomy[currentAnatomy].plane_pulse &&
      this.props.viewer.anatomy[currentAnatomy].plane_pulse[currentPlane]
    ) {
      planeNum = this.props.viewer.anatomy[currentAnatomy].plane_pulse.length - 1;
    }
    if(
      this.props.viewer.anatomy[currentAnatomy] &&
      this.props.viewer.anatomy[currentAnatomy].plane_pulse &&
      this.props.viewer.anatomy[currentAnatomy].plane_pulse[currentPlane] &&
      !_.isEmpty(this.props.viewer.anatomy[currentAnatomy].plane_pulse[currentPlane].image)
    ) {
      sliceNum = this.props.viewer.anatomy[currentAnatomy].plane_pulse[currentPlane].image.length;
    }

    // Checking to see if the data in the url actualy exists
    if(anatomyURL > anatomyNum || currentAnatomy === undefined) {
      const redirectionURL = `/editor/${this.props.match.params.GUID}/${anatomyNum}/0/1`
      this.props.history.replace(redirectionURL);
      doesUrlPass = false;
    } else if(planeURL > planeNum || currentPlane === undefined) {
      // console.log('plane URL Error, redirecting to last plane')
      const redirectionURL = `/editor/${this.props.match.params.GUID}/${anatomyURL}/${planeNum}/1`
      this.props.history.replace(redirectionURL);
      doesUrlPass = false;
    } else if(sliceURL > sliceNum || this.props.match.params.slice === undefined) {
      // console.log('plane URL Error, redirecting to last plane')
      const redirectionURL = `/editor/${this.props.match.params.GUID}/${anatomyURL}/${currentPlane}/${sliceNum}`
      this.props.history.replace(redirectionURL);
      doesUrlPass = false;
    } else if (sliceURL === 0) {
      // console.log('plane URL Error, redirecting to last plane')
      const redirectionURL = `/editor/${this.props.match.params.GUID}/${anatomyURL}/${currentPlane}/1`;
      this.props.history.replace(redirectionURL);
      doesUrlPass = false;
    }
    return doesUrlPass
  }

  render() {
    const currentAnatomy = this.props.match.params.anatomy;
    const currentPlane = this.props.match.params.plane;
    const currentSlice = this.props.match.params.slice;

    let currentImageNumber = 0;
    let image = this.state.image;

    if (!this.checkURL()) {
      // console.log("check url failed")
      return (
        <div className="editor"></div>
      );
    } else {
      if(this.getSliceCount() !== 0) {
        currentImageNumber = parseInt(this.props.match.params.slice, 10);
      }
      if(this.props.viewer.anatomy[currentAnatomy] &&
        this.props.viewer.anatomy[currentAnatomy].plane_pulse[currentPlane] &&
        this.props.viewer.anatomy[currentAnatomy].plane_pulse[currentPlane].image){
        image = this.props.viewer.anatomy[currentAnatomy].plane_pulse[currentPlane].image[parseInt(currentSlice, 10) - 1]
      }
      return (
        <div className="editor">
          <EditorSidebar
            viewer={this.props.viewer}
            diType="Editor"
            slice={currentSlice}
            currentSliceNum={currentImageNumber}
            maxSliceNum={this.getSliceCount()}
          />
          <div className="editor__container">

            <ViewerOverlays
              currentSliceNum={currentImageNumber}
              maxSliceNum={this.getSliceCount()}
              slice={currentSlice}
              isEditor={true}
            />
              <ViewerImageArea
                image={image}
                slice={currentSlice}
              />
          </div>
          <DrawLines
            forceUpdateVar={this.state.forceUpdateVar}
            isEditor
          />
        </div>
      )
    }
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {
    viewer: state.viewer,
    searchTerms: state.searchTerms
  }
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({setScoutDisabled, setScoutVisibility}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Editor))
