import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import MdiChevronRight from 'mdi-react/ChevronRightIcon';
import MdiChevronLeft from 'mdi-react/ChevronLeftIcon';

// Import actions
import {resetLines} from '../../../actions/index'
// Import Components

// Set variables

// Main Class
class NavBackFwdBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urlPrefix: '/'
    }
  }

  componentDidMount() {
    if(this.props.isEditor) {
      this.setState({
        urlPrefix: '/editor/'
      })
    }
  }

  /// Slice number picker
  handleBackClick = () => {
    this.props.resetLines()
    let redirectionURL = `${this.state.urlPrefix}${this.props.match.params.GUID}/${this.props.match.params.anatomy}/${this.props.match.params.plane}/${parseInt(this.props.match.params.slice,10) - 1}` //${this.props.match.params.slice}`
    this.props.history.push(redirectionURL);
  }
  handleFwdClick = () => {
    this.props.resetLines()
    let redirectionURL = `${this.state.urlPrefix}${this.props.match.params.GUID}/${this.props.match.params.anatomy}/${this.props.match.params.plane}/${parseInt(this.props.match.params.slice,10) + 1}` //${this.props.match.params.slice}`
    this.props.history.push(redirectionURL);
  }

  render() {
    let backActive = '';
    let fwdActive = '';
    if(parseInt(this.props.match.params.slice,10) === 1){
      backActive = 'hidden';
    }
    let maxSlices = 0
    if(this.props.viewer.anatomy[parseInt(this.props.match.params.anatomy, 10)].plane_pulse &&
      this.props.viewer.anatomy[parseInt(this.props.match.params.anatomy, 10)].plane_pulse[parseInt(this.props.match.params.plane, 10)].image) {
      maxSlices = this.props.viewer.anatomy[parseInt(this.props.match.params.anatomy, 10)].plane_pulse[parseInt(this.props.match.params.plane, 10)].image.length
    }
    if(parseInt(this.props.match.params.slice,10) === maxSlices || maxSlices === 0){
      fwdActive = 'hidden';
    }
    return (
      <div className="nav__back-fwd">
        <div className={`nav__arrow back ${backActive}`} onClick={() => this.handleBackClick()}><MdiChevronLeft /></div>
        <div className={`nav__arrow fwd ${fwdActive}`}  onClick={() => this.handleFwdClick()}><MdiChevronRight /></div>
      </div>
    )
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {viewer: state.viewer}
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({
resetLines
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBackFwdBtn))
