import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


// Import Components
import Sidebar from '../viewer/di-viewer-sidebar'
import ViewerImageArea from './di-viewer-image-area'
import DrawLines from '../common/draw-line'
import ViewerOverlays from '../common/viewer-overlays'

import {setScoutDisabled, setScoutVisibility} from '../../../actions/index'

// Set variables

let badURL = false;
// Main Class
class Viewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceUpdateVar: 'moo'
    };
  }
  componentDidUpdate(prevProps, prevState){
    // console.log('di-viewer componentDidUpdate');
    if(this.props.scout.disabled !== prevProps.scout.disabled) {
      let scoutImage = this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse[this.props.match.params.plane]._attributes.scout
      if (scoutImage === '' || scoutImage === undefined) {
        this.props.setScoutDisabled(true)
        this.props.setScoutVisibility(false)
      } else {
        this.props.setScoutDisabled(false)
      }
    }
  }
  componentWillReceiveProps(nextProps){
    // console.log('componentWillReceiveProps');

  }
  componentWillMount() {
    if (!this.props.match.params.anatomy || this.props.match.params.anatomy === undefined) {
      console.log('no URL params, redirecting to something useable');
      const redirectionURL = `/${this.props.match.params.GUID}/0/0/1`
      this.props.history.replace(redirectionURL);
      badURL = true
    } else if (!this.props.match.params.plane || this.props.match.params.plane === undefined) {
      console.log('no plane URL params, redirecting to something useable');
      const redirectionURL = `/${this.props.match.params.GUID}/${this.props.match.params.anatomy}/0/1`
      this.props.history.replace(redirectionURL);
      badURL = true
    } else if (!this.props.match.params.slice || this.props.match.params.slice === undefined) {
      console.log('no image URL params, redirecting to something useable');
      const redirectionURL = `/${this.props.match.params.GUID}/${this.props.match.params.anatomy}/${this.props.match.params.plane}/1`
      this.props.history.replace(redirectionURL);
      badURL = true
    }
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    badURL = false
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }
  //// Slice Count
  getSliceCount() {
    let sliceCount = 0;
    if((this.props.viewer.anatomy[this.props.match.params.anatomy]) &&
      (this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse) &&
      (this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse[this.props.match.params.plane].image)) {
      sliceCount = this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse[this.props.match.params.plane].image.length;
    }
    return sliceCount
  }

  updateDimensions() {
    let d = Date.now()
    this.setState({forceUpdateVar: d})
  }

  render() {
    // console.log("Slice", this.props.match.params.slice)
    if (badURL) {
      return (
        <div>BAD URL</div>
      );
    } else {
      let image = ''
      if(this.props.viewer.anatomy[this.props.match.params.anatomy] &&
        this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse[this.props.match.params.plane] &&
        this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse[this.props.match.params.plane].image){
        image = this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse[this.props.match.params.plane].image[parseInt(this.props.match.params.slice, 10) - 1]
      }
      return (
        <div className="viewer">
          <Sidebar diType="Viewer" slice={this.props.match.params.slice}/>
          <div className="viewer__container">
            <ViewerOverlays
              slice={this.props.match.params.slice}
              maxSliceNum={this.getSliceCount()}
            />
            <ViewerImageArea
              image={image}
              slice={this.props.match.params.slice}
            />
          </div>
          <DrawLines forceUpdateVar={this.state.forceUpdateVar}/>
        </div>
      )
    }
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {searchTerms: state.searchTerms, scout: state.scout}
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({setScoutDisabled, setScoutVisibility}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Viewer))
