import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// Import Components
import AuthHOC from '../hoc-auth-wrapper';
import AuthLogin from './auth-login';
import EditorSelector from './select-editor';
import EditorWrapper from './editor-wrapper'
// Set variables

// Render component parts


// Main Class
class EditorAuthWrapper extends Component {

  render() {
    // console.log("Editor Auth Wrapper: ", this.props)
    if(this.props.isAuth) {
      if(this.props.match.params.GUID) {
        return(
          <EditorWrapper
            guid={this.props.match.params.GUID}
            anatomy={this.props.match.params.anatomy}
            plane={this.props.match.params.plane}
            slice={this.props.match.params.slice}
            authData={this.props.authData}
            program={this.props.program}
            logout={() => this.props.logout()}
          />
        )
      } else {
      return(
        <EditorSelector
          logout={() => this.props.logout()}
          viewerGUID={this.props.viewerGUID}
          quizGUID={this.props.quizGUID}
          program={this.props.program}
          authData={this.props.authData}
        />
      )}
    } else {
      if(this.props.match.path !== "/editor/") {
        let redirectionURL = `/editor/`
        this.props.history.push(redirectionURL);
      }
      return(

        <AuthLogin
          login={() => this.props.login()}
        />
      )
    }
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

// Anything returned from this fuction will end up as props
function mapStateToProps(state) {
  return {

  }
}

export default AuthHOC(connect(mapStateToProps)(withRouter(EditorAuthWrapper)))
