import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import _ from 'lodash';

// Import Components
import ListItem from './di-editor-sub-sidebar-list-item'
import MdiChevronLeft from 'mdi-react/ChevronLeftIcon';

// Set variables

// Set Redux Actions
import {resetLines} from '../../../actions/index'
import {
  di1Editor_SetAnatomy_Title,
  di1Editor_SetAnatomy_Add,
  di1Editor_SetAnatomy_Remove,
  di1Editor_SetPlane_Title,
  di1Editor_SetPlane_Add,
  di1Editor_SetPlane_Remove } from '../../../actions/editor-actions'

// Main Class
class SubSideBar extends Component {

  handleListItemRename(listData){
    // console.log('handleListItemRename', listData)
    switch(this.props.type){
      case 'anatomy':
      this.props.di1Editor_SetAnatomy_Title(listData)
      break;
      case 'plane':
      listData.anatomyIndex =
      this.props.di1Editor_SetPlane_Title(listData)
      break;
      default:
      break;
    }
  }

  handleListItemAdd(){
    // console.log('handleListItemAdd', this.props.data)
    switch(this.props.type){
      case 'anatomy':
      this.props.di1Editor_SetAnatomy_Add()
      break;
      case 'plane':
      this.props.di1Editor_SetPlane_Add(this.props.data)
      break;
      default:
      break;
    }
  }

  handleListItemRemove(listData) {
    // console.log('handleListItemRemove', listData)
    switch(this.props.type){
      case 'anatomy':
      this.props.di1Editor_SetAnatomy_Remove(listData)
      break;
      case 'plane':
      this.props.di1Editor_SetPlane_Remove(listData)
      break;
      default:
      break;
    }
  }

  handleClick(value) {
    // console.log('subsidebar - handleClick')
    this.props.resetLines()
    let redirectionURL
    switch(this.props.type){
      case 'anatomy':
      redirectionURL = `/editor/${this.props.match.params.GUID}/${value}/0/1`
      break;
      case 'plane':
      redirectionURL = `/editor/${this.props.match.params.GUID}/${this.props.match.params.anatomy}/${value}/1`
      break;
      default:
      break;
    }
    this.props.history.push(redirectionURL);
    this.props.toggleSubSidebar()
  }
  renderSidebarListItems(data) {
    // console.log('renderSidebarListItems', data)
    let showRemoveBtn = true;
    if(data && data.length <= 1){
      showRemoveBtn = false;
    }

    return _.map(data, (result, index) => {
      const listData = {
        index: result.value,
        title: result.textField
      }
      if(result.anatomyIndex !== undefined) {
        listData.anatomyIndex = result.anatomyIndex
      }
      let isActive = '';
      if(this.props.activeItem === index) {
        isActive = 'active';
      }
      return(
        <ListItem
          key={index}
          isActive={isActive}
          onClicky={() => this.handleClick(result.value)}
          listData={listData}
          showRemoveBtn = {showRemoveBtn}
          onRename = {(returnData) => this.handleListItemRename(returnData)}
          onRemove = {(returnData) => this.handleListItemRemove(returnData)}
        />
      )
    })
  }

  render() {
    // console.log('SubSideBar render')
    let visibilityClass = this.props.isOpen ? "open" : "closed";
    return (
      <div className={`sub-sidebar ${visibilityClass}`}>
        <div className="sub-sidebar__title__container">
        <div className="sub-sidebar__title">{this.props.title}</div>
        <div className="close-btn--mobile" onClick={this.props.toggleSubSidebar} >
          <MdiChevronLeft
            width="40"
            height="40"
          />
        </div>
      </div>
        <div className="sub-sidebar__list" >
          {this.renderSidebarListItems(this.props.data)}
          <div className='list-item' onClick={() => this.handleListItemAdd()}>Add a new {this.props.type}</div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({
    resetLines,
    di1Editor_SetAnatomy_Title,
    di1Editor_SetAnatomy_Add,
    di1Editor_SetAnatomy_Remove,
    di1Editor_SetPlane_Title,
    di1Editor_SetPlane_Add,
    di1Editor_SetPlane_Remove,
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubSideBar))
