import React, { Component } from 'react';
import _ from 'lodash';
// Import Components

// Set variables

// Main Class
export default class DiImage extends Component {
  linePointPxPct(location, parentSize) {
    let parentScale = parentSize / this.props.imageDimensions.scalePercent;
    let percent = (location / parentScale) * 100;
    return percent;
  }

  renderLineEndPoint(x, y, classNameSuffix) {
    let divStylePct = {
      top: this.linePointPxPct(y, this.props.imageDimensions.height) + '%',
      left: this.linePointPxPct(x, this.props.imageDimensions.width) + '%'
    };
    let lineEndClass = `line-end-${classNameSuffix}`;

    return (
      <div
        className={`lineEndPoint ${lineEndClass}`}
        style={divStylePct}
        key={classNameSuffix}
      />
    );
  }

  setupLinePoints(image) {
    return _.map(image.term, (termItem, index) => {
      let i = 0;
      return _.map(termItem.line, line => {
        let x = line._attributes.x;
        let y = line._attributes.y;
        let classNameSuffix = `${index}--${i}`;
        i++;
        return this.renderLineEndPoint(x, y, classNameSuffix);
      });
    });
  }

  onImgLoad = img => {
    this.props.onLoady(img);
  };
  onImgClick = () => {
    this.props.onClicky(this.props.position);
  };

  render() {
    const httpsSrc = this.props.imageURL.replace(/^http:\/\//i, 'https://');

    const css_editMode = this.props.newLineMode ? 'edit-mode' : '';
    return (
      <div className="di-image" style={this.props.cssStyle}>
        <div
          className={`image-border ${css_editMode}`}
          style={this.props.cssStyle}
          onClick={this.onImgClick}
        />
        <div className="image" style={this.props.cssStyle}>
          <img onLoad={this.onImgLoad} src={httpsSrc} alt="DI Slice" />
        </div>
        <div className="lineEndPoints">
          {this.setupLinePoints(this.props.image)}
        </div>
      </div>
    );
  }
}
