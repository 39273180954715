// Import Modules
import React from "react"
import ReactDOM from "react-dom"
import {Provider} from "react-redux"

// Import CSS
import "./scss/main.css";

//Import Store Creator
import configureStore from "./configureStore"

// Import Authenticated App Component
import AuthenticatedApp from "./AuthenticatedApp"

const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <AuthenticatedApp />
  </Provider>,
  document.getElementById("root")
)

if (module.hot) {
  module.hot.accept('./App', () => {
    ReactDOM.render(
      <Provider store={store}>
        <AuthenticatedApp />
      </Provider>,
      document.getElementById('root')
    )
  })
}
