import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Scrollbars} from 'react-custom-scrollbars';
import _ from 'lodash';
// Import actions
import {
  setScoutDisabled,
  setScoutVisibility
} from '../../../actions/index';

import {
  di1GETImageList,
  di1Editor_SetScout_Add,
  di1Editor_setScoutPickerHidden
} from '../../../actions/editor-actions'
// Import Components
import SliceGallery from './di-editor-image-gallery'
// Set variables

//eslint-disable-next-line
let planeSlices = []
// Main Class
class ScoutPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageList: [],
      selectedImages: []
    };
  }
  componentDidMount(){
    this.props.di1GETImageList(this.props.imagesBaseURL)
  }
  componentDidUpdate(prevProps, prevState){
    if((this.props.viewer.anatomy[this.props.match.params.anatomy]) &&
      (this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse) &&
      (this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse[this.props.match.params.plane].image)) {
      this.planeSlices = this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse[this.props.match.params.plane].image;
    }
    if(_.isEmpty(this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse[this.props.match.params.plane].image)) {
      this.planeSlices = []
    }
  }
  componentWillUnmount() {
    this.props.di1Editor_setScoutPickerHidden();
  }

  onSelectImage = (image) => {
    const scoutImageURL = image.path
    let data = {
      anatomyIndex: this.props.match.params.anatomy,
      planeIndex: this.props.match.params.plane,
      scoutImageURL,
    }
    console.log('data: ', data)
    this.props.di1Editor_SetScout_Add(data)
    this.props.di1Editor_setScoutPickerHidden();
    this.props.setScoutDisabled(false);
    this.props.setScoutVisibility(true);
  }

  render() {
    if (this.props.editor.imagePicker.scoutVisible) {
      return (<div className='scout-picker visible'>
        <div className="scout-picker__card card">
          <div className="scout-picker__title card-title">Pick a new Scout Image</div>
          <Scrollbars
            className='scroll-area'
            renderThumbVertical={props => <div {...props} className='scout-picker__scrollbar'/>}
            >
            <SliceGallery
              images={this.props.editor.imageList}
              onSelectImage={this.onSelectImage}
            />
          </Scrollbars>
          <div className="scout-picker__footer">
            <button type="button" className="btn btn-danger" onClick={this.props.di1Editor_setScoutPickerHidden}>Cancel</button>
          </div>
        </div>
      </div>)
    } else {
      return (<div className='scout-picker hidden'></div>)
    }
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {
    viewer: state.viewer,
    editor: state.editor,
    imagesBaseURL: state.viewer._attributes.imagesbaseurl,
  }
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setScoutVisibility,
    setScoutDisabled,
    di1GETImageList,
    di1Editor_setScoutPickerHidden,
    di1Editor_SetScout_Add
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScoutPicker))
