import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// Import Components
//Import actions
import {submitQuiz, setQuizView} from '../../../actions/index'
// Set variables

// Main Class
class QuizSubmit extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.handleSubmitQuiz = this.handleSubmitQuiz.bind(this);
  }
  componentWillReceiveProps(nextProps) {
  }

  handleSubmitQuiz(){
    if(this.props.quizProps.itemsRemaining === 0) {
    this.props.setQuizView('submit');
  } else {
    this.props.setQuizView('warning');
  }
    //this.props.submitQuiz(this.props.viewer, this.props.quiz, this.props.timer)
  }

  render() {
    if(this.props.quizProps){
      let pctDone = Math.floor((this.props.quizProps.itemsComplete / this.props.quizProps.totalItems)*100)
      return(
      <div className="submit_button" onClick={this.handleSubmitQuiz}>
        <div className="submit_button__text">Submit {this.props.quizProps.itemsComplete} of {this.props.quizProps.totalItems}</div>
        <div className="submit_button__progress" style={{width: `${pctDone}%`}}></div>
      </div>
    )
    } else {
      return(
      <div className="submit_button">
        <div>Loading..</div>
        <div className="submit_button__progress" style={{width: 0}}></div>
      </div>
      )
    }
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {
    quizProps: state.quiz.quizProps
  }
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({
    submitQuiz,
    setQuizView
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuizSubmit))
