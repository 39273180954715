import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import { RIEInput } from '@attently/riek'
////
import MdiPencil from 'mdi-react/PencilIcon';
import MdiDeleteCircle from 'mdi-react/DeleteCircleIcon';
import MdiRemove from 'mdi-react/MinusCircleIcon';
import MdiClose from 'mdi-react/CloseCircleIcon';
import MdiPlusCircle from 'mdi-react/PlusCircleIcon';
import MdiPower from 'mdi-react/PowerIcon';
import MdiCheckCircle from 'mdi-react/CheckCircleIcon';

// Import Components
import {createLines, resetLines} from '../../../actions/index'
import {
  di1Editor_SetTerm_Add,
  di1Editor_SetTerm_Remove,
  di1Editor_SetTerm_Title,
  di1Editor_SetLine_Remove,
  di1Editor_setTermIndex,
  newLine_enable,
  newLine_disable
} from '../../../actions/editor-actions'
// Set variables

// Main Class
class EditorTermList extends Component {
  constructor(props) {
    super(props);
    this.newLineTextEnable = 'Create a new line'
    this.newLineTextDisable = 'Cancel new line'
    this.state = {
      hoverLineIndex: 'none',
      clickLineIndex : 'none',
      editIndex: null,
      newLineTitleText: this.newLineTextEnable
    };

    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
    this.onMouseClick = this.onMouseClick.bind(this);
  }
  ////////////////////////////////////////////////////////////////////////////////
  // React Component Functions

  componentDidMount() {
    if(this.props.editor.newLineMode) {
      this.setState({
        newLineTitleText: this.newLineTextDisable
      })
    }
  }
  componentWillReceiveProps(nextProps) {
    if(this.props.match !== nextProps.match) {
      this.setState({clickLineIndex: 'none'})
    }

  }
  componentDidUpdate(prevProps, prevState) {
    // console.log("root new data detected: ",this.props.data, prevProps.data)
    if(this.props.match.params.slice !== prevProps.match.params.slice) {
      this.onCloseTermEditor()
    }
    if(this.state.editIndex !== null
      && _.size(this.props.data[this.state.editIndex].location) !== _.size(prevProps.data[this.state.editIndex].location)
    ) {
      // console.log("new data location detected: ", this.props.data[this.state.editIndex].location)
      this.setupClickLines(this.props.data[this.state.editIndex].location, this.props.editor.termIndex)
      //this.onCloseTermEditor()
    }
    if(this.props.editor.newLineMode !== prevProps.editor.newLineMode) {
      let newLineTitleText = this.newLineTextEnable;
      if(this.props.editor.newLineMode) {
        newLineTitleText = this.newLineTextDisable;
      }
      this.setState({
        newLineTitleText
      })
    }
  }

  // End React Component Functions
  ////////////////////////////////////////////////////////////////////////////////

  setupClickLines = (locations, index) => {
    let clickLines = {}
    let clickEnds = []
    let lineEndClass = ''
    let lineStartClass = `line-start-${index}`
    clickLines.lineStartClass = lineStartClass
    let i = 0;
    _.map(locations, (location) => {
      lineEndClass = `line-end-${index}--${i}`
      clickEnds.push(lineEndClass)
      i++
    })
    clickLines.lineEndClass = clickEnds
    this.props.createLines(this.props.lines, null, clickLines)
    this.setState({clickLineIndex: index});
  }

  handleLineRemove = (index) => {
    const data = {
      anatomyIndex: this.props.match.params.anatomy,
      planeIndex: this.props.match.params.plane,
      sliceIndex: this.props.match.params.slice,
      termIndex: this.props.editor.termIndex,
      lineIndex: index
    }
    this.props.di1Editor_SetLine_Remove(data);
  }

  handleScroll = () => {
    this.props.resetLines()
    this.setState({clickLineIndex: 'none'})
  }
  handleScrollStop = () => {
    this.setupClickLines(this.props.data[0].location, this.props.editor.termIndex)
  }

  onTermEdit = (index) => {
    // console.log('onTermEdit', index)
    this.props.di1Editor_setTermIndex(index)
    this.setState({
      editIndex: index
    })
    this.props.newLine_disable();
  }

  onCloseTermEditor = () => {
    // console.log('onCloseTermEditor')
    this.setState({
      editIndex: null
    })
    this.props.newLine_disable();
  }
  onTermChange = (evt) => {
    // console.log('onTermChange: ', evt.text)
    // this.setState({
    //   ...this.state,
    //   editIndex: null
    // })
    const data = {
      anatomyIndex: this.props.match.params.anatomy,
      planeIndex: this.props.match.params.plane,
      sliceIndex: this.props.match.params.slice,
      termIndex: this.props.editor.termIndex,
      title: evt.text
    }
    this.props.di1Editor_SetTerm_Title(data);
  }

  onTermEditFinish = (e) => {
    return true;
  }

  onAddTermClick = () =>  {
    const newTerm = {
      _attributes:{
        text: 'New Term'
      },
      line: []
    }
    const data = {
      anatomyIndex: this.props.match.params.anatomy,
      planeIndex: this.props.match.params.plane,
      sliceIndex: this.props.match.params.slice,
      termList: this.props.data,
      newTerm: newTerm
    }
    this.props.di1Editor_SetTerm_Add(data)
    this.props.newLine_disable();
  }

  onNewLineClick = () => {
    if(!this.props.editor.newLineMode) {
      this.props.newLine_enable();
      this.setState({
        ...this.state,
        newLineText: 'Click on the slice to add a line to that location'
      })
    } else {
      this.props.newLine_disable();
      this.setState({
        ...this.state,
        newLineText: 'Add a new line'
      })
    }
  }
  ////////////////////////////////////////////////////////////////////////////////
  // On Mouse Event Handlers

  onMouseEnterHandler = (locations, index) => {
    if(!this.props.editor.imagePicker.visible) {
      let hoverLines = {}
      let hoverEnds = []
      let lineEndClass = ''
      let lineStartClass = `line-start-${index}`
      hoverLines.lineStartClass = lineStartClass;
      let i = 0;
      _.map(locations, (location) => {
        lineEndClass = `line-end-${index}--${i}`
        hoverEnds.push(lineEndClass)
        i++
      })
      hoverLines.lineEndClass = hoverEnds
      this.props.createLines(this.props.lines, hoverLines, null )
    }

  }
  onMouseLeaveHandler = () => {
    if(!this.props.editor.imagePicker.visible) {
    let hoverLines = {}
    this.props.createLines(this.props.lines, hoverLines, null )
    }
  }
  onMouseClick = (locations, index) => {
    if(!this.props.editor.imagePicker.visible) {
      this.setupClickLines(locations, index)
    }
  }

  // On Mouse Event Handlers
  ////////////////////////////////////////////////////////////////////////////////

  renderLineTitles = (term, termIndex) => {
    return _.map(term, (location, index) => {
      return(
        <div key={index} className="term-editor--line">

            <div className="line-icon remove-line" onClick={() => this.handleLineRemove(index)}>
              <MdiRemove />
            </div>
            <div className="line-title">{`Line ${index + 1}`} </div>
        </div>
      )
    })
  }

  renderTerms = (terms, termClicked) => {
    // console.log('renderTerms', terms)
    return _.map(terms, (term, index) => {
      let lineStartClass = `line-start-${index}`

      let isClicked = ''
      if(termClicked === index){
        isClicked = 'clicked'
      }

      let css_newLine_Class = ''
      let newLineButton = <MdiPower />;
      if(this.props.editor.newLineMode){
        css_newLine_Class='edit-mode';
        newLineButton = <MdiClose />;
      }
      const data = {
        anatomyIndex: this.props.match.params.anatomy,
        planeIndex: this.props.match.params.plane,
        sliceIndex: this.props.match.params.slice,
        removeTerm: index
      }
      if(this.state.editIndex === index) {
        return (
          <div key={index}
            className="term term-editor"
            onMouseEnter={() => this.onMouseEnterHandler(term.location, index)}
            onMouseLeave={this.onMouseLeaveHandler}
            >
            <div className={`col term__line ${lineStartClass}`}></div>
            <div className="col term-editor--title-bar">
            <div className="term__title">
              <RIEInput
                value={term.textField}
                change={this.onTermChange.bind(this)}
                propName="text"
                editing={false}
                selectAll={true}
                afterFinish={this.onTermEditFinish.bind(this)}
              />
            </div>
            <div className="term-editor--icons title-bar">
              <div className="term-editor--icon icon-edit" onClick={this.onCloseTermEditor}><MdiCheckCircle /></div>
              <div className="term-editor--icon icon-blank"><MdiClose /></div>
            </div>
            <div className="term-editor--lines">
              {this.renderLineTitles(term.location, index)}
              <div className={`term-editor--line new-line ${css_newLine_Class}`} onClick={this.onNewLineClick}>
                <div className="line-icon">{newLineButton}</div>
                <div className="line-title">{this.state.newLineTitleText}</div>
              </div>
            </div>
          </div>
        </div>
        )
      } else {
        return (
          <div
            className={`term ${isClicked}`}
            key={index}
            onMouseEnter={() => this.onMouseEnterHandler(term.location, index)}
            onMouseLeave={this.onMouseLeaveHandler}
            onClick={() => {this.onMouseClick(term.location, index)
            }}
            >
            <div className={`col term__line ${lineStartClass}`}></div>
            <div className="col">
              <div className="term__title">{term.number !== 0 && `${term.number}. `}{term.textField}</div>
              <div className="term-editor--icons">
                <div className="term-editor--icon icon-edit" onClick={() => {this.onTermEdit(index)}}><MdiPencil /></div>
                <div className="term-editor--icon icon-close" onClick={() => {this.props.di1Editor_SetTerm_Remove(data)}}><MdiDeleteCircle /></div>
              </div>
            </div>
          </div>
        )
      }
    })
  }

  render() {
    const css_isDisabled = this.props.hasSlice ? '' : 'disabled';
      return (
        <div className="termList">
          <Scrollbars
            className='scroll-area'
            onScroll={this.handleScroll}
            onScrollStop={this.handleScrollStop}
            >
          {this.renderTerms(this.props.data, this.state.clickLineIndex)}
          <div className="term new-term">
            <div className={`term__title ${css_isDisabled}`} onClick={this.onAddTermClick}>
              <MdiPlusCircle/> Add a term
            </div>
          </div>
        </Scrollbars>
        </div>
      )
  }
}

function mapStateToProps(state) {
  return {
    lines: state.lines,
    editor: state.editor
  }
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({
    createLines,
    resetLines,
    di1Editor_SetTerm_Add,
    di1Editor_SetTerm_Remove,
    di1Editor_SetTerm_Title,
    di1Editor_SetLine_Remove,
    di1Editor_setTermIndex,
    newLine_enable,
    newLine_disable
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditorTermList))
