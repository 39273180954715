import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Scrollbars} from 'react-custom-scrollbars';
import _ from 'lodash';

// Import Components
import MdiTimer from 'mdi-react/TimerIcon';
import MdiCheckbox from 'mdi-react/CheckboxMultipleMarkedOutlineIcon'
// Set variables

// Set Redux Actions
import {resetLines} from '../../../actions/index'

// Main Class
class SubSideBar extends Component {
  handleClick(value) {
    // console.log('subsidebar - handleClick')
    this.props.resetLines()
    let redirectionURL
    switch (this.props.type) {
      case 'anatomy':
        redirectionURL = `/${this.props.match.params.GUID}/${value}/0/1`
        break;
      case 'plane':
        redirectionURL = `/${this.props.match.params.GUID}/${this.props.match.params.anatomy}/${value}/1`
        break;
      default:
        break;
    }
    this.props.toggleSubSidebar()
    this.props.history.push(redirectionURL);
  }

  renderSidebarListItems(data) {
    return _.map(data, (result, index) => {
      let isActive = '';
      if (this.props.activeItem === index) {
        isActive = 'active';
      }
      return (
        <div key={index} className={`list-item ${isActive}`} onClick={() => this.handleClick(result.value)}>
          {result.textField}
        </div>
      )
    })
  }

  render() {
    let visibilityClass = this.props.isOpen ? "open" : "closed";
    if (this.props.quizProps) {
      if (this.props.type === 'instructions') {
        return (
          <div className={`sub-sidebar instructions ${visibilityClass}`}>
            <div className="header-info-area">
              <div className="header-info-area__icon">
                <MdiTimer width="26" height="26"/>
              </div>
              <div>
                <div className="header-info-area__title">Time to Complete Quiz</div>
                <div className="header-info-area__text">{this.props.data.duration} minutes</div>
              </div>
            </div>
            <div className="header-info-area">
              <div className="header-info-area__icon">
                <MdiCheckbox width="26" height="26"/>
              </div>
              <div>
                <div className="header-info-area__title">Questions Remaining</div>
                <div className="header-info-area__text">{this.props.quizProps.itemsRemaining} of {this.props.quizProps.totalItems}</div>
              </div>
            </div>
            <div className="instructions__area">
              {/*<div className="instructions__title">Instructions</div>*/}
              <Scrollbars className='scroll-area'>
                <div className="instructions__text">{this.props.data.instructions}</div>
              </Scrollbars>
            </div>
          </div>
        )
      } else {
        return (
          <div className={`sub-sidebar ${visibilityClass}`}>
            <div className="sub-sidebar__title">{this.props.title}</div>
            <div className="sub-sidebar__list">
              {this.renderSidebarListItems(this.props.data)}
            </div>
          </div>
        )
      }
    } else {
      return (
        <div className={`sub-sidebar ${visibilityClass}`}>
          <div className="sub-sidebar__title">{this.props.title}</div>
          <div className="sub-sidebar__list">
          </div>
        </div>
      )
    }
  }
}

function mapStateToProps(state) {
  return {}
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({
    resetLines
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubSideBar))
