import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import _ from 'lodash';
import ReactCursorPosition from 'react-cursor-position';

// Import Components
import DiImage from './di-editor-image'
import { di1Editor_SetLine_Add, newLine_disable } from '../../../actions/editor-actions'
// Set variables

// Main Class
class ViewerImageArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageDimensions: {},
      stageDimensions: {},
      currentAnatomy: parseInt(this.props.match.params.anatomy, 10),
      currentPlane: parseInt(this.props.match.params.plane, 10),
      currentSlice: (parseInt(this.props.match.params.slice, 10) - 1),
      image: '',
      defaultImage: {
        _attributes: {
          path:'default_hd_image.jpg'
        }
      }
    };
    this.onImgLoad = this.onImgLoad.bind(this);
  }

  componentDidMount() {
    this.updateStageDimensions();
    window.addEventListener("resize", this.updateStageDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateStageDimensions.bind(this));
  }
  componentDidUpdate(prevProps, prevState) {
    if(this.state.stageDimensions !== prevState.stageDimensions) {
      // console.log('updateStageDimensions | ', this.state.stageDimensions)
    }
  }
  updateStageDimensions() {
    // console.log("updateStageDimensions")
    let windowWidth = window.innerWidth - 258;
    let windowHeight = window.innerHeight;
    this.setState({
      stageDimensions: {
        height: windowHeight,
        width: windowWidth
      }
    })
    // console.log('updateStageDimensions | w:', windowWidth, ' h:', windowHeight)
  }

  onImgLoad(img) {
    // console.log("onImgLoad")
    // console.log('image width', img.target.naturalWidth)
    let scalePercent;
    if(img.target.naturalWidth >= img.target.naturalHeight) {
      scalePercent = img.target.naturalWidth/512;
    } else {
      scalePercent = img.target.naturalHeight/512;
    }
    // console.log('scale new width', Math.floor(img.target.naturalWidth/scalePercent))
    // console.log('scale new height', Math.floor(img.target.naturalHeight/scalePercent))
    this.setState({
      imageDimensions: {
        height: img.target.naturalHeight,
        width: img.target.naturalWidth,
        scalePercent
      }
    });
  }

  selectImage(image, stageHeight, stageWidth, imageHeight, imageWidth) {
    // console.log("selectImage", image, stageHeight, stageWidth, imageHeight, imageWidth)
    let heightPercent = stageHeight / imageHeight;
    let widthPercent = stageWidth / imageWidth
    let diImageStylePercent = {}
    if((imageWidth*heightPercent) >= stageWidth) {
      diImageStylePercent = {
        width: stageWidth  + 'px',
        height: imageHeight * widthPercent + 'px'
      }
    } else {
      diImageStylePercent = {
        width: imageWidth * heightPercent + 'px',
        height: stageHeight + 'px'
      }
    }
    const imageURL = `${this.props.viewer._attributes.imagesbaseurl}${image._attributes.path}`
    return (
      <ReactCursorPosition>
          <DiImage
            image={image}
            imageDimensions={this.state.imageDimensions}
            newLine = {this.props.editor.newLineMode}
            imageURL = {imageURL}
            onClicky = {this.handleOnClick}
            onLoady = {this.onImgLoad.bind(this)}
            cssStyle = {diImageStylePercent}
            newLineMode = {this.props.editor.newLineMode}
          />
      </ReactCursorPosition>
    )
  }

  handleOnClick = (pos) => {
    // console.log("handleOnClick | iw:", this.state.imageDimensions.width, " sh:", this.state.stageDimensions.height)
    let percent = this.state.stageDimensions.height / this.state.imageDimensions.height;

    if(this.state.stageDimensions.width < this.state.stageDimensions.height) {
      // console.log("Height is more than Width", this.state.stageDimensions.height, this.state.stageDimensions.width)
      percent = this.state.stageDimensions.width / this.state.imageDimensions.width;
    }

    const lineEndPointX = Math.floor(pos.x / percent / this.state.imageDimensions.scalePercent);
    const lineEndPointY = Math.floor(pos.y / percent / this.state.imageDimensions.scalePercent);

    const data = {
      anatomyIndex: this.props.match.params.anatomy,
      planeIndex: this.props.match.params.plane,
      sliceIndex: this.props.match.params.slice,
      termIndex: this.props.editor.termIndex,
      x: lineEndPointX,
      y: lineEndPointY
    }
    this.props.di1Editor_SetLine_Add(data)
    this.props.newLine_disable()
    console.log(lineEndPointX, lineEndPointY)
  }

  render() {
    let image = this.props.image
    if(_.isEmpty(this.props.image)){
      image = this.state.defaultImage
    }
    return (
      <div className='image-area'>

        {this.selectImage(
          image,
          this.state.stageDimensions.height,
          this.state.stageDimensions.width,
          this.state.imageDimensions.height,
          this.state.imageDimensions.width
        )}
      </div>
    )
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {
    viewer: state.viewer,
    editor: state.editor,
  }
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({
    di1Editor_SetLine_Add,
    newLine_disable
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewerImageArea))
