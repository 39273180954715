import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import _ from 'lodash'
import Toggle from 'react-toggle'

// Set Redux Actions
import {resetLines, setScoutVisibility, setScoutDisabled} from '../../../actions/index'

// Import Components
import TermList from '../common/term-list'
import MobileTermList from './di-viewer-mobile-term-sidebar'
import SubSideBar from './di-viewer-sub-sidebar'
import Search from '../common/search'
import NavItem from '../nav/nav-item'
import NavImageNum from '../nav/nav-image-number'
import Sprite from '../../svg_sprite';
import MdiChevronLeft from 'mdi-react/ChevronLeftIcon';
import MdiArrowCollapse from 'mdi-react/UnfoldLessHorizontalIcon';
import MdiArrowExpand from 'mdi-react/UnfoldMoreHorizontalIcon';

import { UserInfo } from '../../../AuthenticatedApp';
// Set variables

// Main Class
class Sidebar extends Component {
  constructor() {
    super();
    this.state = {
      subSidebar: {
        isOpen: false,
        type: '',
        title: 'select an anatomy',
        data: [ {value: 0, textField: ""} ]
      },
      mobileTermBar: {
        isOpen: false,
      },
      scout: {
        visible: false,
        disabled: false,
      },
      viewer: {
        anatomy: 0,
        plane: 0,
        slice: 0,
      },
      navItemOpen: '',
      searchIsOpen: false,
      headerHover: false,
      navCollapsed: false,
    };
    this.toggleSearch = this.toggleSearch.bind(this);
    this.toggleScout = this.toggleScout.bind(this);
    this.toggleMobileSidebar = this.toggleMobileSidebar.bind(this);
    this.handleTitleClick = this.handleTitleClick.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    let scoutDisabled = nextProps.scout.disabled;
    let scoutVisible = nextProps.scout.visible;
    this.setState({
      scout: {
        disabled: scoutDisabled,
        visible: scoutVisible
      },
      viewer: {
        anatomy: parseInt(nextProps.match.params.anatomy, 10),
        plane: parseInt(nextProps.match.params.plane, 10),
        slice: (parseInt(nextProps.match.params.slice, 10) - 1),
      }
    })
  }
  //// Term Data
  getTermData() {
    let termData = [];
    _.map(this.props.viewer.anatomy[this.state.viewer.anatomy].plane_pulse[this.state.viewer.plane].image[this.state.viewer.slice].term, (value, index) => {
      var myValue = {
        value: index,
        textField: value._attributes.text,
        location: value.line
      }
      termData.push(myValue)
    })

    return termData
  }
  //// Slice Count
  getSliceCount() {
    let sliceCount = this.props.viewer.anatomy[this.state.viewer.anatomy].plane_pulse[this.state.viewer.plane].image.length;
    return sliceCount
  }
  hasTerms() {
    return (this.props.viewer.anatomy[this.state.viewer.anatomy].plane_pulse[this.state.viewer.plane].image[this.state.viewer.slice].term ? true : false);
  }

  toggleSearch() {
    this.setState({
      searchIsOpen: !this.state.searchIsOpen
    })
  }

  toggleSubSidebar() {
    if (this.state.subSidebar.isOpen) {
      this.setState({
        subSidebar: {
          isOpen: !this.state.subSidebar.isOpen
        },
        navItemOpen: ''
      })
    }
  }

  toggleScout() {
    this.props.setScoutVisibility(!this.props.scout.visible)
  }
  toggleMobileSidebar() {
    this.props.resetLines()
      this.setState({
        mobileTermBar: {
          isOpen: !this.state.mobileTermBar.isOpen
        }
      })
  }

  handleAnatomyClick() {
    let anatomyData = [];
    let navItemOpen = 'anatomy'
    let isOpen = true;
    if (this.props.viewer) {
      _.map(this.props.viewer.anatomy, (value, index) => {
        var myValue = {
          value: index,
          textField: value._attributes.menuname
        }
        anatomyData.push(myValue)
      })
    }
    if (this.state.subSidebar.type === 'anatomy' && this.state.subSidebar.isOpen) {
      isOpen = false;
      navItemOpen = ''
    }
    this.setState({
      navItemOpen,
      subSidebar: {
        isOpen: isOpen,
        type: 'anatomy',
        title: 'Select an Anatomy',
        activeItem: this.state.viewer.anatomy,
        data: anatomyData
      }
    })
  }

  handlePlaneClick() {
    let planeData = [];
    let navItemOpen = 'plane'
    let isOpen = true

    if (this.props.viewer) {
      let currentPlane = this.state.viewer.anatomy
      _.map(this.props.viewer.anatomy[currentPlane].plane_pulse, (value, index) => {
        // console.log("getAnatomyData Value: ", value)
        var myValue = {
          value: index,
          textField: value._attributes.menuname
        }
        planeData.push(myValue)
      })
      if (this.state.subSidebar.type === 'plane' && this.state.subSidebar.isOpen) {
        isOpen = false;
        navItemOpen = ''
      }
      this.setState({
        navItemOpen,
        subSidebar: {
          isOpen,
          type: 'plane',
          title: 'Select a Plane/Pulse',
          activeItem: this.state.viewer.plane,
          data: planeData
        }
      })
    }
  }

  handleMobileTermClick() {

  }
  handleTitleHover(hoverState) {
    // console.log('Title Hover', hoverState)
    this.setState({
      ...this.state,
      headerHover: hoverState,
    })
  }

  handleTitleClick() {
    // console.log('Title Click', this.state.navCollapsed)
    let navCollapsed = !this.state.navCollapsed;
    this.setState({
      ...this.state,
      navCollapsed,
    })
  }

  renderHeader(){
    let collapseText = this.state.navCollapsed ? 'Expand' : 'Collapse';
    let MdiIcon = this.state.navCollapsed ? <MdiArrowExpand /> : <MdiArrowCollapse />;
    if(this.state.navCollapsed) {
      return(
        <div className="title-container">
          <div className="collapse-icon">
            {MdiIcon} {collapseText}
          </div>
        </div>
      )
    }
    if(this.state.headerHover) {
      return(
        <div className="title-container">
          <div className="collapse-icon">
            {MdiIcon} {collapseText}
          </div>
        </div>
      )
    } else {
      return(
        <div className="title-container">
          <h1 className="title__text">{this.props.diType}</h1>
          <div className="title__sprite">
            <Sprite sprite="logo-nait-shield"/>
          </div>
        </div>
      )
    }
  }

  render() {
    let anatomyOpen = false;
    let planeOpen = false;
    let scoutDisabledCSS = '';
    let scoutOpenCSS = ''
    let searchOpenCSS = '';
    let termBarOpenCSS = '';
    let hasTermsCSS = '';
    let navCollapsedCSS = '';

    if (this.state.navItemOpen === 'anatomy') {
      anatomyOpen = true;
    } else if (this.state.navItemOpen === 'plane') {
      planeOpen = true;
    }

    if(this.props.scout.disabled){
      scoutDisabledCSS = 'disabled';
    }
    if(this.props.scout.visible) {
      scoutOpenCSS = 'toggled';
    }
    if(this.state.searchIsOpen) {
      searchOpenCSS = 'toggled';
    }
    if(this.state.mobileTermBar.isOpen) {
      termBarOpenCSS = 'toggled';
    }
    if(this.hasTerms()) {
      hasTermsCSS = 'has-terms';
    }
    if(this.state.navCollapsed){
      navCollapsedCSS = 'collapsed';
    }

    return (
      <div className="sidebar">
        <div className={`mainSidebar ${navCollapsedCSS}`}>
          <div className={`sidebar__header ${this.props.diType}`} >
            <UserInfo />
            <div className="title"
              onMouseOut={() => this.handleTitleHover(false)}
              onMouseOver={() => this.handleTitleHover(true)}
              onClick={this.handleTitleClick}
            >
              {this.renderHeader()}
            </div>
            <div className="title__viewer">
              {this.props.viewer._attributes.name}
            </div>
          </div>
          <div className="sidebar__nav">
            <NavItem
              title="Anatomy"
              currentView={this.props.viewer.anatomy[this.state.viewer.anatomy]._attributes.menuname}
              onClicky={this.handleAnatomyClick.bind(this)}
              isActive={anatomyOpen}
            />
            <NavItem
              title="Plane/Pulse"
              currentView={this.props.viewer.anatomy[this.state.viewer.anatomy].plane_pulse[this.state.viewer.plane]._attributes.menuname}
              onClicky={this.handlePlaneClick.bind(this)}
              isActive={planeOpen}
            />
            <NavImageNum
              currentNum={this.state.viewer.slice+1}
              maxNum={this.getSliceCount()}
            />
          </div>
          <div className="termList-title">
            Terms
          </div>
          <TermList data={this.getTermData()}/>
          <div className="sidebar__footer">
            <div className="sidebar__footer--item button search" onClick={() => this.toggleSearch()}>
              <div className="nav__arrow">
                <MdiChevronLeft/>
              </div>
              <div className="sidebar__footer--item--title">
                Search
              </div>

            </div>
            <div className="sidebar__footer--item">
              <div className="sidebar__footer--item--title">
                Scout
              </div>
              <div className="nav__slider">
                <Toggle
                  onChange={this.toggleScout}
                  className='scout__toggle'
                  checked={this.props.scout.visible}
                  disabled={this.props.scout.disabled}
                />
              </div>
            </div>
            <div className="sidebar__footer--mobile">
              <div className={`footer__button--mobile ${termBarOpenCSS} ${hasTermsCSS}`} onClick={this.toggleMobileSidebar}>Terms</div>
              <div className={`footer__button--mobile ${searchOpenCSS}`} onClick={this.toggleSearch}>Search</div>
              <div className={`footer__button--mobile ${scoutDisabledCSS} ${scoutOpenCSS}`} onClick={this.toggleScout}>Scout</div>
            </div>
          </div>
          <MobileTermList
            currentAnatomy={this.props.viewer.anatomy[this.state.viewer.anatomy]._attributes.menuname}
            currentPlane={this.props.viewer.anatomy[this.state.viewer.anatomy].plane_pulse[this.state.viewer.plane]._attributes.menuname}
            currentSlice={this.state.viewer.slice+1}
            maxSlice={this.getSliceCount()}
            isOpen={this.state.mobileTermBar.isOpen}
            data={this.getTermData()}
            toggleSidebar={this.toggleMobileSidebar}
          />
          <Search
            searchTerms={this.props.searchTerms}
            toggleSearch={this.toggleSearch}
            modalIsOpen={this.state.searchIsOpen}
          />
        </div>

        <SubSideBar
          isOpen={this.state.subSidebar.isOpen}
          toggleSubSidebar={this.toggleSubSidebar.bind(this)}
          type={this.state.subSidebar.type}
          title={this.state.subSidebar.title}
          activeItem={this.state.subSidebar.activeItem}
          data={this.state.subSidebar.data}
        />
      </div>
    )
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {viewer: state.viewer, scout: state.scout}
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({
    resetLines,
    setScoutVisibility,
    setScoutDisabled
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar))
