import React from 'react';

import Sprite from './../svg_sprite';

// usage: <SpriteSheet sprite="[SPRITE ID NAME]" />
export default class OrientationOverlay extends React.Component {
  render() {
    return (
      <div className="orientate-device">
        <div className="img-orientate">
          <Sprite sprite="rotate-device" />
        </div>
    <h3>Please Rotate your device</h3>
  </div>
    );
  }
}
