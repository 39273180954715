import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import { startQuizTimer, setupStudentInfo, setQuizView } from '../../../actions/index'

import LoginForm from './quiz-login-form'
// Set variables

// Main Class
class QuizLogin extends Component {
  constructor() {
    super();
    this.state = {
      studentFirstName: '',
      studentLastName: '',
      studentID: '',
      visibility: 'visible'
    };
    this.handleFNInputChange = this.handleFNInputChange.bind(this)
    this.handleLNInputChange = this.handleLNInputChange.bind(this)
    this.handleIDInputChange = this.handleIDInputChange.bind(this)
    this.handleSubmitClick = this.handleSubmitClick.bind(this)
    this.hideLogin = this.hideLogin.bind(this)
  }
  componentWillReceiveProps(nextProps) {
    if(nextProps.quizProps.currentView === 'login') {
      this.setState((state) => ({
        visibility: 'visible'
      }))
    } else {
      this.setState((state) => ({
        visibility: 'hidden'
      }))
    }
  }
  componentDidMount(){
    if(this.props.quizProps.currentView === 'login') {
      this.setState((state) => ({
        visibility: 'visible'
      }))
    } else {
      this.setState((state) => ({
        visibility: 'hidden'
      }))
    }
  }
  hideLogin() {
    this.setState((state) => ({
      visibility: 'hidden'
    }))
  }
  handleFNInputChange(evt) {
    let studentFirstName = evt.target.value
    this.setState((state) => ({
      studentFirstName
    }))
  }
  handleLNInputChange(evt) {
    let studentLastName = evt.target.value
    this.setState((state) => ({
      studentLastName
    }))
  }
  handleIDInputChange(evt) {
    let studentID = evt.target.value
    this.setState((state) => ({
      studentID
    }))
  }
  handleSubmitClick() {
    this.props.startQuizTimer('quizTimer', this.props.timer.timerPeriod)
    let studentInfo = {
      studentFirstName : this.state.studentFirstName,
      studentLastName : this.state.studentLastName,
      studentID : this.state.studentID
    }
    this.props.setupStudentInfo(this.props.quizProps, studentInfo)
    this.props.setQuizView('quiz');
    this.setState((state) => ({
      visibility: 'hidden'
    }))
  }
  render() {
    let timeLimit = this.props.viewer._attributes.duration;

    return (
      <div className={`quiz-login ${this.state.visibility}`}>
        <div className="quiz-login__block">
          <div className="quiz-login__area">
            <h1>Enter Your name and student ID</h1>
            <LoginForm timeLimit={timeLimit} hideLogin={this.hideLogin}/>
            {/* <div className="input-group">
              <input
                placeholder="First Name"
                value={this.state.studentFirstName}
                onChange={this.handleFNInputChange}
                ></input>
            </div>

            <div className="input-group">
              <div className="error-text">
                Please enter a first name
              </div>
              <input
                className="error"
                placeholder="Last Name"
                value={this.state.studentLastName}
                onChange={this.handleLNInputChange}
                ></input>
            </div>

            <div className="input-group">
              <input
                placeholder="Student ID"
                value={this.state.studentID}
                onChange={this.handleIDInputChange}
                ></input>
            </div>
            <div className="time-warning">You will have {timeLimit} minutes to complete this quiz</div>
            <div className="button-group">
          <div className="submit_button" onClick={this.handleSubmitClick}>Start Quiz</div>
        </div>
        */}
            </div>
        </div>
      </div>
    )
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {
    viewer: state.viewer,
    quizProps: state.quiz.quizProps,
    timer: state.timer
  }
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({
    setQuizView,
    startQuizTimer,
    setupStudentInfo
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuizLogin))
