import React from 'react';

import spriteSheet from '../images/svg-spritesheet.svg'

// usage: <SpriteSheet sprite="[SPRITE ID NAME]" />
export default class SpriteSheet extends React.Component {
  render() {
    return (
      <svg role="img" title="diViewer Logo">
        <use xlinkHref={`${spriteSheet}#${this.props.sprite}`}/>
      </svg>
    );
  }
}
