import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import _ from 'lodash'

// Import Components

// Set variables

// Main Class
class Scout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      imageDimensions: {},
      currentAnatomy: parseInt(this.props.match.params.anatomy, 10),
      currentPlane: parseInt(this.props.match.params.plane, 10),
      currentSlice: (this.props.currentSlice),
      image: this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse[this.props.match.params.plane]._attributes.scout,
      flashMaxScoutPos: 128, // This is the max pos value the flash editor could set
      scoutSize: 250
    };
    this.onImgLoad = this.onImgLoad.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      isActive: nextProps.scout.visible,
      currentSlice: nextProps.currentSlice,
      image: this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse[this.props.match.params.plane]._attributes.scout
    })
  }
  renderScoutImage(image) {
    let imageURL = `${this.props.viewer._attributes.imagesbaseurl}${image}`
    
    return (<img onLoad={this.onImgLoad} src={imageURL} alt="Scout"/>)
  }
  onImgLoad(img) {
    this.setState({
      imageDimensions: {
        height: img.target.naturalHeight,
        width: img.target.naturalWidth
      }
    });
  }
  linePosToPct(position, parentSize) {
    const percent = (position / parentSize) * 100
    return (percent)
  }

  renderMinMaxLine(linePlane) {
    // orient: 0 = vertical, 1 = horizontal
    let orient = linePlane._attributes.orient;
    let orientationClass = 'line--horizontal'
    let orientation = 'top';
    let currentLineStyle, minLineStyle, maxLineStyle = {top: '0px'};

    let lines = []
    let minLinePos = 999;
    let maxLinePos = 1;

    // Set orientation
    if(orient === '0') {
      orientationClass = 'line--vertical'
      orientation = "left"
    }
    // Set line positions, convert to percentage
    _.map(linePlane.image, image => {
      if(image._attributes.pos){
        lines.push(parseInt(image._attributes.pos, 10))
      }
    })
    minLinePos = _.min(lines)
    maxLinePos = _.max(lines)
    minLineStyle = {[orientation] : this.linePosToPct(minLinePos, this.state.flashMaxScoutPos) + '%'} //minLinePos+ 'px'}
    maxLineStyle = {[orientation] : this.linePosToPct(maxLinePos, this.state.flashMaxScoutPos) + '%'} //maxLinePos+ 'px'}

    if(linePlane.image[this.props.currentSlice]._attributes.pos){
      let currentPos = linePlane.image[this.props.currentSlice]._attributes.pos;
      currentLineStyle = {[orientation] : this.linePosToPct(currentPos, this.state.flashMaxScoutPos) + '%'} //currentPoslinePlane+ 'px'}
    } else {
      currentLineStyle = {display: 'none'}
    }
    return(
      <div className="scout__lines" style={{maxWidth: this.state.scoutSize+'px'}}>
      <div className={`scout__line line--min ${orientationClass}`} style={minLineStyle}></div>
      <div className={`scout__line line--pos ${orientationClass}`} style={currentLineStyle}></div>
      <div className={`scout__line line--max ${orientationClass}`} style={maxLineStyle}></div>
    </div>
    )
  }

  render() {
    let linePlane = this.props.viewer.anatomy[parseInt(this.props.match.params.anatomy, 10)]
    .plane_pulse[parseInt(this.props.match.params.plane, 10)];
    let image = this.props.viewer.anatomy[this.props.match.params.anatomy].plane_pulse[this.props.match.params.plane]._attributes.scout
    let imageURL = `${this.props.viewer._attributes.imagesbaseurl}${image}`
    return (
      <div className='scout'>
        <div className="scout__image" style={{maxWidth: this.state.scoutSize+'px'}}>
          {this.renderMinMaxLine(linePlane)}
          <img onLoad={this.onImgLoad} src={imageURL} alt="Scout"/>
        </div>
      </div>
    )
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {
    viewer: state.viewer,
    scout: state.scout
  }
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({}, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Scout))
