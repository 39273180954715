import React from 'react';

import MdiEmoticonSad from 'mdi-react/EmoticonSadIcon';

// usage: <SpriteSheet sprite="[SPRITE ID NAME]" />
export default class QuizHider extends React.Component {
  render() {
    return (
      <div className="quiz-hider">
        <div className="quiz-hider__block">
          <MdiEmoticonSad width="80" height="80"/>
          <h3>You must use a desktop PC to take a quiz</h3>
        </div>
      </div>
    );
  }
}
