import {
  TIMER_START,
  TIMER_STOP,
  TIMER_TICK
} from 'redux-timer-middleware';

import {
  QUIZ_TIMER_INIT,
  QUIZ_TIMER_START,
  QUIZ_TIMER_STOP,
  QUIZ_TIMER_TICK,
  QUIZ_TIMER_TICK_END
} from '../../actions/';

function timerReducer(state = {}, action) {
  let newTime
  let newState
  switch(action.type) {
    case TIMER_START:
    return action.payload;
    case TIMER_STOP:
    return action.payload;
    case TIMER_TICK:
    return action.payload;
    ///////////////////////////
    case QUIZ_TIMER_INIT:

    newState = {
      ...state,
      timerStatus: 'initialized',
      timerPeriod: action.payload.timerPeriod,
      timeLeft: action.payload.timerPeriod,
      timeElapsed: 0,
    }
      return newState

    case QUIZ_TIMER_START:
    newState = {
      ...state,
      timerStatus: 'started',
      timerPeriod: action.payload.timerPeriod,
      timeLeft: action.payload.timerPeriod,
      timeElapsed: 0,
    }
    return newState;

    case QUIZ_TIMER_STOP:
    return state

    case QUIZ_TIMER_TICK:
    if(newTime === 0) {
      newState = {
        ...state,
        timerStatus: 'finished'
      }
      return newState;
    }
    newState = {
      ...state,
      timeLeft: state.timeLeft - 1,
      timeElapsed: state.timeElapsed + 1
    }
    return newState;
    case QUIZ_TIMER_TICK_END:
      newState = {
        ...state,
      }
      return newState
    default:
      return state;
  }
}


export {
  timerReducer
}
