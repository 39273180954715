import _ from "lodash";

import {
  DI1_GET_VIEWER,
  DI1_SET_SEARCH,
  DI1_SET_STUDENT_INFO,
  DI1_SET_QUIZ_VIEW,
  DI1_SET_QUIZ_QUESTIONS,
  DI1_SET_QUIZ_STUDENT_ANSWER,
  DI1_SET_QUIZ_QUESTIONS_REMAINING,
  DI1_FINISH_QUIZ,
  DI1_GET_ANATOMIES,
  DI1_GET_PLANE,
  DI1_GET_SLICE,
  DI1_GET_TERMS,
  DI1_SET_QUIZ,
  DI1_SET_QUIZ_MODE
} from "../../actions/";

import {
  DI1_GET_EDITOR,
  DI1_EDITOR_GET_EXISTING_VIEWERS,
  DI1_EDITOR_GET_EXISTING_QUIZZES
} from "../../actions/editor-api-actions";

import {
  DI1_EDITOR_SET_META_TITLE,
  DI1_EDITOR_INSERT_SLICE_AT,
  DI1_SET_SLICE_PICKER_VISIBILITY,
  DI1_SET_SLICE_SWITCHER_VISIBILITY,
  DI1_SET_SCOUT_PICKER_VISIBILITY,
  DI1_EDITOR_SET_ANATOMY_TITLE,
  DI1_EDITOR_SET_ANATOMY_ADD,
  DI1_EDITOR_SET_ANATOMY_REMOVE,
  DI1_EDITOR_SET_PLANE_TITLE,
  DI1_EDITOR_SET_PLANE_ADD,
  DI1_EDITOR_SET_PLANE_REMOVE,
  DI1_EDITOR_SET_SLICE_ADD,
  DI1_EDITOR_SET_SLICE_SWITCH,
  DI1_EDITOR_SET_SLICE_REMOVE,
  DI1_EDITOR_SET_TERM_ADD,
  DI1_EDITOR_SET_TERM_REMOVE,
  DI1_EDITOR_SET_TERM_TITLE,
  DI1_EDITOR_SET_TERM_LINE_ADD,
  DI1_EDITOR_SET_TERM_LINE_REMOVE,
  DI1_EDITOR_SET_TERM_INDEX,
  DI1_EDITOR_NEWLINE_ENABLE,
  DI1_EDITOR_NEWLINE_DISABLE,
  DI1_EDITOR_SET_SCOUT_EDIT_MODE,
  DI1_EDITOR_SET_SCOUT_ADD,
  DI1_EDITOR_SET_SCOUT_REMOVE,
  DI1_EDITOR_SET_SCOUT_ADD_LINE,
  DI1_EDITOR_SET_SCOUT_REMOVE_LINE,
  DI1_EDITOR_SET_SCOUT_LINE_POSITION,
  DI1_EDITOR_SET_SCOUT_LINE_ORIENTATION,
  DI1_EDITOR_SET_QUIZ_EDITOR_VISIBILITY,
  DI1_EDITOR_SET_IMAGELIST,
  di1POSTImageSet
} from "../../actions/editor-actions";

export function Di1_ViewerReducer(state = {}, action) {
  let di_baseURL = "/";
  if (module.hot) {
    di_baseURL = "/";
  }
  let newState = {
    di_baseURL,
    ...state
  };
  switch (action.type) {
    // Viewer Actions
    case DI1_GET_VIEWER:
      return action.payload;
    // Editor Actions

    // META
    case DI1_EDITOR_SET_META_TITLE:
      newState._attributes.name = action.payload;
      break;

    // Quiz
    case DI1_SET_QUIZ_MODE:
      newState._attributes.quiz = setQuiz(action.payload);
      break;

    case DI1_SET_QUIZ:
      // console.log("DI1_SET_QUIZ: ", action.payload);
      newState._attributes.duration = action.payload.duration;
      const text = [action.payload.instructions];
      newState.instructions = [{ _text: text }];
      break;
    // Anatomy
    case DI1_EDITOR_SET_ANATOMY_TITLE:
      newState.anatomy[action.payload.index]._attributes.menuname =
        action.payload.title;
      break;
    case DI1_EDITOR_SET_ANATOMY_ADD:
      newState.anatomy = addAnatomy(newState.anatomy);
      break;
    case DI1_EDITOR_SET_ANATOMY_REMOVE:
      newState.anatomy = removeAnatomy(newState.anatomy, action.payload.index);
      break;
    // Plane
    case DI1_EDITOR_SET_PLANE_TITLE:
      newState.anatomy[action.payload.anatomyIndex].plane_pulse[
        action.payload.index
      ]._attributes.menuname = action.payload.title;
      break;
    case DI1_EDITOR_SET_PLANE_ADD:
      newState.anatomy[action.payload.anatomyIndex].plane_pulse = addPlane(
        newState.anatomy[action.payload.anatomyIndex].plane_pulse
      );
      break;
    case DI1_EDITOR_SET_PLANE_REMOVE:
      newState.anatomy[action.payload.anatomyIndex].plane_pulse = removePlane(
        newState.anatomy[action.payload.anatomyIndex].plane_pulse,
        action.payload.index
      );
      break;

    // Slice
    case DI1_EDITOR_SET_SLICE_ADD:
      newState.anatomy[action.payload.anatomyIndex].plane_pulse[
        action.payload.planeIndex
      ].image = addSlice(
        newState.anatomy[action.payload.anatomyIndex].plane_pulse[
          action.payload.planeIndex
        ],
        action.payload
      );
      break;
    case DI1_EDITOR_SET_SLICE_SWITCH:
      newState.anatomy[action.payload.anatomyIndex].plane_pulse[
        action.payload.planeIndex
      ].image[action.payload.sliceIndex - 1] = switchSlice(
        newState.anatomy[action.payload.anatomyIndex].plane_pulse[
          action.payload.planeIndex
        ].image[action.payload.sliceIndex - 1],
        action.payload
      );
      break;
    case DI1_EDITOR_SET_SLICE_REMOVE:
      newState.anatomy[action.payload.anatomyIndex].plane_pulse[
        action.payload.planeIndex
      ].image.splice(action.payload.removeSlice - 1, 1);
      break;

    // Term
    case DI1_EDITOR_SET_TERM_ADD:
      newState.anatomy[action.payload.anatomyIndex].plane_pulse[
        action.payload.planeIndex
      ].image[action.payload.sliceIndex - 1].term = addTerm(
        newState.anatomy[action.payload.anatomyIndex].plane_pulse[
          action.payload.planeIndex
        ].image[action.payload.sliceIndex - 1],
        action.payload
      );
      break;
    case DI1_EDITOR_SET_TERM_REMOVE:
      newState.anatomy[action.payload.anatomyIndex].plane_pulse[
        action.payload.planeIndex
      ].image[action.payload.sliceIndex - 1].term.splice(
        action.payload.removeTerm,
        1
      );
      break;
    case DI1_EDITOR_SET_TERM_TITLE:
      newState.anatomy[action.payload.anatomyIndex].plane_pulse[
        action.payload.planeIndex
      ].image[action.payload.sliceIndex - 1].term[
        action.payload.termIndex
      ]._attributes.text = action.payload.title;
      break;

    // Line
    case DI1_EDITOR_SET_TERM_LINE_ADD:
      newState.anatomy[action.payload.anatomyIndex].plane_pulse[
        action.payload.planeIndex
      ].image[action.payload.sliceIndex - 1].term[
        action.payload.termIndex
      ].line = addLine(
        newState.anatomy[action.payload.anatomyIndex].plane_pulse[
          action.payload.planeIndex
        ].image[action.payload.sliceIndex - 1].term[action.payload.termIndex]
          .line,
        action.payload
      );
      break;
    case DI1_EDITOR_SET_TERM_LINE_REMOVE:
      const oldLine = [
        ...newState.anatomy[action.payload.anatomyIndex].plane_pulse[
          action.payload.planeIndex
        ].image[action.payload.sliceIndex - 1].term[action.payload.termIndex]
          .line
      ];
      const newLine = [
        ...oldLine.slice(0, action.payload.lineIndex),
        ...oldLine.slice(action.payload.lineIndex + 1)
      ];
      newState.anatomy[action.payload.anatomyIndex].plane_pulse[
        action.payload.planeIndex
      ].image[action.payload.sliceIndex - 1].term[
        action.payload.termIndex
      ].line = newLine;
      break;

    // Scout
    case DI1_EDITOR_SET_SCOUT_ADD:
      newState.anatomy[action.payload.anatomyIndex].plane_pulse[
        action.payload.planeIndex
      ]._attributes.scout = action.payload.scoutImageURL;
      break;
    case DI1_EDITOR_SET_SCOUT_REMOVE:
      newState.anatomy[action.payload.anatomyIndex].plane_pulse[
        action.payload.planeIndex
      ] = removeScout(
        newState.anatomy[action.payload.anatomyIndex].plane_pulse[
          action.payload.planeIndex
        ]
      );
      break;
    case DI1_EDITOR_SET_SCOUT_ADD_LINE:
      newState.anatomy[action.payload.anatomyIndex].plane_pulse[
        action.payload.planeIndex
      ].image[action.payload.sliceIndex - 1]._attributes.pos =
        action.payload.pos;
      break;
    case DI1_EDITOR_SET_SCOUT_LINE_POSITION:
      newState.anatomy[action.payload.anatomyIndex].plane_pulse[
        action.payload.planeIndex
      ].image[action.payload.sliceIndex - 1]._attributes.pos =
        action.payload.pos;
      break;
    case DI1_EDITOR_SET_SCOUT_LINE_ORIENTATION:
      newState.anatomy[action.payload.anatomyIndex].plane_pulse[
        action.payload.planeIndex
      ]._attributes.orient = action.payload.orientation;
      break;
    case DI1_EDITOR_SET_SCOUT_REMOVE_LINE:
      newState.anatomy[action.payload.anatomyIndex].plane_pulse[
        action.payload.planeIndex
      ].image[action.payload.sliceIndex - 1]._attributes = _.omit(
        newState.anatomy[action.payload.anatomyIndex].plane_pulse[
          action.payload.planeIndex
        ].image[action.payload.sliceIndex - 1]._attributes,
        "pos"
      );
      break;
    // Default
    default:
      return newState;
  }
  di1POSTImageSet(newState);
  return newState;
}

export function Di1_SearchTermsReducer(state = {}, action) {
  switch (action.type) {
    case DI1_SET_SEARCH:
      return action.payload;
    default:
      return state;
  }
}
export function Di1_QuizReducer(state = {}, action) {
  let newState = state;
  switch (action.type) {
    case DI1_SET_STUDENT_INFO:
      newState.quizProps = action.payload;
      return newState;
    case DI1_SET_QUIZ_VIEW:
      newState.quizProps.currentView = action.payload;
      return newState;
    case DI1_SET_QUIZ_QUESTIONS:
      newState.quizTerms = action.payload;
      return newState;
    case DI1_SET_QUIZ_STUDENT_ANSWER:
      newState.quizTerms = action.payload;
      return newState;
    case DI1_SET_QUIZ_QUESTIONS_REMAINING:
      newState.quizProps = action.payload;
      return newState;
    case DI1_FINISH_QUIZ:
      newState.quizProps.quizFinished = true;
      return newState;
    default:
      return state;
  }
}

export function Di1_AnatomiesReducer(state = {}, action) {
  switch (action.type) {
    case DI1_GET_ANATOMIES:
      return action.payload;
    default:
      return state;
  }
}
export function Di1_PlanesReducer(state = {}, action) {
  switch (action.type) {
    case DI1_GET_PLANE:
      return action.payload;
    default:
      return state;
  }
}
export function Di1_SlicesReducer(state = {}, action) {
  switch (action.type) {
    case DI1_GET_SLICE:
      return action.payload;
    default:
      return state;
  }
}
export function Di1_TermsReducer(state = {}, action) {
  switch (action.type) {
    case DI1_GET_TERMS:
      return action.payload;
    default:
      return state;
  }
}
export function Di1_EditorReducer(state = {}, action) {
  let newState = {};
  newState = {
    ...state
  };
  switch (action.type) {
    case DI1_GET_EDITOR:
      newState.viewer = action.payload;
      return newState;
    case DI1_EDITOR_GET_EXISTING_VIEWERS:
      newState.existingViewers = action.payload;
      return newState;
    case DI1_EDITOR_GET_EXISTING_QUIZZES:
      newState.existingQuizzes = action.payload;
      return newState;
    case DI1_EDITOR_INSERT_SLICE_AT:
      newState.imagePicker.insertSliceAt = action.payload;
      return newState;
    case DI1_SET_SLICE_PICKER_VISIBILITY:
      newState.imagePicker.sliceVisible = action.payload;
      return newState;
    case DI1_SET_SLICE_SWITCHER_VISIBILITY:
      newState.imagePicker.switchVisible = action.payload;
      return newState;
    case DI1_SET_SCOUT_PICKER_VISIBILITY:
      newState.imagePicker.scoutVisible = action.payload;
      return newState;
    case DI1_EDITOR_SET_QUIZ_EDITOR_VISIBILITY:
      newState.quizEditorVisiblity = action.payload;
      return newState;
    case DI1_EDITOR_SET_IMAGELIST:
      newState.imageList = action.payload;
      return newState;
    //Editor
    case DI1_EDITOR_SET_TERM_INDEX:
      newState.termIndex = action.index;
      return newState;
    // Line
    case DI1_EDITOR_NEWLINE_ENABLE:
      newState.newLineMode = true;
      return newState;
    case DI1_EDITOR_NEWLINE_DISABLE:
      newState.newLineMode = false;
      return newState;
    case DI1_EDITOR_SET_SCOUT_EDIT_MODE:
      newState.scoutEditMode = action.payload;
      return newState;
    default:
      return state;
  }
}
/// Common functions
//// Quiz
function setQuiz(payload) {
  // console.log("setQuiz:", payload);
  let quizState = "0";
  if (payload) {
    quizState = "1";
  }
  return quizState;
}
//// Anatomy
function addAnatomy(anatomy) {
  let newAnatomy = [...anatomy];
  let newAnatomyItem = {
    _attributes: {
      menuname: "New Anatomy"
    },
    plane_pulse: [
      {
        _attributes: {
          menuname: "New Plane/Pulse"
        }
      }
    ]
  };
  newAnatomy.push(newAnatomyItem);
  return newAnatomy;
}

function removeAnatomy(anatomy, index) {
  let newAnatomy = [...anatomy];
  // eslint-disable-next-line
  const removed = newAnatomy.splice(index, 1);
  return newAnatomy;
}

//// Plane/Pulse
function addPlane(plane) {
  let newPlane = [...plane];
  let newPlaneItem = {
    _attributes: {
      menuname: "New Plane/Pulse"
    }
  };
  newPlane.push(newPlaneItem);
  return newPlane;
}

function removePlane(plane, index) {
  let newPlane = [...plane];
  // eslint-disable-next-line
  const removed = newPlane.splice(index, 1);
  return newPlane;
}
//// Slice
function addSlice(currentPlane, payload) {
  let newImage = [];
  if (currentPlane.image) {
    newImage = currentPlane.image;
  }
  newImage.splice(payload.sliceIndex, 0, payload.newSlice);
  return newImage;
}

function switchSlice(currentImage, payload) {
  let newImage = [];
  if (currentImage) {
    newImage = currentImage;
  }
  newImage._attributes.path = payload.newSlice._attributes.path;
  return newImage;
}
//// Term
function addTerm(currentSlice, payload) {
  let newTerms = [];
  if (currentSlice.term) {
    newTerms = currentSlice.term;
  }
  newTerms.push(payload.newTerm);
  return newTerms;
}
//// Scout
function removeScout(currentPlane) {
  let newPlane = { _attributes: {}, image: [] };
  newPlane._attributes = _.omit(currentPlane._attributes, "scout");
  _.map(currentPlane.image, (image, index) => {
    let newImage = { ...image };
    newImage._attributes = _.omit(image._attributes, "pos");
    newPlane.image.push(newImage);
  });
  return newPlane;
}

//// Line
function addLine(currentLine, payload) {
  let newLineArray = [...currentLine];
  let newLineItem = {
    _attributes: {
      x: payload.x,
      y: payload.y
    }
  };
  newLineArray.push(newLineItem);
  return newLineArray;
}
