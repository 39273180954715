import axios from "axios";
import _ from "lodash";
import convert from "xml-js";
import qs from "qs";

import { GLOBAL_ERROR, DI1_GET_VIEWER } from "./index";

////// 1.0 Editor Action definitions
export const DI1_GET_EDITOR = "di1_get_editor";
export const DI1_EDITOR_GET_EXISTING_VIEWERS =
  "di1_editor_get_existing_viewers";
export const DI1_EDITOR_GET_EXISTING_QUIZZES =
  "di1_editor_get_existing_quizzes";

////// Setup and Variables
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.withCredentials = true;

// const DI1_URL =  //"https://digilearn.nait.ca/nait/docstorage/docstorage.asmx/GetStoreXML";
//   `${docStorageUrl}GetStoreXML123`;

const DI1_URL = `${process.env.REACT_APP_DOC_STORAGE_URL}/api/DocStorage/GetStoreXML`;

const DI1_VIEWER_PASS = "diViewerGet";

////////////////////////////////////////////////////////////////////////////////
// Actions for the editor
////////////////////////////////////////////////////////////////////////////////

export function di1EditorGetViewerList(GUID, callback) {
  let currentViewers = [];
  const viewerURL = `${DI1_URL}?viewer`;
  const params = qs.stringify({
    sDocIdentity: GUID,
    sGetPassword: DI1_VIEWER_PASS,
  });
  const request = axios({
    method: "post",
    withCredentials: false,
    url: viewerURL,
    data: params,
  });

  return (dispatch) => {
    request
      .then((response) => {
        const options = {
          compact: true,
          ignoreDeclaration: true,
          alwaysArray: true,
        };
        let xml2jsonParser = new Promise((resolve, reject) => {
          resolve(convert.xml2js(response.data, options));
        });
        xml2jsonParser.then((parserResponse) => {
          const editorArray = parserResponse.viewers[0].viewer;
          _.map(editorArray, (editor) => {
            currentViewers.push(editor._attributes);
          });
          dispatch({
            type: DI1_EDITOR_GET_EXISTING_VIEWERS,
            payload: currentViewers,
          });
        });
        if (callback) callback(response, currentViewers);
      })
      .catch(function (error) {
        parseError(error, dispatch);
      });
  };
}
////////////////////////////////////////////////////////////////////////////////
export function di1EditorGetQuizList(GUID, callback) {
  let currentQuizzes = [];
  const quizListURL = `${DI1_URL}?quiz`;
  const params = qs.stringify({
    sDocIdentity: GUID,
    sGetPassword: DI1_VIEWER_PASS,
  });
  const request = axios({
    method: "post",
    withCredentials: false,
    url: quizListURL,
    data: params,
  });

  return (dispatch) => {
    request
      .then((response) => {
        const options = {
          compact: true,
          ignoreDeclaration: true,
          alwaysArray: true,
        };
        let xml2jsonParser = new Promise((resolve, reject) => {
          resolve(convert.xml2js(response.data, options));
        });
        xml2jsonParser.then((parserResponse) => {
          const editorArray = parserResponse.viewers[0].viewer;
          _.map(editorArray, (editor) => {
            currentQuizzes.push(editor._attributes);
          });
          dispatch({
            type: DI1_EDITOR_GET_EXISTING_QUIZZES,
            payload: currentQuizzes,
          });
        });
        if (callback) callback(response, currentQuizzes);
      })
      .catch(function (error) {
        parseError(error, dispatch);
      });
  };
}
////////////////////////////////////////////////////////////////////////////////
export function di1EditorGetViewer(viewerGUID, callback) {
  const viewerURL = `${DI1_URL}?sDocIdentity=${viewerGUID}&sGetPassword=${DI1_VIEWER_PASS}`;
  const request = axios({
    method: "get",
    withCredentials: false,
    url: viewerURL,
    alwaysArray: true,
  });
  return (dispatch) => {
    request
      .then((response) => {
        const options = {
          compact: true,
          ignoreDeclaration: true,
          alwaysArray: true,
        };
        let xml2jsonParser = new Promise((resolve, reject) => {
          resolve(convert.xml2js(response.data, options));
        });
        xml2jsonParser.then((parserResponse) => {
          const diData = parserResponse.div_data[0];
          dispatch({ type: DI1_GET_VIEWER, payload: diData });
        });
        if (callback) callback(response);
      })
      .catch(function (error) {
        parseError(error, dispatch);
      });
  };
}
////////////////////////////////////////////////////////////////////////////////
export function di1EditorSetNewViewer(viewerData, callback) {
  console.log(viewerData);

  const url = `${process.env.REACT_APP_DOC_STORAGE_URL}/api/DocStorage/CreateNewStoreString`;

  const params = qs.stringify({
    sUserName: viewerData.sUserName,
    sSetPassword: viewerData.sSetPassword,
    sDocIdentity: viewerData.sDocIdentity,
    sGetPassword: DI1_VIEWER_PASS,
    sCreationPassword: viewerData.sCreationPassword,
    binString: viewerData.binString,
  });

  const request = axios({
    method: "post",
    withCredentials: false,
    url: url,
    data: params,
  });
  request
    .then((res) => {
      // console.log("di1EditorSetNewViewer response: ", res);
      if (callback) {
        callback(res);
      }
    })
    .catch(function (error) {
      parseError(error);
    });
}
////////////////////////////////////////////////////////////////////////////////
export function di1EditorSetViewerList(viewerData, callback) {
  const url = `${process.env.REACT_APP_DOC_STORAGE_URL}/api/DocStorage/SetStoreString`;

  let submissionURL = `${url}`;

  const params = qs.stringify({
    sSetPassword: viewerData.sSetPassword,
    sDocIdentity: viewerData.sDocIdentity,
    sBinString: viewerData.sBinString,
  });

  const request = axios({
    method: "post",
    withCredentials: false,
    url: submissionURL,
    data: params,
  });
  request
    .then((res) => {
      if (callback) {
        callback(res);
      }
    })
    .catch(function (error) {
      parseError(error);
    });
}
////////////////////////////////////////////////////////////////////////////////
export function di1EditorSetNewQuiz(viewerData, callback) {
  const url =
    //"https://digilearn.nait.ca/nait/docStorage/docstorage.asmx/CreateNewStoreString?viewer";
    `${process.env.REACT_APP_DOC_STORAGE_URL}/api/DocStorage/CreateNewStoreString?viewer`;

  let submissionURL = `${url}`;

  const params = qs.stringify({
    sUserName: viewerData.sUserName,
    sSetPassword: viewerData.sSetPassword,
    sDocIdentity: viewerData.sDocIdentity,
    sGetPassword: DI1_VIEWER_PASS,
    sCreationPassword: viewerData.sCreationPassword,
    binString: viewerData.binString,
  });

  const request = axios({
    method: "post",
    withCredentials: false,
    url: submissionURL,
    data: params,
  });
  request
    .then((res) => {
      if (callback) {
        callback(res);
      }
    })
    .catch(function (error) {
      parseError(error);
    });
}
////////////////////////////////////////////////////////////////////////////////
export function di1EditorSetQuizList(viewerData, callback) {
  const url = `${process.env.REACT_APP_DOC_STORAGE_URL}/api/DocStorage/SetStoreString`;

  let submissionURL = `${url}`;

  const params = qs.stringify({
    sSetPassword: viewerData.sSetPassword,
    sDocIdentity: viewerData.sDocIdentity,
    sBinString: viewerData.sBinString,
  });
  const request = axios({
    method: "post",
    withCredentials: false,
    url: submissionURL,
    data: params,
  });
  request
    .then((res) => {
      if (callback) {
        callback(res);
      }
    })
    .catch(function (error) {
      parseError(error);
    });
}

export function di1EditorSetViewer(viewerData, callback) {
  const url = `${process.env.REACT_APP_DOC_STORAGE_URL}/api/DocStorage/SetStoreString`;

  let submissionURL = `${url}`;

  const params = qs.stringify({
    sSetPassword: viewerData.sSetPassword,
    sDocIdentity: viewerData.sDocIdentity,
    sBinString: viewerData.sBinString,
  });

  const request = axios({
    method: "post",
    withCredentials: false,
    url: submissionURL,
    data: params,
  });
  request
    .then((res) => {
      if (callback) {
        callback(res);
      }
    })
    .catch(function (error) {
      parseError(error);
    });
}

export function copyStore(copyId, viewId, type, callback) {
  const params = qs.stringify({ copyId, viewId, type });
  const request = axios({
    method: "post",
    withCredentials: false,
    url: `${process.env.REACT_APP_DOC_STORAGE_URL}/api/DocStorage/CopyStore`,
    data: params,
  });
  request
    .then((res) => {
      if (callback) {
        callback(res);
      }
    })
    .catch(function (error) {
      parseError(error);
    });
}

////////////////////////////////////////////////////////////////////////////////
// End Actions for the editor
////////////////////////////////////////////////////////////////////////////////

// Common functions

// Parse any errors that might arrise from the axios request
function parseError(error, dispatch) {
  console.warn("Error status: ", error);
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(
      "Actions - ParseError: Server responded with error code: ",
      error.response.status
    );
    const isVisible = error.response.status ? true : false;
    const payload = {
      visible: isVisible,
      message: error.response.status,
    };
    if (dispatch) dispatch({ type: GLOBAL_ERROR, payload: payload });
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(
      "Actions - ParseError: The request was made but no response was received: ",
      error.request
    );
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log(
      "Actions - ParseError: An error other than a request or response happened: ",
      error.message
    );
  }
}
