import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import _ from 'lodash';

// Import Components
import MdiChevronLeft from 'mdi-react/ChevronLeftIcon';

// Set variables

// Set Redux Actions
import {resetLines} from '../../../actions/index'

// Main Class
class SubSideBar extends Component {
  handleClick(value) {
    // console.log('subsidebar - handleClick')
    this.props.resetLines()
    let redirectionURL
    switch(this.props.type){
      case 'anatomy':
      redirectionURL = `/${this.props.match.params.GUID}/${value}/0/1`
      break;
      case 'plane':
      redirectionURL = `/${this.props.match.params.GUID}/${this.props.match.params.anatomy}/${value}/1`
      break;
      default:
      break;
    }
    this.props.history.push(redirectionURL);
    this.props.toggleSubSidebar()
  }

  renderSidebarListItems(data) {

    return _.map(data, (result, index) => {
      let isActive = '';
      if(this.props.activeItem === index) {
        isActive = 'active';
      }
      return(
        <div
          key={index}
          className={`list-item ${isActive}`}
          onClick={() => this.handleClick(result.value)}
        >
          {result.textField}
        </div>
      )
    })
  }

  render() {
    let visibilityClass = this.props.isOpen ? "open" : "closed";
    return (
      <div className={`sub-sidebar ${visibilityClass}`}>
        <div className="sub-sidebar__title__container">
        <div className="sub-sidebar__title">{this.props.title}</div>
        <div className="close-btn--mobile" onClick={this.props.toggleSubSidebar} >
          <MdiChevronLeft
            width="40"
            height="40"
          />
        </div>
      </div>
        <div className="sub-sidebar__list" >
          {this.renderSidebarListItems(this.props.data)}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {viewer: state.viewer}
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({
    resetLines
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubSideBar))
