import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// Import Components
import MdiChevronRight from 'mdi-react/ChevronRightIcon';
import MdiChevronLeft from 'mdi-react/ChevronLeftIcon';

// Set variables

// Import actions
import {resetLines} from '../../../actions/index'


// Main Class
class NavImageNum extends Component {
  constructor() {
    super();
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }
  componentDidMount() {
  document.addEventListener('keydown', (event) => this.handleKeyDown(event.key));
}

  componentWillUnmount() {
    document.removeEventListener('keydown', (event) => this.handleKeyDown(event.key));
  }

  handleKeyDown = (key) => {
    // console.log("currentNum: ", this.props.currentNum)
    switch (key) {
        case "ArrowLeft":
            // console.log("Left pressed")
            if(parseInt(this.props.currentNum, 10) >= 2){
              this.handleBackClick();
            }
            break;
        case "ArrowRight":
            // console.log("Right pressed")
            if(parseInt(this.props.currentNum, 10) <= (this.props.maxNum - 1) ){
              this.handleFwdClick();
            }
            break;
        case "ArrowUp":
            // console.log("Up pressed")
            if(parseInt(this.props.currentNum, 10) >= 2){
              this.handleBackClick();
            }
            break;
        case "ArrowDown":
            // console.log("Down pressed")
            if(parseInt(this.props.currentNum, 10) <= (this.props.maxNum - 1) ){
              this.handleFwdClick();
            }
            break;
            default:
            break;
    }
  }

  handleBackClick = () => {
    this.props.resetLines()
    let redirectionURL = `/${this.props.match.params.GUID}/${this.props.match.params.anatomy}/${this.props.match.params.plane}/${parseInt(this.props.match.params.slice,10) - 1}` //${this.props.match.params.slice}`
    this.props.history.push(redirectionURL);
  }
  handleFwdClick = () => {
    this.props.resetLines()
    let redirectionURL = `/${this.props.match.params.GUID}/${this.props.match.params.anatomy}/${this.props.match.params.plane}/${parseInt(this.props.match.params.slice,10) + 1}` //${this.props.match.params.slice}`
    this.props.history.push(redirectionURL);
  }

  render() {
    let css_backActive = '';
    let css_fwdActive = '';
    if(parseInt(this.props.currentNum, 10) <= 1){
      css_backActive = 'inactive';
    }

    if(parseInt(this.props.currentNum, 10) === this.props.maxNum){
      css_fwdActive = 'inactive';
    }


    return (
      <div className="nav__item image-number">
      <div className={`image-link ${css_backActive}`} onClick={() => this.handleBackClick()}><MdiChevronLeft width="28" height="28" /></div>

        <div className="image-number__container">
          <div className="nav__item--title">Image</div>
        <div className="nav__item--value">
          {this.props.currentNum} of {this.props.maxNum}
        </div>
      </div>
        <div className={`image-link ${css_fwdActive}`} onClick={() => this.handleFwdClick()}><MdiChevronRight /></div>
    </div>)
  }
}

function mapStateToProps(state) {
  return {viewer: state.viewer}
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({
resetLines
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavImageNum))
