import React, {Component} from 'react';
import Select from 'react-select';
import _ from 'lodash';
// Import Components

// Set variables

// Main Class
export default class EditorSelect extends Component {
  constructor(props) {
    console.log(props);
    super(props);
    this.state = {
      currentViewers: null,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps !== this.props){
      this.setupSelectOptions()
    }
  }

  setupSelectOptions() {
    // console.log(this.props.className, "setupSelectOptions --------------------------")
    let viewerList = [];
    if (this.props.existingViewers) {
      _.map(this.props.existingViewers, viewer => {
        viewerList.push({value: viewer.guid, label: viewer.name})
      })
      this.setState({currentViewers: viewerList})
    }
  }

  render() {
    return (
      <Select
        value={this.props.value}
        onBlurResetsInput={false}
        onSelectResetsInput={false}
        onChange={this.props.onChange}
        options={this.state.currentViewers}
        className={this.props.className}
      />
    )
  }
}
