import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import MdiThumbsUp from 'mdi-react/ThumbUpIcon';
import MdiTimer from 'mdi-react/TimerIcon';

import { setQuizView, stopQuizTimer, submitQuiz, addQuizLog } from '../../../actions/index'

// Set variables

// Main Class
class QuizSubmit extends Component {
  constructor() {
    super();
    this.state = {
      currentView: 'warning',
      visibilityCSSClass: 'hidden',
      content: '',
      quizSubmitted: false
    };
    this.handleWarningCancelClick = this.handleWarningCancelClick.bind(this)
    this.handleWarningSubmitClick = this.handleWarningSubmitClick.bind(this)
    this.handleSubmittedClick = this.handleSubmittedClick.bind(this)
    this.submitQuiz = this.submitQuiz.bind(this)
  }
  componentWillReceiveProps(newProps) {
    let visibilityCSSClass = 'hidden';
    let content = '';
    let currentView = newProps.quizProps.currentView;
    let quizSubmitted = false
    switch(newProps.quizProps.currentView) {
      case 'warning':
        content = this.renderWarningContent()
        visibilityCSSClass = 'visible'
      break
      case 'submit':
        content = this.renderSubmitContent()
        visibilityCSSClass = 'visible'
        quizSubmitted = true;
        if(!this.state.quizSubmitted){
          this.submitQuiz()
        }
      break;
      case 'submitTimerFinished':
        content = this.renderTimerFinishedContent()
        visibilityCSSClass = 'visible'
        quizSubmitted = true;
        if(!this.state.quizSubmitted){
          this.submitQuiz()
        }
      break;
      case 'submitError':
        content = this.renderSubmitErrorContent();
        visibilityCSSClass = 'visible';
        break;
      default:
      break
    }
    this.setState((state) => ({
      visibilityCSSClass,
      content,
      currentView,
      quizSubmitted
    }))
  }
  renderWarningContent() {
    return(
      <div className={`quiz-submit__block warning`}>
        <div className="quiz-submit__area">
           <h1>You are submitting {this.props.quizProps.itemsComplete} of {this.props.quizProps.totalItems} answers.<br/>Do you want to submit anyway?
           </h1>
           <div className="button-group">
        <div className="submit_button" onClick={this.handleWarningCancelClick}>Do not submit</div>
        <div className="submit_button warning" onClick={this.handleWarningSubmitClick}>Submit Anyway</div>
      </div>
          </div>
      </div>
    )
  }
  renderSubmitContent() {
    return(
      <div className="quiz-submit__block">
        <div className="quiz-submit__area">
          <div className="svg-group">
          <MdiThumbsUp />
        </div>
           <h1>You submitted {this.props.quizProps.itemsComplete} of {this.props.quizProps.totalItems} answers. <br/>
           Your instructor will get back to you with your marks.</h1>
           <div className="button-group">
        {/* <div className="submit_button" onClick={this.handleSubmittedClick}>Do something</div> */}
      </div>
          </div>
      </div>
    )
  }
  renderTimerFinishedContent() {
    return(
      <div className="quiz-submit__block">
        <div className="quiz-submit__area">
          <div className="svg-group">
          <MdiTimer/>
        </div>
           <h1>Whoops! Your time ran out and the quiz was submitted automatically.<br/><br/>
           You submitted {this.props.quizProps.itemsComplete} of {this.props.quizProps.totalItems} answers. <br/><br/>
           Your instructor will get back to you with your marks.</h1>
           <div className="button-group">
        {/* <div className="submit_button" onClick={this.handleSubmittedClick}>Do something</div> */}
      </div>
          </div>
      </div>
    )
  }
  renderSubmitErrorContent() {
    return <div className="quiz-submit__block">
      <div className="quiz-submit__area">
        <h1>Error occurred, your quiz may NOT be successfully submitted!<br /><br />Please contact your instructor.</h1>
      </div>
    </div>;
  }
  //// Cancel View
  handleWarningCancelClick() {
    this.setState((state) => ({
      visibilityCSSClass: 'hidden',
      content: ''
    }))
    this.props.setQuizView('quiz');
  }
  handleWarningSubmitClick() {
    this.props.setQuizView('submit');
  }
  //// Submitted View
  handleSubmittedClick() {
    // console.log('handleSubmittedClick')
  }
  submitQuiz() {
    // console.log('submitting quiz')
    //this.props.setQuizView('quiz');
    addQuizLog(this.props.quizProps.quizGuid, 10, "{}");
    this.props.stopQuizTimer('quizTimer')
    this.props.submitQuiz(this.props.viewer, this.props.quiz, this.props.timer)
  }

  render() {
    return (
      <div className={`quiz-submit ${this.state.visibilityCSSClass}`}>
        {this.state.content}
      </div>
    )
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {
    viewer: state.viewer,
    timer: state.timer,
    quiz: state.quiz,
    quizProps: state.quiz.quizProps,
    currentView: state.quiz.quizProps.currentView
  }
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({
    setQuizView,
    stopQuizTimer,
    submitQuiz
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuizSubmit))
