import jwt from "jsonwebtoken";
import { authUrl } from "../global";

export default class AuthService {
  // Initializing important variables
  constructor(domain) {

    this.domain = domain || authUrl; // API server domain
    this.fetch = this.fetch.bind(this); // React binding stuff
    this.login = this.login.bind(this);
    this.getProfile = this.getProfile.bind(this);

    this.domain = `${this.domain}`;
  }

  login(username, password) {
    // console.log("auth-service login")
    // Get a token from api server using the fetch api
    return this.fetch(
      `${this.domain}?username=${username}&password=${password}`,
      {
        method: "POST",
        body: JSON.stringify({
          username,
          password,
        }),
      }
    ).then((res) => {
      if (!res.errorMessage) {
        const token = jwt.sign({ res }, "edIsMyHero", { expiresIn: "12h" });
        this.setToken(token); // Setting the token in localStorage
        return Promise.resolve(res);
      } else {
        return Promise.reject(Error(res.errorMessage));
      }
    });
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken(); // GEtting token from localstorage
    return !!token && !this.isTokenExpired(token);
  }

  isTokenExpired(token) {
    try {
      const decoded = jwt.decode(token);
      if (decoded.exp < Date.now() / 1000) {
        // Checking if token is expired
        return true;
      } else return false;
    } catch (err) {
      return false;
    }
  }

  setToken(idToken) {
    // Saves user token to localStorage
    localStorage.setItem("auth_token", idToken);
  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem("auth_token");
  }

  logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem("auth_token");
  }

  getProfile() {
    // Using jwt-decode npm package to decode the token
    const decodedToken = jwt.decode(this.getToken());
    return decodedToken.res;
  }

  fetch(url, options) {
    // performs api calls sending the required authentication headers
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    if (this.loggedIn()) {
      headers["Authorization"] = "Bearer " + this.getToken();
    }

    return fetch(url, {
      headers,
      ...options,
    })
      .then(this._checkStatus)
      .then((response) => response.json());
  }

  _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      return response;
    } else {
      var error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }
}
