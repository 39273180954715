import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';

// Import Actions
import {globalAlert} from '../actions'

class Alert extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: this.props.globalAlert.visible,
      message: this.props.globalAlert.message
    }
    this.handleAlertDismiss = this.handleAlertDismiss.bind(this)
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      visible: nextProps.globalAlert.visible,
      message: nextProps.globalAlert.message
    })
  }
  handleAlertDismiss(event) {
    this.props.dispatchGlobalAlert();
  }

  render() {
    if (this.state.visible) {
      return (
        <div className="error--global alert alert-danger alert-dismissible" role="alert">
          <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.handleAlertDismiss}>
            <span aria-hidden="true">x</span>
          </button>
          <strong>{this.state.message}</strong>
        </div>
      );
    } else {
      return (null)
    }
  }
}
function mapStateToProps(state) {
  return {globalAlert: state.globalAlert};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    dispatchGlobalAlert: globalAlert
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Alert)
