import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import _ from 'lodash';
import {Scrollbars} from 'react-custom-scrollbars';

// Import Components
import QuizItem from './quiz-item'
import NextQuestionBtn from './quiz-next-question-btn'
//Import actions
import {resetLines} from '../../../actions/index'
// Set variables

// Main Class
class TermList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clickLineIndex: '',
      quizQuestions: [
        {
          quizID: 0,
          correctAnswer: '',
          studentAnswer: ''
        }
      ]
    };
    this.setClickedItem = this.setClickedItem.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.match !== nextProps.match) {
      this.setState({clickLineIndex: 'none'})
    }
  }
  handleScroll() {
    this.props.resetLines()
    this.setState({clickLineIndex: 'none'})
  }
  setClickedItem(index) {
    this.setState({clickLineIndex: index})
  }
  renderQuizItems(terms, itemClicked) {
    return _.map(terms, (term, index) => {
      let isClicked = false;
      let key = term.quizID
      if (itemClicked === index) {
        isClicked = true;
      }
      return (
        <QuizItem
          key={key}
          quizID={term.quizID}
          isClicked={isClicked}
          index={index}
          term={term}
          setClickedItem={() => this.setClickedItem(index)}
        />
      )
    })
  }

  render() {
    return (
      <div className="termList">
        <Scrollbars
          className='scroll-area'
          onScroll={this.handleScroll}
          >
          {this.renderQuizItems(this.props.data, this.state.clickLineIndex)}
          <NextQuestionBtn />
        </Scrollbars>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({resetLines}, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TermList))
