import axios from "axios";
import _ from "lodash";
// import convert from "xml-js";
import { parseError } from "./index";

import { di1EditorSetViewer } from "./editor-api-actions";

import { authData } from "../components/views/editor-wrapper";
import qs from "qs";

////// 1.0 Editor Action definitions
export const DI1_EDITOR_SET_META_TITLE = "di1_editor_set_meta_title";
export const DI1_EDITOR_SET_META_ISQUIZ = "di1_editor_set_meta_isquiz";
export const DI1_EDITOR_SET_META_QUIZLENGTH = "di1_editor_set_meta_quizlength";
export const DI1_EDITOR_SET_META_TITLE1 = "di1_editor_set_meta_title1";
export const DI1_EDITOR_SET_META_TITLE2 = "di1_editor_set_meta_title2";
export const DI1_EDITOR_SET_META_TITLE3 = "di1_editor_set_meta_title3";

export const DI1_EDITOR_SET_ANATOMY_ADD = "di1_editor_set_anatomy_add";
export const DI1_EDITOR_SET_ANATOMY_REMOVE = "di1_editor_set_anatomy_remove";
export const DI1_EDITOR_SET_ANATOMY_TITLE = "di1_editor_set_anatomy_title";

export const DI1_EDITOR_SET_PLANE_ADD = "di1_editor_set_plane_add";
export const DI1_EDITOR_SET_PLANE_REMOVE = "di1_editor_set_plane_remove";
export const DI1_EDITOR_SET_PLANE_TITLE = "di1_editor_set_plane_title";
export const DI1_EDITOR_SET_PLANE_SCOUT_IMAGE =
  "di1_editor_set_plane_scout_image";
export const DI1_EDITOR_SET_PLANE_SCOUT_ORIENTATION =
  "di1_editor_set_plane_scout_orientation";

export const DI1_EDITOR_SET_SLICE_ADD = "di1_editor_set_slice_add";
export const DI1_EDITOR_SET_SLICE_SWITCH = "di1_editor_set_slice_switch";
export const DI1_EDITOR_SET_SLICE_REMOVE = "di1_editor_set_slice_remove";

export const DI1_EDITOR_SET_TERM_ADD = "di1_editor_set_term_add";
export const DI1_EDITOR_SET_TERM_REMOVE = "di1_editor_set_term_remove";
export const DI1_EDITOR_SET_TERM_TITLE = "di1_editor_set_term_title";

export const DI1_EDITOR_SET_TERM_LINE_ADD = "di1_editor_set_term_line_add";
export const DI1_EDITOR_SET_TERM_LINE_REMOVE =
  "di1_editor_set_term_line_remove";
export const DI1_EDITOR_SET_TERM_INDEX = "di1_editor_set_term_index";

export const DI1_EDITOR_NEWLINE_ENABLE = "di1_editor_newline_enable";
export const DI1_EDITOR_NEWLINE_DISABLE = "di1_editor_newline_disable";

export const DI1_EDITOR_SET_SCOUT_EDIT_MODE = "di1_editor_set_scout_edit_mode";
export const DI1_EDITOR_SET_SCOUT_ADD = "di1_editor_set_scout_add";
export const DI1_EDITOR_SET_SCOUT_REMOVE = "di1_editor_set_scout_remove";
export const DI1_EDITOR_SET_SCOUT_ADD_LINE = "di1_editor_set_scout_add_line";
export const DI1_EDITOR_SET_SCOUT_REMOVE_LINE =
  "di1_editor_set_scout_remove_line";
export const DI1_EDITOR_SET_SCOUT_LINE_POSITION =
  "di1_editor_set_scout_line_position";
export const DI1_EDITOR_SET_SCOUT_LINE_ORIENTATION =
  "di1_editor_set_scout_line_orientation";
export const DI1_EDITOR_SET_QUIZ_EDITOR_VISIBILITY =
  "di1_editor_set_quiz_editor_visibility";

export const DI1_EDITOR_SET_IMAGELIST = "di1_editor_set_imagelist";

export const DI1_EDITOR_INSERT_SLICE_AT = "di1_editor_insert_slice_at";
export const DI1_SET_SLICE_PICKER_VISIBILITY =
  "di1_set_slice_picker_visibility";
export const DI1_SET_SLICE_SWITCHER_VISIBILITY =
  "di1_set_slice_switcher_visibility";
export const DI1_SET_SCOUT_PICKER_VISIBILITY =
  "di1_set_scout_picker_visibllity";

////// Setup and Variables
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.withCredentials = true;

// eslint-disable-next-line
//const DI1_URL = 'https://digilearn.nait.ab.ca/nait/docstorage/docstorage.asmx/GetStoreXML';
//const DI1_VIEWER_PASS = 'diViewerGet';

////////////////////////////////////////////////////////////////////////////////
// Actions for the editor
////////////////////////////////////////////////////////////////////////////////

//// META
export function di1Editor_SetTitle(title) {
  return { type: DI1_EDITOR_SET_META_TITLE, payload: title };
}
export function di1Editor_setSlicePickerVisibility(isVisible, insertSlideAt) {
  return { type: DI1_SET_SLICE_PICKER_VISIBILITY, payload: isVisible };
}
export function di1Editor_setSliceSwitcherVisibility(isVisible) {
  return { type: DI1_SET_SLICE_SWITCHER_VISIBILITY, payload: isVisible };
}
export function di1Editor_setScoutPickerVisibility(isVisible) {
  return { type: DI1_SET_SCOUT_PICKER_VISIBILITY, payload: isVisible };
}
export function di1Editor_setSlicePickerHidden() {
  return { type: DI1_SET_SLICE_PICKER_VISIBILITY, payload: false };
}
export function di1Editor_setSliceSwitcherHidden() {
  return { type: DI1_SET_SLICE_SWITCHER_VISIBILITY, payload: false };
}
export function di1Editor_setScoutPickerHidden() {
  return { type: DI1_SET_SCOUT_PICKER_VISIBILITY, payload: false };
}

////////////////////////////////////////////////////////////////////////////////
//// Anatomy
export function di1Editor_SetAnatomy_Title(data) {
  const payload = {
    index: data.index,
    title: data.title,
  };
  return { type: DI1_EDITOR_SET_ANATOMY_TITLE, payload };
}
export function di1Editor_SetAnatomy_Add() {
  const payload = {};
  return { type: DI1_EDITOR_SET_ANATOMY_ADD, payload };
}
export function di1Editor_SetAnatomy_Remove(data) {
  const payload = {
    index: data.index,
    title: data.title,
  };
  return { type: DI1_EDITOR_SET_ANATOMY_REMOVE, payload };
}

////////////////////////////////////////////////////////////////////////////////
//// Plane/Pulse
export function di1Editor_SetPlane_Title(data) {
  const payload = {
    index: data.index,
    anatomyIndex: data.anatomyIndex,
    title: data.title,
  };
  return { type: DI1_EDITOR_SET_PLANE_TITLE, payload };
}
export function di1Editor_SetPlane_Add(data) {
  const payload = {
    anatomyIndex: data[0].anatomyIndex,
  };
  return { type: DI1_EDITOR_SET_PLANE_ADD, payload };
}
export function di1Editor_SetPlane_Remove(data) {
  const payload = {
    anatomyIndex: data.anatomyIndex,
    index: data.index,
    title: data.title,
  };
  return { type: DI1_EDITOR_SET_PLANE_REMOVE, payload };
}

////////////////////////////////////////////////////////////////////////////////
//// Slice
export function di1Editor_SetSlice_Add(data) {
  const payload = data;
  return { type: DI1_EDITOR_SET_SLICE_ADD, payload };
}
export function di1Editor_SetSlice_At(data) {
  const payload = data;
  return { type: DI1_EDITOR_INSERT_SLICE_AT, payload };
}
export function di1Editor_SetSlice_Switch(data) {
  const payload = data;
  return { type: DI1_EDITOR_SET_SLICE_SWITCH, payload };
}
export function di1Editor_SetSlice_Remove(data) {
  const payload = data;
  return { type: DI1_EDITOR_SET_SLICE_REMOVE, payload };
}

////////////////////////////////////////////////////////////////////////////////
//// Term
export function di1Editor_SetTerm_Add(data) {
  const payload = data;
  return { type: DI1_EDITOR_SET_TERM_ADD, payload };
}
export function di1Editor_SetTerm_Remove(data) {
  const payload = data;
  return { type: DI1_EDITOR_SET_TERM_REMOVE, payload };
}
export function di1Editor_SetTerm_Title(data) {
  const payload = data;
  return { type: DI1_EDITOR_SET_TERM_TITLE, payload };
}
export function newLine_enable() {
  const payload = { newLine: true };
  return { type: DI1_EDITOR_NEWLINE_ENABLE, payload };
}
export function newLine_disable() {
  const payload = { newLine: false };
  return { type: DI1_EDITOR_NEWLINE_DISABLE, payload };
}
export function di1Editor_setTermIndex(index) {
  return { type: DI1_EDITOR_SET_TERM_INDEX, index };
}
export function di1Editor_SetLine_Add(payload) {
  return { type: DI1_EDITOR_SET_TERM_LINE_ADD, payload };
}
export function di1Editor_SetLine_Remove(payload) {
  return { type: DI1_EDITOR_SET_TERM_LINE_REMOVE, payload };
}

////////////////////////////////////////////////////////////////////////////////
//// Scout
export function setScoutEditMode(payload) {
  return { type: DI1_EDITOR_SET_SCOUT_EDIT_MODE, payload };
}
export function di1Editor_SetScout_Add(payload) {
  return { type: DI1_EDITOR_SET_SCOUT_ADD, payload };
}
export function di1Editor_removeScout(payload) {
  return { type: DI1_EDITOR_SET_SCOUT_REMOVE, payload };
}
export function addScoutLine(payload) {
  return { type: DI1_EDITOR_SET_SCOUT_ADD_LINE, payload };
}
export function removeScoutLine(payload) {
  return { type: DI1_EDITOR_SET_SCOUT_REMOVE_LINE, payload };
}
export function setScoutLinePosition(payload) {
  return { type: DI1_EDITOR_SET_SCOUT_LINE_POSITION, payload };
}
export function setScoutLineOrientation(payload) {
  return { type: DI1_EDITOR_SET_SCOUT_LINE_ORIENTATION, payload };
}

////////////////////////////////////////////////////////////////////////////////
//// Quiz Editor
export function setQuizEditorVisibility(payload) {
  return { type: DI1_EDITOR_SET_QUIZ_EDITOR_VISIBILITY, payload };
}

////////////////////////////////////////////////////////////////////////////////
//// GET IMAGE LIST FROM SERVER
export function di1GETImageList(url) {

  let imageListURL = `${process.env.REACT_APP_DOC_STORAGE_URL}/api/images/GetImageListing`;

  const params = qs.stringify({
    baseUrl: url,
  });

  const request = axios({
    method: "post",
    withCredentials: false,
    url: imageListURL,
    data: params,
  });
  return (dispatch) => {
    request
      .then((response) => {
        // const options = {
        //   compact: true,
        //   ignoreDeclaration: true,
        //   textKey: "src",
        // };

        const extendedImagelist = [];
        // const imageList = [];

        response.data.forEach(image => {
          extendedImagelist.push({
            src: `${url}${image}`,
            thumbnail: `${url}${image}`,
            path: image,
          })
        });

        dispatch({
          type: DI1_EDITOR_SET_IMAGELIST,
          payload: extendedImagelist,
        });
        // });
      })
      .catch(function (error) {
        parseError(error);
      });
  };
}

////////////////////////////////////////////////////////////////////////////////
//// POST IMAGE SET TO SERVER
export function di1POSTImageSet(imageSet) {
  let instructions;
  if (imageSet._attributes.quiz === "0") {
    instructions = _.escape(`<p>Viewers don't get instructions</p>`);
  } else {
    instructions = _.escape(
      imageSet.instructions
        ? imageSet.instructions[0]._text[0]
        : `<p>Instructions go here</p>`
    );
  }
  let duration = imageSet._attributes.duration || "0";

  let xmlToSubmit = "";
  let xmlHeader = `<?xml version="1.0" encoding="utf-8"?>
  <div_data guid="${imageSet._attributes.guid}" name="${_.escape(
    imageSet._attributes.name
  )}" quiz="${imageSet._attributes.quiz}" imagesbaseurl="${
    imageSet._attributes.imagesbaseurl
  }" duration="${duration}" title1="${_.escape(
    imageSet._attributes.title1
  )}" title2="${_.escape(imageSet._attributes.title2)}" title3="${_.escape(
    imageSet._attributes.title3
  )}">`;
  let xmlBody = "";

  let xmlFooter = `<instructions>${instructions}</instructions>
    <instructor_email></instructor_email>
    <quiz_title>${_.escape(imageSet._attributes.name)}</quiz_title>
    <log_id></log_id>
    <objective_number></objective_number>
    </div_data>`;
  _.map(imageSet.anatomy, (anatomy, anatomyIndex) => {
    let xmlAnatomy = `<anatomy menuname="${_.escape(
      anatomy._attributes.menuname
    )}">`;
    _.map(anatomy.plane_pulse, (plane, planeIndex) => {
      let orient = plane._attributes.orient || "0";
      let xmlPlane = `<plane_pulse menuname="${_.escape(
        plane._attributes.menuname
      )}" scout="${_.escape(plane._attributes.scout)}" orient="${orient}">`;
      _.map(plane.image, (image, imageIndex) => {
        const scoutPos = image._attributes.pos
          ? `pos="${image._attributes.pos}"`
          : "";
        let xmlImage = `<image path="${_.escape(
          image._attributes.path
        )}" ${scoutPos}>`;
        _.map(image.term, (term, termIndex) => {
          let xmlTerm = `<term text="${_.escape(term._attributes.text)}">`;
          _.map(term.line, (line, lineIndex) => {
            let xmlLine = `<line x="${line._attributes.x}" y="${line._attributes.y}"></line>`;
            xmlTerm += xmlLine;
          });
          xmlTerm += `</term>`;
          xmlImage += xmlTerm;
        });
        xmlImage += `</image>`;
        xmlPlane += xmlImage;
      });
      xmlPlane += `</plane_pulse>`;
      xmlAnatomy += xmlPlane;
    });
    xmlAnatomy += `</anatomy>`;
    xmlBody += xmlAnatomy;
  });
  xmlToSubmit = xmlHeader + xmlBody + xmlFooter;

  const programData = {
    sSetPassword: authData.viewersDocStoreSetPassword,
    sDocIdentity: imageSet._attributes.guid,
    sBinString: xmlToSubmit,
  };
  di1EditorSetViewer(programData);
}
