import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import _ from 'lodash';

// Import Components
import MdiAdjust from 'mdi-react/AdjustIcon';
//Import actions
import {createLines, resetLines, setQuizStudentAnswer, setQuizTermsRemaining, addQuizLog} from '../../../actions/index'
// Set variables

// Main Class
class QuizItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverLineIndex: 'none',
      clickLineIndex : 'none',
      prevValue: ""
    };
    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
    this.onMouseClick = this.onMouseClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
  }
  componentWillReceiveProps(nextProps) {
  }
  componentDidMount(){
    if (this.props.index === 0 && _.get(this, "props.quiz.quizProps.currentView", "").indexOf('submit') !== 0) {
      this.nameInput.focus();
    }
  }
  onMouseEnterHandler(locations, index) {
    // console.log('onMouseEnterHandler');
    let hoverLines = {};
    let hoverEnds = [];
    let lineEndClass = '';
    let lineStartClass = `line-start-${index}`;
    hoverLines.lineStartClass = lineStartClass;
    let i = 0;
    _.map(locations, (location) => {
      lineEndClass = `line-end-${index}--${i}`;
      hoverEnds.push(lineEndClass);
      i++
    })
    hoverLines.lineEndClass = hoverEnds
    this.props.createLines(this.props.lines, hoverLines,null );
  }
  onMouseLeaveHandler() {
    // console.log('onMouseLeaveHandler');
    let hoverLines = {};
    this.props.createLines(this.props.lines, hoverLines,null );
  }
  onMouseClick(locations, index) {
    // console.log('onMouseClick');
    let clickLines = {};
    let clickEnds = [];
    let lineEndClass = '';
    let lineStartClass = `line-start-${index}`;
    clickLines.lineStartClass = lineStartClass;
    let i = 0;
    _.map(locations, (location) => {
      lineEndClass = `line-end-${index}--${i}`;
      clickEnds.push(lineEndClass);
      i++;
    })
    clickLines.lineEndClass = clickEnds
    this.props.createLines(this.props.lines, null, clickLines);
    this.props.setClickedItem(index);
    this.setState({clickLineIndex: index});
  }
  handleInputChange(evt) {
    // console.log('handleInputChange');
    let quizID = this.props.quizID;
    let studentAnswer = evt.target.value
    this.setState({
      quizQuestion: {
        studentAnswer
      }
    })
    this.props.setQuizStudentAnswer(this.props.quiz.quizTerms, quizID, studentAnswer);
  }
  handleOnBlur(evt, questionNum) {
    this.props.setQuizTermsRemaining(this.props.quiz);
    if (this.state.prevValue !== evt.target.value) {
      addQuizLog(this.props.quiz.quizProps.quizGuid, 1, JSON.stringify({answers: {[questionNum]: evt.target.value}}));
    }
    this.setState({prevValue: ""});
  }
  render() {
    if(this.props.quiz.quizTerms[this.props.quizID] !== undefined){
    let lineStartClass = `line-start-${this.props.index}`;
    let clickedClass = '';
    if(this.props.isClicked){
      clickedClass = 'clicked';
    }
      return (
        <div
          className={`quiz-item ${clickedClass} question--${this.props.index}`}
          onMouseEnter={() => this.onMouseEnterHandler(this.props.term.location, this.props.index)}
          onMouseLeave={this.onMouseLeaveHandler}
          >
            <div className={`quiz-item__line ${lineStartClass}`}></div>
            <div className="quiz-item__entry">
            <div className="quiz-item__question-number">{this.props.term.quizID}</div>
            <input
              onFocus={(e) => this.setState({prevValue: e.target.value})}
              className={`input-${this.props.index}`}
              ref={(input) => { this.nameInput = input; }}
              value={this.props.quiz.quizTerms[this.props.quizID].studentAnswer}
              onChange={this.handleInputChange}
              onBlur={(e) => this.handleOnBlur(e, this.props.term.quizID)}
              ></input>
          </div>
          <div
            className={`quiz-item__toggle ${clickedClass}`}
            onClick={() => {this.onMouseClick(this.props.term.location, this.props.index)}}
            ><MdiAdjust width={24} height={24}/></div>

        </div>
      )
  } else {
    return (
      <div>
      </div>
    )
  }
}
}

function mapStateToProps(state) {
  return {
    lines: state.lines,
    quiz: state.quiz
  }
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({
    createLines,
    resetLines,
    setQuizStudentAnswer,
    setQuizTermsRemaining
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuizItem))
