import React, {Component} from 'react';
import _ from 'lodash';
// Import actions

// Import Components

// Set variables

// Main Class
export default class SliceGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageList: [],
      selectedImages: []
    };
  }
  componentDidMount(){

  }
  componentDidUpdate(prevProps, prevState){
    // if(prevProps.editor.imageList !== this.props.editor.imageList){
    //   console.log('SliceGallery componentDidUpdate')
    //   this.setState({
    //     ...this.state,
    //     imageList: this.props.editor.imageList
    //   })
    // }
  }

  renderImages = (images) => {
    return(
    _.take(_.map(images, (image, index) => {
      let css_isSelected = '';
      let sliceOrder = ''
      let sliceTotal = 0;
      const pathArray = image.path.split( '/' );
      const filename = pathArray[pathArray.length - 1]
      const httpsSrc = image.src.replace(/^http:\/\//i, 'https://');
      // console.log('renderImages: ', image)
      _.map(this.props.planeSlices, (slice, sliceIndex) => {
        sliceTotal = this.props.planeSlices.length;
        if(slice._attributes && slice._attributes.path === image.path) {
          css_isSelected = 'selected'
          sliceOrder = sliceIndex + 1;
        }
      })
      return(
        <div
          key={index}
          className={`image-tile ${css_isSelected}`}
          onClick={() => this.props.onSelectImage(image)}
          >
          <div className="image-container">
            <div className="slice-order">Slice {sliceOrder} of {sliceTotal}</div>
            <img
              className="image"
              src={httpsSrc}
              alt={`Slice ${index}`}
            />
            <div className="filename">{filename}</div>
          </div>
        </div>
      )
    }), 50))
  }
  render() {

    // console.log(this.props.planeSlices)
    return(
      <>
        <p>Only 50 images will be loaded, please use the search box.</p>
        <div className="image-gallery">
          {this.renderImages(this.props.images)}
        </div>
      </>
    )
  }
}
