import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import _ from 'lodash';

// Import Components

//Import actions

// Set variables

// Main Class
class NextQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.handleNextQuestionClick = this.handleNextQuestionClick.bind(this)
  }
  componentWillReceiveProps(nextProps) {

  }

  handleNextQuestionClick() {
    let quizTerms = {...this.props.quizTerms}
    let currentURL = `/${this.props.match.params.GUID}/${this.props.match.params.anatomy}/${this.props.match.params.plane}/${this.props.match.params.slice}`
    let redirectionURL = ''
    _.each(quizTerms, (term, index) => {
      if(term.studentAnswer === ''){
        // console.log(term.quizURL, index)
        redirectionURL = `/${this.props.match.params.GUID}/${term.quizURL}`
        if(currentURL === redirectionURL) {
          // console.log("Whoops! that's the same link!", index-1)
        } else {
          this.props.history.push(redirectionURL);
        }
        return false
      }
    })
  }
  render() {
    return (
      <div className="next-question-button" onClick={this.handleNextQuestionClick}>
        Next Unanswered Question
      </div>
    )
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {
    quizTerms: state.quiz.quizTerms
  }
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({

  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NextQuestion))
