import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
// Import Components

import MdiTimer from 'mdi-react/TimerIcon';

import { setQuizView } from '../../../actions/index'
// Set variables

// Main Class
class Countdown extends Component {

  formatTime(time){
    // Hours, minutes and seconds
    let hrs = Math.floor(time / 3600);
    let mins = Math.floor((time % 3600) / 60);
    let secs = time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let formattedTime = "";

    if (hrs > 0) {
        formattedTime += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    formattedTime += "" + mins + ":" + (secs < 10 ? "0" : "");
    formattedTime += "" + secs;
    return formattedTime;
  }
  render() {
    let count = 1;
    if(this.props.timer) {

      count = this.props.timer.timeLeft;
      // console.log('timer: ', count)
    }
    const readableTime = this.formatTime(count);

    let countdownTextClass= 'time--good';
    if(count <= 300 && count >= 121) {
      countdownTextClass = 'time--warning';
    } else if(count <= 120 && count >= 1) {
      countdownTextClass = 'time--danger-will-robinson';
    } else if(count === 0) {
      countdownTextClass = 'time--danger-will-robinson';
      this.props.setQuizView('submitTimerFinished')
    }

    return (
      <div className={`countdown ${countdownTextClass}`}>
        <MdiTimer/><div ref={"countdown__text"} className='countdown__text'>{readableTime}</div>
      </div>
    )
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {
    timer: state.timer
  }
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({
    setQuizView
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Countdown))
