import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Import Components
import MdiChevronRight from "mdi-react/ChevronRightIcon";
import MdiChevronLeft from "mdi-react/ChevronLeftIcon";
import MdiRemove from "mdi-react/MinusCircleIcon";
import MdiPlusCircle from "mdi-react/PlusCircleIcon";
import MdiPencil from "mdi-react/PencilIcon";

// Set variables

// Import actions
import { resetLines } from "../../../actions/index";
import { newLine_disable } from "../../../actions/editor-actions";

// Main Class
class NavImageNum extends Component {
  handleBackClick = () => {
    this.props.resetLines();
    let redirectionURL = `/editor/${this.props.match.params.GUID}/${
      this.props.match.params.anatomy
    }/${this.props.match.params.plane}/${parseInt(
      this.props.match.params.slice,
      10
    ) - 1}`; //${this.props.match.params.slice}`
    this.props.history.push(redirectionURL);
    //this.props.newLine_disable();
  };
  handleFwdClick = () => {
    this.props.resetLines();
    let redirectionURL = `/editor/${this.props.match.params.GUID}/${
      this.props.match.params.anatomy
    }/${this.props.match.params.plane}/${parseInt(
      this.props.match.params.slice,
      10
    ) + 1}`; //${this.props.match.params.slice}`
    this.props.history.push(redirectionURL);
    //this.props.newLine_disable();
  };

  handleRemoveSlice = () => {
    //this.props.newLine_disable();
    this.props.onRemoveSlice();
  };
  handleAddSliceBefore = () => {
    //this.props.newLine_disable();
    this.props.onAddSliceBefore();
  };
  handleAddSliceAfter = () => {
    //this.props.newLine_disable();
    this.props.onAddSliceAfter();
  };
  handleSwitchSlice = () => {
    //this.props.newLine_disable();
    this.props.onSwitchSlice();
  };

  render() {
    let css_backActive = "";
    let css_fwdActive = "";
    let css_removeActive = "";
    if (parseInt(this.props.currentNum, 10) <= 1) {
      css_backActive = "inactive";
    }

    if (parseInt(this.props.currentNum, 10) === this.props.maxNum) {
      css_fwdActive = "inactive";
    }

    if (this.props.maxNum <= 1) {
      css_removeActive = "inactive";
    }

    return (
      <div className="nav__item image-number">
        <div className="image-number__top">
          <div
            className={`image-link ${css_backActive}`}
            onClick={() => this.handleBackClick()}
          >
            <MdiChevronLeft width="28" height="28" />
          </div>
          <div className="image-number__container">
            <div className="nav__item--title">Slice</div>
            <div className="nav__item--value">
              {this.props.currentNum} of {this.props.maxNum}
            </div>
          </div>
          <div
            className={`image-link ${css_fwdActive}`}
            onClick={() => this.handleFwdClick()}
          >
            <MdiChevronRight />
          </div>
        </div>
        <div className="editor-actions">
        <div className="image-action add-image" onClick={this.handleAddSliceBefore}>
          <MdiPlusCircle />
          Add Slice<br/>Before
        </div>
        <div className="image-action add-image" onClick={this.handleAddSliceAfter}>
          <MdiPlusCircle />
          Add Slice<br/>After
        </div>
          <div
            className={`image-action remove-image ${css_removeActive}`}
            onClick={this.handleRemoveSlice}
          >
            <MdiRemove />
            Remove Slice
          </div>

          <div
            className="image-action switch-image"
            onClick={this.handleSwitchSlice}
          >
            <MdiPencil />
            Edit Slice
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { viewer: state.viewer };
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetLines,
      newLine_disable
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NavImageNum)
);
