import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import promise from "redux-promise";
import thunk from "redux-thunk";
import timerMiddleware from "redux-timer-middleware";
//import rootReducer from "./reducers"
import rootReducer from "./reducers/";

const configureStore = () => {
  const initialState = {
    scout: { visible: false, disabled: false },
    quiz: {
      quizTerms: {},
      quizProps: {
        itemsComplete: 0,
        itemsRemaining: 0,
        totalItems: 0,
        currentView: "login"
      }
    },
    editor: {
      programs: {
        magnetic: {
          name: "magnetic",
          title: "Magnetic"
          //guid: "6c95910c-a160-4c7c-8ff5-3dbc12ce52d7"
        },
        ultrasound: {
          name: "ultrasound",
          title: "Ultrasound"
          //guid: "1585aaf9-9014-472c-9a8b-5658a39b5f39"
        },
        xray: {
          name: "xray",
          title: "X-ray"
          //guid: "b9aac333-4069-4e8d-a6c3-87472a75c96d"
        },
        pathology: {
          name: "pathology",
          title: "Pathology"
          //guid: "6965ab6d-196f-4b84-9915-85d49fbe5b21"
        },
        test: {
          name: "test",
          title: "Testing"
          //guid: "00000000-0000-0000-0000-00000000000d"
        }
      },
      imagePicker: {
        sliceVisible: false,
        insertSliceAt: 0,
        switchVisible: false,
        scoutVisible: false
      },
      imageList: {},
      newLineMode: false,
      termIndex: 0,
      scoutEditMode: false,
      quizEditorVisiblity: false
    }
  };
  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(promise, thunk, timerMiddleware)
      // other store enhancers if any
    )
  );

  if (process.env.NODE_ENV !== "production") {
    if (module.hot) {
      module.hot.accept("./reducers/", () => {
        store.replaceReducer(rootReducer);
      });
    }
  }
  return store;
};

export default configureStore;
