import React, {Component} from 'react';
import { Field, reduxForm } from 'redux-form'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter, /*Link*/} from 'react-router-dom';
import {addQuizLog} from "../../../actions";

const generateGUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

// Import Actions
import { startQuizTimer, setupStudentInfo, setQuizView } from '../../../actions/index'

const renderField = ({ input, label, type, sprite, meta: { touched, error }}) => {
  let errorCSS = ''
  if(error !== undefined && touched) {
    errorCSS= 'error'
  }
  return(
    <div className="input-group">
       <div className={`errors ${errorCSS}`}>
          { touched && (error && <span>{error}</span>)}
        </div>
      <input
        className={errorCSS}
        // validate={this.nameValidate}
        type={type}
        placeholder={label}
        {...input}/>
    </div>
  )
}
const firstNameRequired = value => (value && value.trim().length > 0 ? undefined : 'Please enter your first name')
const lastNameRequired = value => (value && value.trim().length > 0 ? undefined : 'Please enter your last name')
const sIDRequired = value => (value && value.trim().length > 0 ? undefined : 'Please enter your student ID')
const sIDNumberRequired = value =>
  value && isNaN(Number(value)) ? 'Your Student ID Must be a Number' : undefined

class LoginForm extends Component {
  constructor(props) {
      super(props);
      this.state = {
        errorText: ''
      };
    }

onSubmit(values){
  let studentInfo = {
    quizGuid : generateGUID(),
    studentFirstName : values.studentFirstName.trim(),
    studentLastName : values.studentLastName.trim(),
    studentID : values.studentID.trim()
  };
  addQuizLog(studentInfo.quizGuid, 0, JSON.stringify({
    studentID: +studentInfo.studentID,
    studentName: `${studentInfo.studentLastName}, ${studentInfo.studentFirstName}`,
    viewerGuid: this.props.viewer.guid,
    quizName: this.props.viewer.name.trim(),
    quizDuration: +this.props.viewer.duration,
  }));
  this.props.startQuizTimer('quizTimer', this.props.timer.timerPeriod)
  this.props.setupStudentInfo(this.props.quizProps, studentInfo)
  this.props.setQuizView('quiz');
  this.props.hideLogin();
}
nameValidate() {
  return false;
}
render() {
  const { handleSubmit } = this.props
  return (
    <form name="loginForm" className="login-form" onSubmit={handleSubmit(this.onSubmit.bind(this))}>
        <Field
          name="studentFirstName"
          type="text"
          component={renderField}
          validate={[firstNameRequired]}
          label="First Name"
        />
        <Field
          name="studentLastName"
          type="text"
          component={renderField}
          validate={[lastNameRequired]}
          label="Last Name"
        />
        <Field
          name="studentID"
          type="text"
          component={renderField}
          validate={[sIDRequired, sIDNumberRequired]}
          label="Student ID"
        />
        <div className="time-warning">You will have {this.props.timeLimit} minutes to complete this quiz</div>
        <div className="button-group">
          <div className="submit_button" onClick={handleSubmit(this.onSubmit.bind(this))}>Start Quiz</div>
        </div>
    </form>
  )
}
}
function mapStateToProps(state) {
  return {
    viewer: state.viewer._attributes,
    quizProps: state.quiz.quizProps,
    timer: state.timer
  }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      setQuizView,
      startQuizTimer,
      setupStudentInfo
    }, dispatch)
}
export default reduxForm({
  form: 'loginForm',
  initialValues: {

  }, // a unique identifier for this form
})(connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginForm)))
