import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Import actions
import { setQuizInfo } from "../../../actions/index";

import { setQuizEditorVisibility } from "../../../actions/editor-actions";
// Import Components
// Set variables

//eslint-disable-next-line
let planeSlices = [];
// Main Class
class QuizEditor extends Component {
  constructor(props) {
    super(props);

    //const tempdata = '<TEXTFORMAT LEADING="2"><P ALIGN="LEFT"><FONT FACE="Tahoma" SIZE="12" COLOR="#4D4D4D" LETTERSPACING="0" KERNING="0">These are some <B>test</B> instructions.</FONT></P></TEXTFORMAT>'

    let contentBlock = "";
    let editorState = "";
    let quizDuration = "";

    if (
      this.props.viewer.instructions &&
      this.props.viewer.instructions[0]._text
    ) {
      // console.log('this.props.viewer.instructions[0]._text', this.props.viewer.instructions[0]._text)
      contentBlock = htmlToDraft(this.props.viewer.instructions[0]._text[0]);
    }
    if (this.props.viewer._attributes.duration) {
      quizDuration = this.props.viewer._attributes.duration;
    }
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      editorState = EditorState.createWithContent(contentState);
    }
    this.state = {
      editorState,
      quizDuration: quizDuration
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    this.props.setQuizEditorVisibility(false);
  }

  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
  };

  onSaveClick = () => {
    const data = {
      duration: this.state.quizDuration,
      instructions: draftToHtml(
        convertToRaw(this.state.editorState.getCurrentContent())
      )
    };
    // console.log('onSaveClick: ',data)
    this.props.setQuizInfo(data);
    this.props.setQuizEditorVisibility(false);
  };

  onCancelClick = () => {
    this.props.setQuizEditorVisibility(false);
  };
  onQuizDurationChange = evt => {
    const num = evt.target.value.replace(/\D/g, "");
    this.setState({ quizDuration: num });
  };

  render() {
    if (this.props.editor.quizEditorVisiblity) {
      return (
        <div className="quiz_editor visible">
          <div className="quiz_editor__card card">
            <div className="quiz_editor__title card-title">Quiz Options</div>
            <div className="quiz_editor__body">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <div className="form-group">
                    <label htmlFor="quiz-duration">Quiz Duration</label>
                    <div className="form-item">
                      <input
                        type="number"
                        className="form-control quiz-duration"
                        id="quiz-duration"
                        placeholder="30"
                        value={this.state.quizDuration}
                        onChange={this.onQuizDurationChange}
                      />
                      <span>Minutes</span>
                    </div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="form-group">
                    <label>Quiz Instructions</label>
                    <Editor
                      editorState={this.state.editorState}
                      wrapperClassName="instruction-wrapper"
                      editorClassName="instruction-editor"
                      onEditorStateChange={this.onEditorStateChange}
                      onContentStateChange={this.onContentStateChange}
                      toolbar={{
                        options: [
                          "inline",
                          "fontSize",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "link",
                          "remove",
                          "history"
                        ],
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true }
                      }}
                    />
                  </div>
                </li>
                <li className="list-group-item footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.onSaveClick}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={this.onCancelClick}
                  >
                    Cancel
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    } else {
      return <div className="quiz_editor hidden"></div>;
    }
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {
    viewer: state.viewer,
    editor: state.editor
  };
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setQuizInfo,
      setQuizEditorVisibility
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(QuizEditor)
);
