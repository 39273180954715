import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { Field, reduxForm } from "redux-form";

import AuthService from "../auth-service";
import { Greeting } from "../../AuthenticatedApp";
// Import Components

// Set variables
let buttonStatus = "";

// Render component parts
const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <div className="subtitle">
      <label>{label}</label>
    </div>
    <input
      className="form-control"
      type={type}
      placeholder={label}
      {...input}
    />
    {
      <div className="errors">
        {touched && (error && <span>{error}</span>)}{" "}
      </div>
    }
  </div>
);

const renderFieldSelect = ({
  name,
  label,
  selectList,
  meta: { touched, error }
}) => {
  if (selectList) {
    return (
      <div>
        <div className="subtitle">
          <label>{label}</label>
        </div>
        <Field name="select" className="form-control" component="select">
          <option value="-1" hidden>
            Please select a program
          </option>
          {_.map(selectList, (listItem, index) => {
            return (
              <option name={listItem.title} value={listItem.name} key={index}>
                {listItem.title}
              </option>
            );
          })}
        </Field>
      </div>
    );
  } else {
    return <div>Something went wrong</div>;
  }
};

// Redux form stuff
const validate = values => {
  const errors = {};
  // check for GUID format
  // check to see if any string exists
  if (!values.password) {
    errors.password = "Please enter a password";
  }

  buttonStatus = !_.isEmpty(errors) ? "disabled" : "";
  return errors;
};

// Main Class
class AuthLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorText: " "
    };
    this.Auth = new AuthService();
  }

  onSubmit(values) {
    if (this.Auth.loggedIn()) {
      this.Auth.logout();
      this.setState(this.state);
    } else {
      this.Auth.login(values.select, values.password)
        .then(res => {
          if (res) {
            this.props.login(res);
          }
        })
        .catch(err => {
          this.setState({
            errorText: "Invalid Program Selection or Password"
          });
        });
    }
  }

  render() {
    let buttonTitle = "Log In";
    if (this.Auth.loggedIn()) {
      buttonTitle = "Log Out";
    } else {
    }
    const { handleSubmit } = this.props;
    return (
      <div className="select-editor">
        <div className="card login-form">
          <div className="card-block">
            <Greeting />
            <h5 className="card-title text-primary">Please select a program</h5>
            <h6 className="card-subtitle mb-2 text-muted">
              Select the program you are associated with
            </h6>
            <p className="card-text">
              Please select the program you would like to edit, then enter the
              password below
            </p>
            <form
              name="viewerInput"
              onSubmit={handleSubmit(this.onSubmit.bind(this))}
            >
              <div className="form-group">
                <Field
                  name="program"
                  type="select"
                  component={renderFieldSelect}
                  selectList={this.props.programs}
                  label="Program"
                />
                <Field
                  name="password"
                  type="password"
                  component={renderField}
                  label="Password"
                />
                <div className="error">{this.state.errorText}</div>
                <div className="btn--submit">
                  <button
                    type="button"
                    className={`btn btn-primary ${buttonStatus}`}
                    onClick={handleSubmit(this.onSubmit.bind(this))}
                  >
                    {buttonTitle}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {
    mainViews: state.mainViews,
    programs: state.editor.programs
  };
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default reduxForm({
  form: "viewerInput",
  enableReinitialize: true,
  validate
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(AuthLogin))
);
