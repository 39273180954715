import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import ReactHtmlParser from "react-html-parser";
import SanitizeHtml from "sanitize-html";

import Toggle from "react-toggle";

// Set Redux Actions
import {
  resetLines,
  setScoutVisibility,
  setScoutDisabled
} from "../../../actions/index";

// Import Components
import QuizItemList from "./quiz-item-list";
import SubSideBar from "./di-quiz-sub-sidebar";
import NavItem from "../nav/nav-item";
import NavImageNum from "../nav/nav-image-number";
import Sprite from "../../svg_sprite";
import Countdown from "../common/countdown";
import SubmitBtn from "./quiz-submit-btn";

import { UserInfo } from "../../../AuthenticatedApp";
// Set variables

// Main Class
class Sidebar extends Component {
  constructor() {
    super();
    this.state = {
      subSidebar: {
        isOpen: true,
        type: "instructions",
        title: "",
        data: [
          {
            value: 0,
            textField: ""
          }
        ]
      },
      scout: {
        visible: false,
        disabled: false
      },
      viewer: {
        anatomy: 0,
        plane: 0,
        slice: 0
      },
      navItemOpen: "instructions",
      searchIsOpen: false,
      timerPaused: true,
      quizFinished: false
    };

    this.toggleSearch = this.toggleSearch.bind(this);
    this.toggleScout = this.toggleScout.bind(this);
    this.handleSubmitQuiz = this.handleSubmitQuiz.bind(this);
  }
  componentDidMount(nextProps) {
    let instructions = [{ _text: "There are no instructions for this quiz" }];
    if (this.props.viewer.instructions) {
      //console.log("There are instructions: ", this.props.viewer.instructions);
      instructions = this.props.viewer.instructions;
    } else {
      //console.log("There are no instructions");
    }

    this.setState(state => ({
      subSidebar: {
        ...this.state.subSidebar,
        isOpen: this.state.subSidebar.isOpen,
        data: {
          duration: this.props.viewer._attributes.duration,
          instructions: this.parseInstructions(instructions[0])
        }
      }
    }));
  }
  componentWillReceiveProps(nextProps) {
    let scoutDisabled = nextProps.scout.disabled;
    let scoutVisible = nextProps.scout.visible;
    let quizFinished = false;
    if (this.props.quizProps && this.props.quizProps.itemsRemaining === 99) {
      quizFinished = true;
    }
    this.setState(state => ({
      scout: {
        disabled: scoutDisabled,
        visible: scoutVisible
      },
      viewer: {
        anatomy: parseInt(nextProps.match.params.anatomy, 10),
        plane: parseInt(nextProps.match.params.plane, 10),
        slice: parseInt(nextProps.match.params.slice, 10) - 1
      },
      quizFinished
    }));
  }
  parseInstructions(rawInstructions) {
    let instructions = rawInstructions;
    if (rawInstructions._text) {
      instructions = rawInstructions._text[0];
    } else {
      // console.log('instructions: ', instructions)
      instructions =
        "The Instructions XML is malformed and setup in a way the viewer cannot read them. Please contact support to resolve this.";
    }
    let parsedInstructions = ReactHtmlParser(
      SanitizeHtml(instructions, {
        transformTags: {
          b: "strong"
        },
        exclusiveFilter: function(frame) {
          return !frame.text.trim();
        }
      })
    );
    return parsedInstructions;
  }
  //// Term Data
  getTermData() {
    let termData = [];
    _.map(
      this.props.viewer.anatomy[this.state.viewer.anatomy].plane_pulse[
        this.state.viewer.plane
      ].image[this.state.viewer.slice].term,
      (value, index) => {
        var myValue = {
          value: index,
          correctAnswer: value._attributes.term,
          quizID: value._attributes.quizID,
          location: value.line
        };
        termData.push(myValue);
      }
    );
    return termData;
  }
  //// Slice Count
  getSliceCount() {
    let sliceCount = this.props.viewer.anatomy[this.state.viewer.anatomy]
      .plane_pulse[this.state.viewer.plane].image.length;
    return sliceCount;
  }
  //// Toggle Search
  toggleSearch() {
    this.setState({
      ...this.state,
      searchIsOpen: !this.state.searchIsOpen
    });
  }
  //// Toggle Sub Sidebar
  toggleSubSidebar() {
    if (this.state.subSidebar.isOpen) {
      this.setState(state => ({
        subSidebar: {
          isOpen: false
        },
        navItemOpen: ""
      }));
    }
  }
  //// Toggle Scout
  toggleScout() {
    this.props.setScoutVisibility(!this.props.scout.visible);
  }

  handleInstructionsClick() {
    let instructionsData = {
      duration: this.props.viewer._attributes.duration,
      instructions: this.parseInstructions(this.props.viewer.instructions[0])
    };
    let navItemOpen = "instructions";
    let isOpen = true;
    if (this.props.viewer) {
      if (
        this.state.subSidebar.type === "instructions" &&
        this.state.subSidebar.isOpen
      ) {
        isOpen = false;
        navItemOpen = "";
      }
      this.setState(state => ({
        navItemOpen,
        subSidebar: {
          isOpen: isOpen,
          type: "instructions",
          title: "",
          activeItem: this.state.viewer.instructions,
          data: instructionsData
        }
      }));
    }
  }
  handleAnatomyClick() {
    let anatomyData = [];
    let navItemOpen = "anatomy";
    let isOpen = true;
    if (this.props.viewer) {
      _.map(this.props.viewer.anatomy, (value, index) => {
        var myValue = {
          value: index,
          textField: value._attributes.menuname
        };
        anatomyData.push(myValue);
      });
    }
    if (
      this.state.subSidebar.type === "anatomy" &&
      this.state.subSidebar.isOpen
    ) {
      isOpen = false;
      navItemOpen = "";
    }
    this.setState(state => ({
      navItemOpen,
      subSidebar: {
        isOpen,
        type: "anatomy",
        title: "Select an Anatomy",
        activeItem: this.state.viewer.anatomy,
        data: anatomyData
      }
    }));
  }
  handlePlaneClick() {
    let planeData = [];
    let navItemOpen = "plane";
    let isOpen = true;

    if (this.props.viewer) {
      let currentPlane = this.state.viewer.anatomy;
      _.map(
        this.props.viewer.anatomy[currentPlane].plane_pulse,
        (value, index) => {
          var myValue = {
            value: index,
            textField: value._attributes.menuname
          };
          planeData.push(myValue);
        }
      );
      if (
        this.state.subSidebar.type === "plane" &&
        this.state.subSidebar.isOpen
      ) {
        isOpen = false;
        navItemOpen = "";
      }
      this.setState(state => ({
        navItemOpen,
        subSidebar: {
          isOpen,
          type: "plane",
          title: "Select a Plane/Pulse",
          activeItem: this.state.viewer.plane,
          data: planeData
        }
      }));
    }
  }

  handleSubmitQuiz() {
    this.props.submitQuiz(this.props.viewer, this.props.quiz, this.props.timer);
  }

  render() {
    let instructionsOpen = false;
    let anatomyOpen = false;
    let planeOpen = false;
    let sidebarBlockoutVisibleCSS = "hidden";
    // console.log("sidebar quizProps: ",this.props.quiz.quizProps)
    if (this.state.navItemOpen === "anatomy") {
      anatomyOpen = true;
    } else if (this.state.navItemOpen === "plane") {
      planeOpen = true;
    } else if (this.state.navItemOpen === "instructions") {
      instructionsOpen = true;
      sidebarBlockoutVisibleCSS = "visible";
    }
    return (
      <div className="sidebar">
        <div className="mainSidebar">
          <div className={`sidebar__header ${this.props.diType}`}>
            <UserInfo isQuiz />
            <div className="title">
              <h1 className="title__text">{this.props.diType}</h1>
              <div className="title__sprite">
                <Sprite sprite="logo-nait-shield" />
              </div>
            </div>
            <div className="title__viewer">
              {this.props.viewer._attributes.name}
            </div>
          </div>
          <div className="sidebar__nav">
            <NavItem
              title="Instructions"
              onClicky={this.handleInstructionsClick.bind(this)}
              isActive={instructionsOpen}
            />
            <NavItem
              title="Anatomy"
              currentView={
                this.props.viewer.anatomy[this.state.viewer.anatomy]._attributes
                  .menuname
              }
              onClicky={this.handleAnatomyClick.bind(this)}
              isActive={anatomyOpen}
            />
            <NavItem
              title="Plane/Pulse"
              currentView={
                this.props.viewer.anatomy[this.state.viewer.anatomy]
                  .plane_pulse[this.state.viewer.plane]._attributes.menuname
              }
              onClicky={this.handlePlaneClick.bind(this)}
              isActive={planeOpen}
            />
            <NavImageNum
              currentNum={this.state.viewer.slice + 1}
              maxNum={this.getSliceCount()}
            />
          </div>
          <div className="termList-title">
            <div className="countdown">
              <Countdown
                seconds={this.props.viewer._attributes.duration * 60}
                paused={this.state.timerPaused}
              />
            </div>
            Your Answers
          </div>
          <QuizItemList data={this.getTermData()} />
          <div className="submit-btn__container">
            <SubmitBtn />
          </div>
          <div className="sidebar__footer">
            <div className="sidebar__footer--item">
              <div className="sidebar__footer--item--title">Scout</div>
              <div className="nav__slider">
                <Toggle
                  onChange={this.toggleScout}
                  className="scout__toggle"
                  checked={this.props.scout.visible}
                  disabled={this.props.scout.disabled}
                />
              </div>
            </div>
          </div>
        </div>
        {
          <SubSideBar
            isOpen={this.state.subSidebar.isOpen}
            toggleSubSidebar={this.toggleSubSidebar.bind(this)}
            type={this.state.subSidebar.type}
            title={this.state.subSidebar.title}
            activeItem={this.state.subSidebar.activeItem}
            data={this.state.subSidebar.data}
            quizProps={this.props.quiz.quizProps}
          />
        }
        <div
          className={`sidebar__blockout ${sidebarBlockoutVisibleCSS}`}
          onClick={this.handleInstructionsClick.bind(this)}
        ></div>
      </div>
    );
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {
    viewer: state.viewer,
    scout: state.scout,
    quiz: state.quiz,
    quizProps: state.quiz.quizProps
  };
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetLines,
      setScoutVisibility,
      setScoutDisabled
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Sidebar)
);
