import React, {Component} from 'react';
import { RIEInput } from '@attently/riek'

// Import Components
import MdiPencil from 'mdi-react/PencilIcon';
import MdiRemove from 'mdi-react/MinusCircleIcon';

// Set Redux Actions

// Set Variables

// Main Class
export default class SubSidebarListItem extends Component {
  constructor() {
    super();
    this.state = {
      editable: false,
      titleActiveClass: '',
      title: ''
    };
    this.handleEditClick = this.handleEditClick.bind(this)
  }

  handleLinkClick = () => {
    // console.log("handleLinkClick")
    this.props.onClicky()
  }
  handleEditClick = () => {
    // console.log("handleEditClick")
    if(!this.state.editable){
      this.setState({
        ...this.state,
        editable: true,
        titleActiveClass: 'active'
      })
    } else {
      this.setState({
        ...this.state,
        editable: false,
        titleActiveClass: ''
      })
    }
  }
  renderRemoveItem = () => {
    if(this.props.showRemoveBtn){
      return(
        <span className="list-item__icon close" onClick={this.handleRemoveClick}><MdiRemove /></span>
      )
    }
  }
  handleRemoveClick = () => {
    const listData = {
      index: this.props.listData.index,
      anatomyIndex: this.props.listData.anatomyIndex,
      title: this.props.listData.title
    }
    this.props.onRemove(listData)
  }
  onTitleChange(e){
    // console.log('onTitleChange: ', e.text)
    const listData = {
      index: this.props.listData.index,
      anatomyIndex: this.props.listData.anatomyIndex,
      title: e.text
    }
    this.props.onRename(listData)
  }

  onTitleEditFinish(e){
    this.setState({
      ...this.state,
      editable: false,
      titleActiveClass: ''
    })

  }

  render() {
    if(this.state.editable){
      return(
        <div className={`list-item ${this.props.isActive}`} >
        <span className="list-item__title">
          <RIEInput
            value={this.props.listData.title}
            change={this.onTitleChange.bind(this)}
            propName="text"
            editing={true}
            selectAll={true}
            afterFinish={this.onTitleEditFinish.bind(this)}
          />
    </span>
        <span className="list-item__icon edit" onClick={this.handleEditClick}><MdiPencil /></span>
        { this.renderRemoveItem() }
    </div>
      )
    } else {
      return (
        <div className={`list-item ${this.props.isActive}`} >
          <span className="list-item__title" onClick={this.props.onClicky}>{this.props.listData.title}</span>
          <span className="list-item__icon edit" onClick={this.handleEditClick}><MdiPencil /></span>
          { this.renderRemoveItem() }
        </div>
      )
    }
  }
}
