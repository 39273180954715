import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {setGlobalError} from '../../actions/index'
import {di1EditorGetViewer} from '../../actions/editor-api-actions'

import Editor from './editor/di-editor'
import Error from './main-error'
import Loader from './loader'

// Import Components
// import MainNav from '../main-nav';
// import MainSubNav from '../main-sub-nav'
// import StageContainer from './stage/stage-container'

/// Variables
export let authData = {}
class EditorWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewMode: '',
      errorText: ''
    }
  }
  componentDidMount() {
    const viewerGUID = this.props.match.params.GUID;
    if (/^[a-zA-Z\d]{8}-[a-zA-Z\d]{4}-[a-zA-Z\d]{4}-[a-zA-Z\d]{4}-[a-zA-Z\d]{12}$/g.test(viewerGUID)) {
      this.props.di1EditorGetViewer(viewerGUID, (response) => {
      });
    } else {
      console.log('GUID Error');
      this.setState({
        viewMode: 'error',
        errorText: 'There was a problem with the editor ID'
      })
    }
    authData = {...this.props.authData}
  }

  componentWillReceiveProps(nextProps) {
    let viewMode = ''
    let errorText = ""
    if(nextProps.globalError.message) {
      viewMode = 'error'
      switch(nextProps.globalError.message){
        case 500:
          errorText = "Server responded with error code 500. There is probably something wrong with the viewer ID. Please check to see if the ID is correct."
        break
        default:
          errorText = 'Something happened'
        break
      }
    }
    if(nextProps.viewer._attributes !== undefined) {
      switch(nextProps.viewer._attributes.quiz){
        case '0':
        viewMode = 'editor'
        break;
        case '1':
        viewMode = 'editor'
        break;
        default:
        viewMode = 'error'
        break;
      }
    }
    this.setState({
      viewMode, errorText
    })
  }

  renderStage(view) {
    let displayView = ''
    switch (view) {
      case 'editor':
        displayView = <Editor
                        anatomy={this.props.match.params.anatomy}
                        plane={this.props.match.params.plane}
                        slice={this.props.match.params.slice}
                      />
        break;
      case 'error':
        displayView = <Error
                        errorText={this.state.errorText}
                        redirectionURL="/editor/"
                        redirectionText="Pick a viewer to edit"
                      />
        break
      default:
        displayView = <Loader
                        errorText="Loading..."
                      />
    }
    return (
      <div className="stage">
        {displayView}
      </div>
    )
  }
  render() {
    return (
      <div className="main">
        <div style={{background: "#4D4D4D", color: "white", position: "absolute", top: 0, left: 0, zIndex: 100, cursor: "pointer", padding: 10}} onClick={() => this.props.history.push("/editor")}>Return to home page!</div>
        {this.renderStage(this.state.viewMode)}
      </div>
    )
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {
    viewer: state.viewer,
    globalError: state.globalError,
  }
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {
  // whenever selectView is called the result should be passed to
  // all of our reducers
  return bindActionCreators({
    di1EditorGetViewer, setGlobalError
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditorWrapper));
