import React, {Component} from 'react';

// Import Components
import MdiChevronRight from 'mdi-react/ChevronRightIcon';
import MdiChevronLeft from 'mdi-react/ChevronLeftIcon';

// Set variables

// Main Class
class NavItem extends Component {

  render() {
    if (this.props.isActive) {
      return (
        <div className="nav__item active" onClick={this.props.onClicky}>
          <div className='nav__arrow active'><MdiChevronRight/></div>

          <div className="nav__item--contaner">
            <div className="nav__item--title">{this.props.title}</div>
            <div className="nav__item--value">{this.props.currentView}</div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="nav__item" onClick={this.props.onClicky}>
          <div className='nav__arrow'><MdiChevronLeft/></div>
          <div className="nav__item--contaner">
            <div className="nav__item--title">{this.props.title}</div>
            <div className="nav__item--value">{this.props.currentView}</div>
          </div>

        </div>
      )
    }
  }
}

export default NavItem
