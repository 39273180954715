import {
  GET_GLOBAL_ERROR,
  GLOBAL_MODAL,
  API_ERROR,
  GLOBAL_ERROR,
  SET_LINES,
  SET_SCOUT_VISIBILITY,
  SET_SCOUT_DISABLED,


} from '../../actions/';

export function globalAlertReducer(state = {}, action) {
  switch(action.type) {
    case GET_GLOBAL_ERROR:
    // console.log('global error reducer', action.payload);
    return action.payload;
    default:
      return state;
  }
}
export function ApiErrorReducer(state = {}, action) {
  switch(action.type) {
    case API_ERROR:
    return action.payload;
    default:
      return state;
  }
}
export function globalModalReducer(state = {}, action) {
  switch(action.type) {
    case GLOBAL_MODAL:
    return action.payload;
    default:
      return state;
  }
}
export function globalErrorReducer(state = {}, action) {
  switch(action.type) {
    case GLOBAL_ERROR:
    return action.payload;
    default:
      return state;
  }
}
export function lineReducer(state = {}, action) {
  switch(action.type) {
    case SET_LINES:
    return action.payload;
    default:
      return state;
  }
}
export function scoutReducer(state = {}, action) {
  // console.log('scoutReducer: ', action, state)
  let newState = {}
  switch(action.type) {
    case SET_SCOUT_VISIBILITY:
    newState = {...state, visible: action.payload}
    return newState;
    case SET_SCOUT_DISABLED:
    newState = {...state, disabled: action.payload}
    return newState;
    default:
      return state;
  }
}
