import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import _ from 'lodash';
import {Field, reduxForm} from 'redux-form'
import { Greeting } from '../../AuthenticatedApp';

// Import Components

// Set variables
let buttonStatus = '';

// Render component parts
const renderField = ({ input, label, type, meta: { touched, error } }) => <div className="input-group">
  <input className="form-control" type={type} placeholder={label} {...input}/>
   { < div className = "errors" >
   { touched && (error && <span>{error}</span>) } < /div>}
</div>

// Redux form stuff
const validate = values => {
  const errors = {}
  // check for GUID format
  if (!/^[a-zA-Z\d]{8}-[a-zA-Z\d]{4}-[a-zA-Z\d]{4}-[a-zA-Z\d]{4}-[a-zA-Z\d]{12}$/g.test(values.viewerGUID)) {
    errors.viewerGUID = 'Please enter a Viewer ID with the format XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX'
  }
  // check to see if any string exists
  if (!values.viewerGUID) {
    errors.viewerGUID = 'Please enter a Viewer ID'
  }
  buttonStatus = !_.isEmpty(errors) ? "disabled" : ""
  return errors
}

// Main Class
class SelectViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorText: ''
    };
  }

  onSubmit(values) {
    const redirectionURL = `/${values.viewerGUID}/0/0/1`;
    this.props.history.push(redirectionURL);
  }

  render() {
    const {handleSubmit} = this.props
    return (
      <div className="select-viewer">
        <div className="card">
          <div className="card-block">
            <Greeting />
            <h5 className="card-title text-primary">Please select a viewer</h5>
            <h6 className="card-subtitle mb-2 text-muted">We don't know which viewer you're trying to look at!</h6>
            <p className="card-text">Please enter the id of the viewer you are trying to access below.
              <br/>It looks something like this:
            </p>
              <p><strong>2DADC15F-162C-476E-75EC-EE8277B72CAF</strong></p>
            <form name="viewerInput" onSubmit={handleSubmit(this.onSubmit.bind(this))}>
              <div className="form-group">
                <Field
                  name="viewerGUID"
                  type="text"
                  component={renderField}
                  label="Viewer ID"/>
                <div className="btn--submit">
                  <button type="button" className={`btn btn-primary ${buttonStatus}`} onClick={handleSubmit(this.onSubmit.bind(this))}>Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {mainViews: state.mainViews, isAuthenticated: state.isAuthenticated}
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({

  }, dispatch)
}

export default reduxForm({
  form: 'viewerInput',
  validate,
  initialValues: {
    viewerGUID: '2DADC15F-162C-476E-75EC-EE8277B72CAF',
  }
})(connect(mapStateToProps, mapDispatchToProps)(withRouter(SelectViewer)))
