import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import AuthService from "./auth-service";
import {authUrl} from '../global';

// Import actions

export default function withAuth(AuthComponent) {
  const Auth = new AuthService(
    //"https://digilearn.nait.ca/assets/NAIT/DiViewerAuthenticator/DiViewerAuthenticator/authentication/index"
    authUrl
  );

  class AuthWrapped extends Component {
    constructor() {
      super();
      this.state = {
        isAuth: null,
        program: null,
        viewerGUID: null,
        quizGUID: null
      };
    }

    componentWillMount() {
      this.checkAuth();
    }
    componentWillReceiveProps(nextProps) {
      //this.checkAuth()
    }

    checkAuth() {
      if (!Auth.loggedIn()) {
        this.setState({
          isAuth: false,
          program: null,
          viewerGUID: null,
          quizGUID: null
        });
      } else {
        try {
          const profile = Auth.getProfile();
          this.setState({
            isAuth: true,
            program: profile.username,
            viewerGUID: profile.viewerGUID,
            quizGUID: profile.quizGUID,
            authData: profile.authData
          });
        } catch (err) {
          Auth.logout();
          this.setState({
            isAuth: false,
            program: null,
            viewerGUID: null,
            quizGUID: null
          });
        }
      }
    }

    processLogin() {
      const profile = Auth.getProfile();

      this.setState({
        isAuth: true,
        program: profile.username,
        viewerGUID: profile.viewerGUID,
        quizGUID: profile.quizGUID,
        authData: profile.authData
      });
    }

    processLogout() {
      Auth.logout();
      this.checkAuth();
    }

    render() {
      return (
        <AuthComponent
          history={this.props.history}
          isAuth={this.state.isAuth}
          program={this.state.program}
          viewerGUID={this.state.viewerGUID}
          quizGUID={this.state.quizGUID}
          authData={this.state.authData}
          login={() => this.processLogin()}
          logout={() => this.processLogout()}
        />
      );
    }
  }
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(AuthWrapped);
}

function mapStateToProps(state) {
  return { viewer: state.viewer };
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}
