import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import _ from 'lodash';

// Import Components


// Set variables

// Main Class
class ViewerImageArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageDimensions: {},
      stageDimensions: {},
      currentAnatomy: parseInt(this.props.match.params.anatomy, 10),
      currentPlane: parseInt(this.props.match.params.plane, 10),
      currentSlice: (parseInt(this.props.match.params.slice, 10) - 1),
      image: '',
      defaultImage: {
        _attributes: {
          path:'default_hd_image.jpg'
        }
      }
    };
    this.onImgLoad = this.onImgLoad.bind(this);
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        ...this.state,
        currentAnatomy: parseInt(this.props.match.params.anatomy, 10),
        currentPlane: parseInt(this.props.match.params.plane, 10),
        currentSlice: (parseInt(this.props.match.params.slice, 10) - 1),
      })
    }
  }

  updateDimensions() {
    let windowWidth = window.innerWidth - 258;
    let windowHeight = window.innerHeight;
    this.setState({
      stageDimensions: {
        height: windowHeight,
        width: windowWidth
      }
    })
  }

  linePointPxPct(location, parentSize) {
    let parentScale = parentSize/this.state.imageDimensions.scalePercent
    let percent = (location / parentScale) * 100
    return (percent)
  }
  onImgLoad(img) {
    let scalePercent;
    if(img.target.naturalWidth >= img.target.naturalHeight) {
      scalePercent = img.target.naturalWidth/512;
    } else {
      scalePercent = img.target.naturalHeight/512;
    }

    this.setState({
      imageDimensions: {
        height: img.target.naturalHeight,
        width: img.target.naturalWidth,
        scalePercent
      }
    });
  }

  renderImage(image) {
    const httpsSrc = this.props.viewer._attributes.imagesbaseurl.replace(/^http:\/\//i, 'https://');
    let imageURL = `${httpsSrc}${image}`
    
    return (<img onLoad={this.onImgLoad} src={imageURL} alt="DI Slice"/>)
  }
  renderLineEndPoint(x, y, classNameSuffix) {
    let divStylePct = {
      top: this.linePointPxPct(y, this.state.imageDimensions.height) + '%',
      left: this.linePointPxPct(x, this.state.imageDimensions.width) + '%'
    }
    let lineEndClass = `line-end-${classNameSuffix}`

    return (
      <div
        className={`lineEndPoint ${lineEndClass}`}
        style={divStylePct}
        key={classNameSuffix}>
      </div>
    )
  }
  
  setupLinePoints(image) {
    return (_.map(image.term, (termItem, index) => {
      let i = 0;
      return (_.map(termItem.line, (line) => {
        let x = line._attributes.x;
        let y = line._attributes.y;
        let classNameSuffix = `${index}--${i}`
        i++;
        return (this.renderLineEndPoint(x, y, classNameSuffix))
      }))
    }))
  }

  selectImage(image, stageHeight, stageWidth, imageHeight, imageWidth) {
    let heightPercent = stageHeight / imageHeight;
    let widthPercent = stageWidth / imageWidth
    let diImageStylePercent = {}
    if((imageWidth*heightPercent) >= stageWidth) {
      diImageStylePercent = {
        width: stageWidth  + 'px',
        height: imageHeight * widthPercent + 'px'
      }
    } else {
      diImageStylePercent = {
        width: imageWidth * heightPercent + 'px',
        height: stageHeight + 'px'
      }
    }

    return (
      <div className="di-image" style={diImageStylePercent}>
        <div className="image" style={diImageStylePercent}>
          {this.renderImage(image._attributes.path)}
        </div>
        <div className="lineEndPoints">
          {this.setupLinePoints(image)}
        </div>
      </div>
    )
  }

  render() {
    let image = this.props.viewer.anatomy[this.state.currentAnatomy].plane_pulse[this.state.currentPlane].image[this.state.currentSlice]
    //let image = this.props.image
    if(_.isEmpty(image)){
      image = this.state.defaultImage
    }
    return (
      <div className="image-area">
        {this.selectImage(
          image,
          this.state.stageDimensions.height,
          this.state.stageDimensions.width,
          this.state.imageDimensions.height,
          this.state.imageDimensions.width
        )}
      </div>
    )
  }
}


// -----------------------------------------------------------------------------
// React/Redux Stuff
// -----------------------------------------------------------------------------

function mapStateToProps(state) {
  return {viewer: state.viewer}
}

// Anything returned from this fuction will end up as props
function mapDispatchToProps(dispatch) {

  return bindActionCreators({}, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewerImageArea))
